import Axios from "axios";

const ExcelDownloader = (config = { route: "", data: {} }) => {
  Axios.get(config.route, {
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    params: { ...config.data },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
};

export default ExcelDownloader;
