import React, { useContext } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Button from "../../theme/Button";
import AppContext from "../../context/store";

const QuickActionView = (props) => {
  const context = useContext(AppContext);

  const BoxPopItem = (props) => (
    <Button
      size="xs"
      containerStyle={{
        justifyContent: "flex-start",
        paddingLeft: 0,
        paddingRight: 0,
      }}
      textColor="black"
      fullWidth
      {...props}
    />
  );

  return (
    <BoxPopContainer
      className={props.state ? "show" : "hide"}
      onMouseOver={props.stopPropagation}
      onClick={props.stopPropagation}
    >
      <BoxPopInner>
        <BoxPopCaption>
          {context.t(`scaffold.quickActionsViewHeading`)}
        </BoxPopCaption>

        <BoxPopItem
          icon="alarm"
          title={context.t(`scaffold.QAItems.createAppointment`)}
          onClick={() => {
            props.history.push({
              pathname: "/appointments",
              state: { createAppointment: true },
            });
            props.closeHandler();
          }}
        />

        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
          props.packageNo === 1 && (
            <BoxPopItem
              icon="receipt"
              title={context.t(`scaffold.QAItems.createReceipt`)}
              onClick={() => {
                props.history.push("/receipts/add");
                props.closeHandler();
              }}
            />
          )) ||
          null}

        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
          props.packageNo === 1 && (
            <BoxPopItem
              icon="shopping_cart"
              title={context.t(`scaffold.QAItems.createPackageSale`)}
              onClick={() => {
                props.history.push("/packages/sales/add");
                props.closeHandler();
              }}
            />
          )) ||
          null}

        <BoxPopItem
          icon="group_add"
          title={context.t(`scaffold.QAItems.createCustomer`)}
          onClick={() => {
            props.history.push("/customers/add");
            props.closeHandler();
          }}
        />

        {context.state.user.permission === 1 && (
          <>
            <BoxPopItem
              icon="layers"
              title={context.t(`scaffold.QAItems.createService`)}
              onClick={() => {
                props.history.push("/services/add");
                props.closeHandler();
              }}
            />

            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
              props.packageNo === 1 && (
                <BoxPopItem
                  icon="view_agenda"
                  title={context.t(`scaffold.QAItems.createPackage`)}
                  onClick={() => {
                    props.history.push("/packages/add");
                    props.closeHandler();
                  }}
                />
              )) ||
              null}
          </>
        )}
      </BoxPopInner>
    </BoxPopContainer>
  );
};

export default withRouter(QuickActionView);

const BoxPopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;

  position: fixed;
  z-index: 10000000000000;
  right: 56px;
  top: 66px;

  background: transparent;
  opacity: 0;

  width: 300px;
  max-width: 80vw;

  &.show {
    opacity: 1;
    visibility: visible;
    transition: backdrop-filter 0s, all ease 0.25s;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.25s;
  }

  @media only screen and (max-width: 576px) {
    right: 20px !important;
    width: calc(100vw - 40px) !important;
    max-width: 100vw;
  }
`;

const BoxPopCaption = styled.h4`
  font-weight: 600;
  letter-spacing: 0.25px;
  letter-spacing: 0.75px;
  font-size: 11px;
  color: #aaa;
  padding-top: 8px;
  margin: 0;
`;

const BoxPopInner = styled.div`
  width: 100%;
  padding: 8px 16px;

  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;
