import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: "10px",
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    flex: 1,
  },
}));

const FullScreenDialog = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  return props.maxWidth ? (
    <Dialog
      className={props.className ? props.className : null}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={handleClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title}>{props.title}</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.receiptOk ? (
        <DialogContent
          style={props.containerStyle ? props.containerStyle : null}
        >
          {props.textType === true ? (
            <DialogContentText>
              {props.children && props.children}
            </DialogContentText>
          ) : (
            props.children && props.children
          )}
        </DialogContent>
      ) : (
        props.children
      )}
    </Dialog>
  ) : (
    <Dialog fullScreen open={props.open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title}>{props.title}</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.children}
    </Dialog>
  );
};

export default FullScreenDialog;
