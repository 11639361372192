const LimitedPermission = () => {
  return [
    { label: "Randevu Takvimi", name: "appointment" },
    { label: "Adisyon Yönetimi/Paket Yönetimi/Stok Yönetimi", name: "sales" },
    { label: "Müşteri İşlemleri", name: "customer" },
    { label: "Müşteri Bilgileri", name: "customer_info" },
    { label: "Hizmet Yönetimi", name: "services" },
    { label: "SMS Yönetimi", name: "sms" },
    { label: "Muhasebe Yönetimi/Gelişmiş Raporlar", name: "income_expense" },
    { label: "Bakiye İşlemleri", name: "wallet" },
    { label: "Galeri Yönetimi", name: "gallery" },
    { label: "Sistem Ayarları", name: "system" },
  ];
};

export default LimitedPermission;
