import React, { useContext, useState } from "react";
import Button from "../../theme/Button";
import AppContext from "../../context/store";

import GetAppIcon from "@material-ui/icons/GetApp";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import PlanUpgrade from "../PlanUpgrade";
import ExcelDownloader from "../../functions/ExcelDownloader";
import { Grid, Column } from "../../theme/Grid";
import { toast } from "react-toastify";
import ExcelDownloaderPost from "../../functions/ExcelDownloaderPost";

const ExcelButton = ({
  title,
  size,
  route,
  requestData,
  isPost,
  customCondition,
  withGrid,
  fullWidth,
}) => {
  const context = useContext(AppContext);
  const [planUpgradeDialog, setPlanUpgradeDialog] = useState(false);

  const ExcelButtonJSX = (
    <>
      <Button
        title={title ?? context.t(`component.excelButton.title`)}
        iconComponent={GetAppIcon}
        backgroundColor="green-opacity"
        textColor="green"
        size={size ?? "sm"}
        fullWidth={fullWidth ?? false}
        onClick={() => {
          if (isPost === true) {
            ExcelDownloaderPost({
              route: `${context.api_endpoint}/company/${route}`,
              data: requestData ?? {},
            });
          } else if (context.state.user.permission === 1) {
            if (
              customCondition ??
              (context.state.company_license.accounting === 1 ||
                context.state.company_license.accounting === 3)
            ) {
              ExcelDownloader({
                route: `${context.api_endpoint}/company/${route}`,
                data: requestData ?? {},
              });
            } else {
              setPlanUpgradeDialog(true);
            }
          } else {
            toast.warning(
              "Bu işlemi yapabilmek için yetkiye sahip olmanız gerekmektedir!"
            );
          }
        }}
      />
      <AlertDialog
        open={planUpgradeDialog}
        disableBackdropClick={false}
        closeHandler={() => setPlanUpgradeDialog(false)}
      >
        <PlanUpgrade />
      </AlertDialog>
    </>
  );

  const ExcelButtonWithGrid = (
    <Grid>
      <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
      <Column className="xs-12 sm-12 md-12 lg-12 xl-3">{ExcelButtonJSX}</Column>
    </Grid>
  );

  return withGrid ? ExcelButtonWithGrid : ExcelButtonJSX;
};

export default React.memo(ExcelButton);
