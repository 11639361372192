// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyClI3MXCXdifFdM0THm-tawpnUa38Ltq10",
  authDomain: "salon-auth.firebaseapp.com",
  projectId: "salon-auth",
  storageBucket: "salon-auth.appspot.com",
  messagingSenderId: "1092711284001",
  appId: "1:610805820179:android:f2d75dafe67d9d726448e6",
  measurementId: "G-5L18BE37ZS",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const providerGoogle = new GoogleAuthProvider();
export const providerApple = new OAuthProvider("apple.com");

/* export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
}; */
