import { Alert } from "@material-ui/lab";
import React, { useContext } from "react";
import AppContext from "../../context/store";
import { Column, Grid } from "../../theme/Grid";

const UndefinedSenderAlert = () => {
  const context = useContext(AppContext);
  return (
    <Grid>
      {process.env.APP_NAME === "management" ? null : (
        <Column className="xs-12 sm-12 mb-2">
          <Alert severity="error">
            <b>{context.t(`sms.undefinedAlerts.sms_heading`)}</b>
            <br />
            {context.t(`sms.undefinedAlerts.sms_content`)}
          </Alert>
        </Column>
      )}
    </Grid>
  );
};

export default UndefinedSenderAlert;
