/**
 * It changes phone number.
 * param {phoneNumner} - Phone number
 * returns a last 4 characters of phoneNumber
 */
export const phoneNumberDisplay = (phoneNumber) => {
  let newPhoneNumber = phoneNumber.split("");
  if (newPhoneNumber.length > 5) {
    for (let i = 0; i < phoneNumber.length - 4; i++) {
      newPhoneNumber[i] = "-";
    }
  }

  return newPhoneNumber.toString().replace(/,/g, "");
};
