
import BetweenTimeGenerator from "./BetweenTimeGenerator";
const DisabledAppointmentsGenerator = (data) => {
  // {
  //   single: ["11:00", "11:20"],
  //   between: [
  //     { start_hour: "10:00", end_hour: "10:20" },
  //     { start_hour: "15:30", end_hour: "16:20" },
  //   ],
  //   day_closed: true,
  // };

  // console.log(data)


  if (data.day_closed === true){
    return [...BetweenTimeGenerator({
      start_hour: "00:00",
      end_hour: "23:59",
      slice: 1,
    })]
  }

  let disabledArr = [];
  disabledArr = [...data.single];

  data.between &&
    data.between.length > 0 &&
    data.between.map(
      (item) =>
        (disabledArr = [
          ...disabledArr,
          ...BetweenTimeGenerator({
            start_hour: item.start_hour,
            end_hour: item.end_hour,
            slice: 1,
          }),
        ])
    );

  return [...disabledArr];
};

export default DisabledAppointmentsGenerator;
