import React, { useState } from "react";
import { Grid, Icon } from "@material-ui/core";
import { IoLinkOutline } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { SlCalculator } from "react-icons/sl";
import { TbCalendarCheck } from "react-icons/tb";
import { FaMobileAlt } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { RiMessage2Fill } from "react-icons/ri";
import { FaFileContract } from "react-icons/fa";
import { IoReceipt } from "react-icons/io5";
import { HiMiniIdentification } from "react-icons/hi2";
import { IoNotifications } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import styled from "styled-components";

const featuresData = [
  {
    id: 1,
    title: "İşletmeye Özel Randevu Sayfası",
    Icon: <IoLinkOutline color="black" size={60} fontSize="large" />,
    context:
      "Artık randevu alma işlemi, işletmeniz için özel olarak oluşturulan randevu alma sayfası sayesinde çok daha kolay ve hızlı.  Danışanlarınız, istedikleri zaman ve günün her saatinde randevu alabilirler. Bu sayede, müşteri bağlılığınızı korumanın yanı sıra, onlara daha fazla esneklik ve özgürlük sunabilirsiniz. Hemen deneyin ve randevu planlamanız hakkında endişelenmeyin. Biz buradayız!",
  },
  {
    id: 2,
    title: "Müşteri Yönetimi",
    Icon: <BsPeopleFill size={60} color="black" fontSize="large" />,
    context:
      "Ayrıntılı müşteri profili oluşturma seçeneği sayesinde her müşterinize ait bilgileri ve randevu geçmişinizi raporlayarak müşteri takibini kolaylaştırın. Her müşterinize kişiselleştirilmiş hizmet sunarak, müşteri sadakatinizi arttırın.",
  },
  {
    id: 3,
    title: "Muhasebe Yönetimi",
    Icon: <SlCalculator size={60} color="black" fontSize="large" />,
    context:
      "Gelir ve gider muhasebesel kayıtlarınızı tutarken finansal durumunuz her an takibinizde olsun. Günlük ve aylık satış, Alacak, Personel prim, Stok ve ürün satışları raporları ile her şey kontrolünüz altında olsun.",
  },
  {
    id: 4,
    title: "Randevu Yönetimi",
    Icon: <TbCalendarCheck size={60} color="black" fontSize="large" />,
    context:
      "Bu otomasyon ile hem zamandan hem de randevularına gelmeyenlerden kaynaklı oluşabilecek maddi kayıpların önüne geçin.Vaktimin her bir dakikası çok değerli diyenler için mutlaka kullanılması gereken bir özellik",
  },
  {
    id: 5,
    title: "Mobile Uygulama",
    Icon: <FaMobileAlt size={60} color="black" fontSize="large" />,
    context:
      "İster bilgisayar üzerinden isterseniz de En Randevu APP mobil uygulamasını kullanarak salonunuzu 7/24 cepten yönetin.",
  },
  {
    id: 6,
    title: "Gelişmiş Raporlama",
    Icon: <BiSolidReport size={60} color="black" fontSize="large" />,
    context:
      "Gelişmiş Raporlama sayesinde işletmenize ait Günlük, Haftalık ve Aylık, vb. zaman dilimlerindeki performansınızı rapor olarak görüntüleyip gerekli değerlendirmeleri ve kıyaslamaları yapmanıza olanak sağlıyoruz.",
  },
  {
    id: 7,
    title: "WhatsApp Mesaj",
    Icon: <RiMessage2Fill size={60} color="black" fontSize="large" />,
    context:
      "Randevu hatırlatmalarınızı ,anketlerinizi ve bildirimlerinizi SMS yerine WhatsAPP üzerinden göndermekte artık mümkün.",
  },
  {
    id: 8,
    title: "E-İmzalı Sözleşme",
    Icon: <FaFileContract size={60} color="black" fontSize="large" />,
    context:
      "Bu özellik ile kağıt sözleşmelerden kurtulun ve taslak olarak kayıt ettiğiniz sözleşmelerinizi her müşteriniz için tüm bilgileri ile kayıtlı gelsin ve dijital imza alın.",
  },
  {
    id: 9,
    title: "Adisyon Sistemi",
    Icon: <IoReceipt size={60} color="black" fontSize="large" />,
    context:
      "Detaylı adisyon yönetimi kullanıcılarımıza gerek paket satışları gerek hizmet veya ürün satışlarını tek sayfada gösterme ve ödemelerini takip etme imkanı sağlayan gelişmiş bir paneldir.",
  },
  {
    id: 10,
    title: "Personel Raporları",
    Icon: <HiMiniIdentification size={60} color="black" fontSize="large" />,
    context:
      "Sattığınız birincil kaynak zamandır. Geliri en üst düzeye çıkarmak için, personellerin her zaman en iyi hizmeti vermesini istersiniz. Mükemmel bir işletme olmak için her zaman %100 odaklanıp hizmet vermeniz çok önemli.",
  },
  {
    id: 11,
    title: "SMS Bildirimler",
    Icon: <IoNotifications size={60} color="black" fontSize="large" />,
    context:
      "En Randevu APP, hatırlatıcıları kolay ve eğlenceli hale getirdi. Müşterilerinizin hiçbir randevuyu kaçırmadığından emin olmak için hatırlatıcıları hızlı ve kolay bir şekilde gönderin ve otomatikleştirin.",
  },
  {
    id: 12,
    title: "Stok Yönetimi",
    Icon: <TbReportSearch size={60} color="black" fontSize="large" />,
    context:
      "Ürün satışlarını ve stok durumlarını En Randevu App ile takip edin hatalara taviz vermeyin. Stok kontrolü ile verilerinizi güncel ve doğru tutun. Günlük ve aylık raporlarla ürün satışlarınız kontrolünüzde olsun",
  },
];

function Index() {
  const [feature, setFeature] = useState();
  const [selectFeature, setSelecetFeature] = useState(0);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#3189ec63",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      <div className="container">
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: 1.4,
            color: "white",
            marginTop: "30px",
          }}
        >
          Uygulamaya ufak bir bakış
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: 100,
            lineHeight: 1.1,
            color: "white",
            marginBottom: "30px",
          }}
        >
          İşte Enrandevu’da en çok kullanılan özellikler
        </p>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div>
              <Grid container>
                <Grid
                  onClick={() => setSelecetFeature(0)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 0 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<IoLinkOutline color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: " white",
                      }}
                    >
                      {" "}
                      İşletmeye Özel Randevu Sayfası
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(1)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 1 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<BsPeopleFill color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Müşteri Yönetimi
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(2)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 2 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<SlCalculator color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Muhasebe Yönetimi{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(3)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 3 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <TbCalendarCheck
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Randevu Yönetimi{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(4)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 4 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<FaMobileAlt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Mobil Uygulama{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(5)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 5 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<BiSolidReport color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Gelişmiş Raporlama{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(6)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 6 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <RiMessage2Fill
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      WhatsApp Mesaj{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(7)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 7 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <FaFileContract
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      E-imzalı Sözleşme{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(8)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 8 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {<IoReceipt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Adisyon Sistemi{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(9)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 9 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <HiMiniIdentification
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Personel Raporları
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(10)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 10 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <IoNotifications
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      SMS Bildirimleri
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(11)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 11 ? "#d50a58" : "#ffa000"
                    }
                  >
                    {
                      <TbReportSearch
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Stok Yönetimi
                    </p>
                  </FavoriteBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px"
                //height: "450px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      //height: "300px",
                      paddingBottom: "30px",
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 5px 8px #a5a5a5ad",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].Icon}
                      </div>
                      <h2
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                          color: "black",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].title}
                      </h2>
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "rgba(3, 29, 68, 0.5)",
                        }}
                      >
                        {
                          featuresData[selectFeature ? selectFeature : 0]
                            .context
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Index;

const FavoriteBox = styled.div`
  width: 90%;
  height: 100px;
  text-align: center;
  display: flex;
  gap: 5px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor};
  box-shadow: 0 3px 3px #a5a5a5ad;

  @media only screen and (max-width: 1190px) {
    margin: 5px;
  }
`;
