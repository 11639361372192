import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Table from "../../../theme/Table";
import { Grid, Column } from "../../../theme/Grid";
import moment from "moment";
import Axios from "axios";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Select from "../../../theme/CustomMUI/Select";
import { Alert } from "@material-ui/lab";
import Input from "../../../theme/CustomMUI/Input";
import { toast } from "react-toastify";

const AppointmentFilter = () => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getAppointmentsByFilter(page),
  });
  const [data, setData] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [startDate, setStartDate] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [staffId, setStaffId] = useState(null);

  const [serviceKey, setServiceKey] = useState("");
  const [customerKey, setCustomerKey] = useState("");
  const [appointmentState, setAppointmentState] = useState(null);

  const getAppointmentsByFilter = (page = 1) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/list?page=${page}`,
      {
        startDate,
        endDate,
        staffId,
        serviceKey,
        customerKey,
        appointmentState,
      }
    )
      .then(({ data }) => {
        setLoaded(true);
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
        setData([...data.data.records.map((item) => ({
          ...item,
          customer: {
            ...item.customer,
            phone: `0${item.customer.phone.substring(
              0,
              3
            )} ${item.customer.phone.substring(
              3,
              6
            )} ${item.customer.phone.substring(6, 10)}`,
          },
          created_at: moment(item.created_at)
            .tz("Europe/Istanbul")
            .format("LLL"),
          appointment_start_date: moment(item.appointment_start_date)
            .tz("Europe/Istanbul")
            .format("LLL"),
        })),
        ].filter((item) => item !== undefined));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAppointmentIndex = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/index`)
      .then((response) => {
        if (response.status === 201) {
          const { data } = response.data;
          setStaffs([...data.staffs]);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getAppointmentIndex();
    getAppointmentsByFilter();
  }, []);

  return (
    <AuthContainer limited_permission="appointment">
      <Grid>
        <Column className="xs-12 sm-12 md-4 lg-3">
          <Alert severity="info" className="mb-2">
            <div>
              <b>{context.t(`['appointments/filter'].alertBoldText`)}</b>
            </div>
            {context.t(`['appointments/filter'].alertText`)}
          </Alert>

          <DatePickerInput
            className="mb-1"
            required
            label={context.t(`['appointments/filter'].startInputLabel`)}
            value={new Date(moment(startDate).toISOString())}
            onChange={async (start_date) => {
              await setLoaded(false);
              await setStartDate(moment(start_date).format("YYYY-MM-DD"))
              await getAppointmentsByFilter();
            }}
          />

          <DatePickerInput
            className="mb-1"
            required
            label={context.t(`['appointments/filter'].endInputLabel`)}
            minDate={new Date(moment(startDate).toISOString())}
            value={new Date(moment(endDate).toISOString())}
            onChange={async (end_date) => {
              await setLoaded(false);
              await setEndDate(moment(end_date).format("YYYY-MM-DD"));
              await getAppointmentsByFilter();
            }}
          />

          <Select
            label={context.t(`['appointments/filter'].statusInputLabel`)}
            items={[
              {
                label: context.t(
                  `['appointments/filter'].statusInputItems[0]`
                ),
                value: "null",
              },
              {
                label: context.t(
                  `['appointments/filter'].statusInputItems[1]`
                ),
                value: 0,
              },
              {
                label: context.t(
                  `['appointments/filter'].statusInputItems[2]`
                ),
                value: 1,
              },
              {
                label: context.t(
                  `['appointments/filter'].statusInputItems[3]`
                ),
                value: 2,
              },
              {
                label: context.t(
                  `['appointments/filter'].statusInputItems[4]`
                ),
                value: 3,
              },
            ]}
            labelKey="label"
            valueKey="value"
            selected={`${appointmentState}`}
            handler={async (value) => {
              await setAppointmentState(value === "null" ? null : parseInt(value));
              await getAppointmentsByFilter();
            }}
          />

          <Select
            label={context.t(`['appointments/filter'].staffInputLabel`)}
            items={[
              {
                id: "null",
                full_name: context.t(
                  `['appointments/filter'].allStaffsText`
                ),
              },
              ...staffs,
            ]}
            labelKey="full_name"
            valueKey="id"
            selected={`${staffId}`}
            handler={async (staff_id) => {
              await setStaffId(staffId !== "null" ? parseInt(staff_id) : null);
              await getAppointmentsByFilter();
            }}
          />

          <Input
            label={context.t(
              `['appointments/filter'].serviceInputLabel`
            )}
            onChange={async ({ target: { value } }) => {
              await setServiceKey(value);
              await getAppointmentsByFilter();
            }}
          />

          <Input
            label={context.t(
              `['appointments/filter'].customerInputLabel`
            )}
            onChange={async ({ target: { value } }) => {
              await setCustomerKey(value);
              await getAppointmentsByFilter();
            }}
          />
        </Column>
        <Column className="xs-12 sm-12 md-8 lg-9">
          <Table
            refreshAction={() =>
              getAppointmentsByFilter(pagination.page)
            }
            loaded={loaded}
            headings={{
              appointment_start_date: {
                label: context.t(
                  `['appointments/filter'].headings.appointmentStartDate`
                ),
                style: { width: 200 },
              },
              "customer.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.customerFullName`
                ),
                //with_photo: "customer.photo",
                limited_line: 1,
              },
              "service.name": {
                label: context.t(
                  `['appointments/filter'].headings.serviceName`
                ),
                limited_line: 1,
              },
              "staff.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.staffFullName`
                ),
                with_photo: "staff.detail.profile_photo",
                limited_line: 1,
              },
              "customer.phone": {
                label: context.t(
                  `['appointments/filter'].headings.customerPhone`
                ),
              },
              created_at: {
                label: context.t(
                  `['appointments/filter'].headings.createdAt`
                ),
              },
            }}
            rows={data}
            pagination={pagination}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
}

export default AppointmentFilter;
