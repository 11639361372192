import React, { useState, useEffect, useContext } from "react";

import { Grid, Column, Container } from "../../../theme/Grid";
import AppContext from "../../../context/store";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { flushSync } from "react-dom";
import moment from "moment";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Info from "../../../assets/images/svg/info.svg";
import Check from "../../../assets/images/svg/check.svg";
import Close from "../../../assets/images/svg/close.svg";
import styled from "styled-components";
import Phone from "../../../assets/images/svg/phone.svg";
import Map from "../../../assets/images/svg/map.svg";

const AppointmentConfirmation = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [appoint, setAppoint] = useState(null);
  const [company, setCompany] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [sureModal, setSureModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //TODO: Get appointment from backend
  const getAppointments = async () => {
    await Axios.get(`${context.api_endpoint}/appointments`, {
      params: {
        appointment_id: parseInt(location.pathname.split("/")[2]),
      },
    })
      .then(({ data }) => {
        flushSync(() => {
          setAppoint(data.data.appointment);
          setCompany(data.data.company);
        });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // toast.error(e.response.data.message);
          setErrorMessage(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Confirm Appointment
  const confirmAppointment = async () => {
    await Axios.put(`${context.api_endpoint}/appointment/customer/state`, {
      appointment_id: parseInt(location.pathname.split("/")[2]),
      customer_state: 3,
    })
      .then(() => {
        setConfirmAlert(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Not Confirm Appointment
  const notConfirmAppointment = () => {
    setConfirmModal(true);
  };

  // TODO: Open Confirm Modal
  const openConfirmModal = () => {
    history.push(`/isletme/${parseInt(location.pathname.split("/")[3])}`);
    setConfirmModal(false);
  };

  // TODO: Close Confirm Modal
  const closeConfirmModal = async () => {
    await Axios.post(`${context.api_endpoint}/appointment/cancel`, {
      appointment_id: parseInt(location.pathname.split("/")[2]),
    })
      .then(() => {
        setConfirmModal(false);
        setCancelAlert(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getAppointments();
  }, []);

  return (
    <>
      {errorMessage ? (
        <>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "50vh",
              width: "90%",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              padding: "20px",
              filter: "drop-shadow(0px 3px 6px rgba(180, 180, 180, 0.329))",
              marginTop: "20px",
            }}
          >
            <Grid className="mt-5">
              <Column className="xs-12 sm-12">
                <SpanDiv>
                  <img src={Check} alt="check" />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {errorMessage}
                  </span>
                </SpanDiv>
              </Column>
            </Grid>
          </Container>
        </>
      ) : (
        <>
          <Container
            style={{
              width: "90%",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              padding: "20px",
              filter: "drop-shadow(0px 3px 6px rgba(180, 180, 180, 0.329))",
              marginTop: "20px",
            }}
          >
            <Grid className="mt-5">
              <Column className="xs-12 sm-12">
                {appoint !== null ? (
                  <SpanDiv>
                    <img src={Info} alt="info" />
                    <span
                      style={{
                        color: "#A880EC",
                        fontSize: "25px",
                      }}
                    >
                      {moment(appoint.appointment_start_date).format(
                        "DD.MM.YYYY dddd HH:mm"
                      )}{" "}
                    </span>
                    <span style={{ fontSize: "20px", marginTop: "10px" }}>
                      {context.t(`businessURL.appointment.appointmentComingText`).replace("%s", appoint.service.name)}{" "}
                    </span>
                  </SpanDiv>
                ) : (
                  ""
                )}
              </Column>
            </Grid>
            <ButtonCa className="mt-2">
              <ButtonY onClick={() => confirmAppointment()}>{context.t(`businessURL.appointment.check.confirmButtonTitle`)}</ButtonY>
              <ButtonN onClick={() => notConfirmAppointment()}>{context.t(`businessURL.appointment.check.discardButtonTitle`)}</ButtonN>
            </ButtonCa>
          </Container>
        </>
      )}
      <AlertDialog
        title={""}
        fullWidth={true}
        buttons={[
          {
            title: context.t(`component.CADialog.appointment_notification.closeButtonTitle`),
            backgroundColor: "primary",
            textColor: "white",
            onClick: () => {
              setConfirmAlert(false);
              window.close();
            },
          },
        ]}
        open={confirmAlert}
        closeHandler={() => {
          setConfirmAlert(false);
        }}
      >
        <SpanDiv>
          <img src={Check} alt="check" />
          <p
            style={{
              fontSize: "20px",
            }}
          >
            {context.t(`businessURL.appointment.appointmentConfirmationText`)}
          </p>
        </SpanDiv>
        {company !== null ? (
          <PhoneDivIcon>
            <img
              src={Map}
              alt="map"
              style={{
                marginRight: "5px",
              }}
            />
            <PhoneDivIconA
              href={`https://www.google.com/maps/search/?api=1&query=${company.map}`}
            >
              {context.t(`businessURL.appointment.getDirectionText`)}
            </PhoneDivIconA>
          </PhoneDivIcon>
        ) : (
          <> </>
        )}
      </AlertDialog>
      <AlertDialog
        title={""}
        fullWidth={true}
        buttons={[
          {
            title: context.t(`businessURL.appointment.check.confirmButtonTitle`),
            timer: 5,
            backgroundColor: "green-opacity",
            textColor: "green",
            onClick: () => {
              closeConfirmModal();
            },
          },
          {
            title: context.t(`businessURL.appointment.check.discardButtonTitle`),
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => {
              setCancelAlert(false);
            },
          },
        ]}
        open={sureModal}
        closeHandler={() => {
          setSureModal(false);
        }}
      >
        <SpanDiv>
          <img src={Close} alt="close" />
          <p
            style={{
              fontSize: "20px",
            }}
          >
            {context.t(`businessURL.appointment.appointmentCancelingText1`)}
          </p>{" "}
        </SpanDiv>
      </AlertDialog>
      <AlertDialog
        title={""}
        fullWidth={true}
        buttons={[
          {
            title: context.t(`component.CADialog.appointment_notification.closeButtonTitle`),
            backgroundColor: "primary",
            textColor: "white",
            onClick: () => {
              setCancelAlert(false);
              window.close();
            },
          },
        ]}
        open={cancelAlert}
        closeHandler={() => {
          setCancelAlert(false);
        }}
      >
        <SpanDiv>
          <img src={Check} alt="Check" />
          <p
            style={{
              fontSize: "20px",
            }}
          >
            {context.t(`businessURL.appointment.appointmentCancelingText2`)}
          </p>{" "}
        </SpanDiv>
      </AlertDialog>
      {confirmModal && (
        <Container
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            padding: "20px",
            filter: "drop-shadow(0px 3px 6px rgba(180, 180, 180, 0.329))",
            marginTop: "20px",
          }}
        >
          <Grid className="mt-2">
            <Column className="xs-12 sm-12">
              <SpanDiv>
                <span style={{ fontSize: "20px" }}>
                  {context.t(`businessURL.appointment.appointmentDatePostponeText`)}{" "}
                </span>
              </SpanDiv>
            </Column>
          </Grid>
          <ButtonCa className="mt-2">
            <ButtonY onClick={() => openConfirmModal()}>{context.t(`businessURL.appointment.check.confirmButtonTitle`)}</ButtonY>
            <ButtonN onClick={() => setSureModal(true)}>{context.t(`businessURL.appointment.check.discardButtonTitle`)}</ButtonN>
          </ButtonCa>
        </Container>
      )}
      {company !== null ? (
        <PhoneDiv>
          <PhoneSpan>{context.t(`businessURL.appointment.businessPhoneText`)}</PhoneSpan>
          <PhoneDivIcon>
            <img src={Phone} alt="phone" />
            <PhoneDivIconA href={`tel:0${company.phone}`}>
              0{company.phone}
            </PhoneDivIconA>
          </PhoneDivIcon>
        </PhoneDiv>
      ) : (
        <> </>
      )}
    </>
  );
};

export default AppointmentConfirmation;

const ButtonCa = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonY = styled.button`
  width: 148px;
  height: 44px;
  color: #ffffff;
  background-color: #5d0ee1;
  filter: drop-shadow(0px 3px 6px rgba(53, 56, 151, 0.31));
  border: none;
  border-radius: 25px;
`;

const ButtonN = styled.button`
  width: 148px;
  height: 44px;
  color: #5d0ee1;
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(53, 56, 151, 0.31));
  border: 1px solid #5d0ee1;
  border-radius: 25px;
  margin-left: 30px;
`;

const SpanDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PhoneDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const PhoneSpan = styled.span`
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: #707070;
`;

const PhoneDivIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PhoneDivIconA = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #5d0ee1;
  text-decoration: underline;
  marginleft: 2px;
`;
