import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import AppContext from '../../context/store';
import Button from '../../theme/Button';
import Axios from 'axios';
import AuthorityNotFound from '../AuthorityNotFound';

import moment from 'moment';

const SystemLogsView = props => {
  const context = useContext(AppContext);
  const [data, setData] = useState({
    loaded: false,
    data: [],
  });


  const getSystemLogs = (page = 1) => {
    Axios
      .post(`${context.api_endpoint}/company/logs`, {
        params: { page }
      })
      .then((response) => {
        const _data = response.data.data;
        response.status === 201 &&
          setData({
            data: [..._data.records.map(item => ({
              ...item,
              created_at: moment(item.created_at).format('LLL'),
              message: item.message?.split('\n')[0]
            }))]
          })
      })
  };

  useEffect(() => {
    props.state && context.state.user.permission && getSystemLogs();
    // eslint-disable-next-line
  }, [props.state])


  return (
    <BoxPopContainer
      className={props.state ? 'show' : 'hide'}
      onMouseOver={props.stopPropagation}
      onClick={props.stopPropagation}
    >
      <BoxPopInner>
        <BoxPopCaption>{context.t(`scaffold.logsViewHeading`)}</BoxPopCaption>
        <ListOverflow>
          {context.state.user.permission
            ? data.data?.map(log =>
              <LogCard>
                <p>{log.message ?? ""}</p>
                <span className="sub">{log.created_at}</span>
                <span className="sub">{log.ip}</span>
              </LogCard>
            )
            : <AuthorityNotFound />
          }
        </ListOverflow>
        {context.state.user.permission === 1 &&
          <Button
            className="mt-1"
            icon="launch"
            title={context.t(`scaffold.logsViewAllButtonTitle`)}
            textColor="primary"
            size="xs"
            fullWidth
            onClick={() => {
              props.history.push('/settings/logs')
              props.closeHandler();
            }}
          />
        }

      </BoxPopInner>
    </BoxPopContainer>
  )
}

export default withRouter(SystemLogsView);


const BoxPopContainer = styled.div`
  display:flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;

  position: fixed;
  z-index: 10000000000000;
  right: 112px;
  top: 66px;

  width: 380px;
  max-width: 80vw;

  background: transparent;
  opacity: 0;

  &.show{
    opacity: 1;
    visibility: visible;
    transition: backdrop-filter 0s, all ease 0.250s;
  }

  &.hide{  
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.250s;
  }

  @media only screen and (max-width: 576px){
    right: 20px !important;
    max-width: calc(100vw - 40px) !important;
  }
`;

const BoxPopCaption = styled.h4`
  font-weight: 600;
  letter-spacing: 0.25px;
  letter-spacing: 0.75px;
  font-size: 11px;
  color: #AAA;
  padding: 8px 0;
  margin: 0;
`;

const BoxPopInner = styled.div`
  width: 100%;
  padding: 8px 16px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 4px 24px rgba(0,0,0,0.08)
`;

const ListOverflow = styled.div`
  max-height: 42vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  
  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    transition: all ease 0.35s;
  }

  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0d0d0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
    transition: all ease 0.35s;
  }
`;


const LogCard = styled.div`
  width: 100%;
  padding: 12px 16px;     
  box-sizing: border-box;
  background: #FFF;
  box-shadow: 0 4px 10px rgba(0,0,0,0.025);
  border: 1px solid #FAFAFA;
  border-radius: 5px;
  margin: 0 0 8px;

  transition: all ease 0.5s;

  p{
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-weight: 500;
    margin: 0 0 2px;
  }
  span.sub{
    font-size: 12px;
    margin-right: 16px;
    display: inline-block;
    color: #606060;
  }
`;
