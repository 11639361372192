import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled from "styled-components";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { ClosedCaptionSharp, Update } from "@material-ui/icons";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../../../theme/CustomMUI/Input";
import MenuItem from "@material-ui/core/MenuItem";
const Settings = () => {
  const context = useContext(AppContext);

  const [show_in_platform, setShowInPlatform] = useState(null);
  const [show_amount_platform, setShowAmountPlatform] = useState(null);
  const [show_comment_platform, setShowCommentPlatform] = useState(null);
  const [appointment_auto_accept, setAppointmentAutoAccept] = useState(null);
  const [platform_color, setPlatformColor] = useState(null);
  const [platform_video_url, setPlatformVideoUrl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [openColorDialog, setOpenColorDialog] = useState(false);
  const [senderApproveCode, setSenderApproveCode] = useState(1);
  const [selectedColorCode, setSelectedColorCode] = useState(null);

  const getSettings = async () => {
    Axios.get(`${context.api_endpoint}/company/platform/setting/index`).then(
      ({ data }) => {
        console.log(data.data);
        setShowInPlatform(data.data.show_in_platform);
        setShowAmountPlatform(data.data.show_amount_platform);
        setShowCommentPlatform(data.data.show_comment_platform);
        setAppointmentAutoAccept(data.data.appointment_auto_accept);
        setPlatformVideoUrl(data.data.platform_video_url);
        setPlatformColor(data.data.platform_color);
        setSelectedColorCode(
          data.data.platform_color ? data.data.platform_color : null
        );
      }
    );
  };

  const updateSettings = async () => {
    const data =
      process.env.APP_NAME === "management"
        ? {
            show_amount_platform: show_amount_platform,
            show_comment_platform: show_comment_platform,
            show_in_platform: show_in_platform,
            appointment_auto_accept: appointment_auto_accept,
            platform_color: selectedColorCode,
            platform_video_url: platform_video_url,
            senderApproveCode: senderApproveCode,
          }
        : {
            show_amount_platform: show_amount_platform,
            show_comment_platform: show_comment_platform,
            show_in_platform: show_in_platform,
            appointment_auto_accept: appointment_auto_accept,
            platform_color: selectedColorCode,
            platform_video_url: platform_video_url,
          };
    await Axios.put(
      `${context.api_endpoint}/company/platform/setting/update`,
      data
    )
      .then(({ data }) => {
        toast.success(context.t(`["platform/settings"].updateSettingsSuccessToast`));
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.data);
        }
      });
  };

  const SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                key={item.key}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    disabled={item.disabled}
                    color="primary"
                    checked={
                      item.key == "show_in_platform"
                        ? show_in_platform === 1
                          ? true
                          : false
                        : item.key == "show_amount_platform"
                        ? show_amount_platform === 1
                          ? true
                          : false
                        : item.key == "show_comment_platform"
                        ? show_comment_platform === 1
                          ? true
                          : false
                        : item.key == "appointment_auto_accept"
                        ? appointment_auto_accept === 1
                          ? true
                          : false
                        : false
                    }
                    onChange={(e) => {
                      console.log(item.key);
                      item.key === "show_in_platform" &&
                        setShowInPlatform(e.target.checked ? 1 : 0);
                      item.key === "show_amount_platform" &&
                        setShowAmountPlatform(e.target.checked ? 1 : 0);
                      item.key === "show_comment_platform" &&
                        setShowCommentPlatform(e.target.checked ? 1 : 0);
                      item.key === "appointment_auto_accept" &&
                        setAppointmentAutoAccept(e.target.checked ? 1 : 0);
                    }}
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  const ColorSelection = () => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>
            {context.t(`['platform/settings'].platformColorSelectionTitle`)}
          </h3>
          <p>
            {context.t(
              `['platform/settings'].platformColorSelectionDescription`
            )}
          </p>

          <Button
            title={context.t(`['platform/settings'].chooseColor`)}
            backgroundColor="blue-opacity"
            textColor="blue"
            fullWidth
            onClick={() => {
              setOpenColorDialog(!openColorDialog);
            }}
          />
        </SettingsBox>
      </Column>
    );
  };

  const ColorSelectionDialog = () => {
    const colors = [
      "#339bcc",
      "#cc3333",
      "#cc3370",
      "#33cc69",
      "#cc8133",
      "#cacc33",
    ];

    const selectColorHandler = (colorItem) => {
      setSelectedColorCode(colorItem);
    };

    const cancelColorHandler = () => {
      setSelectedColorCode(null);
    };

    const closeDialogHandler = () => {
      setOpenColorDialog(false);
    };

    return (
      <AlertDialog
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {context.t(`['platform/settings'].colorDialogTitle`)}
            <IconButton aria-label="close" onClick={closeDialogHandler}>
              <CloseIcon />
            </IconButton>
          </div>
        }
        buttons={[
          {
            title: context.t(`['platform/settings'].colorDialogCancelTitle`),
            icon: "close",
            backgroundColor: "white",
            textColor: "grey",
            disabled: selectedColorCode ? false : true,
            disabledHandleClose: true,
            onClick: cancelColorHandler,
          },
          {
            title: context.t(`['platform/settings'].colorDialogSelectTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {},
          },
        ]}
        fullWidth
        //disableBackdropClick={false}
        maxWidth={"sm"}
        open={openColorDialog}
        closeHandler={() => setOpenColorDialog(false)}
      >
        <div>
          {colors.map((item, index) => (
            <ColorBox
              color={item}
              onClick={() => {
                selectColorHandler(item);
              }}
            >
              <div
                className={`selected-box ${
                  selectedColorCode === item ? "active" : "hide"
                }`}
              >
                <DoneIcon />
              </div>
            </ColorBox>
          ))}
        </div>
      </AlertDialog>
    );
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <Grid>
        {process.env.APP_NAME === "en" ? null : (
          <SettingsBoxBuilder
            boxProps={{
              title: context.t(`['platform/settings'].platformVisibleTitle`),
              description: context.t(
                `['platform/settings'].platformVisibleDescription`
              ),
            }}
            optionList={[
              {
                label: context.t(`['platform/settings'].offOn`),
                key: "show_in_platform",
                disabled: false,
              },
            ]}
          />
        )}
        <SettingsBoxBuilder
          boxProps={{
            title: context.t(`['platform/settings'].platformPriceTitle`),
            description: context.t(
              `['platform/settings'].platformPriceDescription`
            ),
          }}
          optionList={[
            {
              label: context.t(`['platform/settings'].offOn`),
              key: "show_amount_platform",
              disabled: false,
            },
          ]}
        />
        <SettingsBoxBuilder
          boxProps={{
            title: context.t(`['platform/settings'].platformCommentTitle`),
            description: context.t(
              `['platform/settings'].platformCommentDescription`
            ),
          }}
          optionList={[
            {
              label: context.t(`['platform/settings'].offOn`),
              key: "show_comment_platform",
              disabled: false,
            },
          ]}
        />
        <SettingsBoxBuilder
          boxProps={{
            title: context.t(`['platform/settings'].platfromAppointmentTitle`),
            description: context.t(
              `['platform/settings'].platfromAppointmentDescription`
            ),
          }}
          optionList={[
            {
              label: context.t(`['platform/settings'].offOn`),
              key: "appointment_auto_accept",
              disabled: false, // It will be changed.
            },
          ]}
        />
        {process.env.APP_NAME === "management" ? (
          <>
            <SettingsBoxBuilder
              boxProps={{
                title: context.t(
                  `platformSettings.appointmentApprovelCode.title`
                ),
                description: context.t(
                  `platformSettings.appointmentApprovelCode.detail`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['platform/settings'].offOn`),
                  key: "sendApproveCode",
                  disabled: false, // It will be changed.
                },
              ]}
            />
            <SettingsBoxBuilder
              boxProps={{
                title: context.t(
                  `platformSettings.appointmentApprovelCodeType.title`
                ),
                description: context.t(
                  `platformSettings.appointmentApprovelCodeType.detail`
                ),
              }}
            >
              <Select
                className="mb-0"
                items={[
                  // {
                  // 	label: "SMS",
                  // 	value: "0",
                  // },
                  {
                    label: "WP",
                    value: "1",
                  },
                  // {
                  // 	label: "E-Mail",
                  // 	value: "2",
                  // },
                ]}
                // selected={`${state.senderApproveCode}`}
                selected={1}
                labelKey="label"
                valueKey="value"
                handler={(reminder) => setSenderApproveCode(parseInt(reminder))}
              />
            </SettingsBoxBuilder>
          </>
        ) : null}
        {process.env.APP_NAME === "en" ? null : (
          <>
            <ColorSelection />
            <Column className="xs-12 sm-12 md-4">
              <SettingsBox>
                <h3>
                  {context.t(`['platform/settings'].platformVideoURLTitle`)}
                </h3>
                <p>
                  {context.t(
                    `['platform/settings'].platformVideoURLDescription`
                  )}
                </p>
                <Input
                  label={context.t(
                    `['platform/settings'].platformVideoURLInputLabel`
                  )}
                  placeholder={context.t(
                    `['platform/settings'].platformVideoURLInputPlaceholder`
                  )}
                  value={platform_video_url ?? ""}
                  onChange={(e) => {
                    setPlatformVideoUrl(e.target.value);
                  }}
                />
              </SettingsBox>
            </Column>
          </>
        )}
        <Column className="xs-12 sm-12">
          <Button
            iconComponent={Update}
            title={"Ayarları Güncelle"}
            backgroundColor="primary"
            textColor="white"
            fullWidth
            onClick={() => updateSettings()}
          />
        </Column>
      </Grid>
      <ColorSelectionDialog />
    </AuthContainer>
  );
};

export default Settings;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;

const ColorBox = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  background: ${(props) => (props.color ? props.color : "#fff")};
  cursor: pointer;

  .selected-box,
  active,
  hide {
    width: 0px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    transition: all 250ms linear;
  }

  .active {
    width: 40px;
    opacity: 1;
  }

  .hide {
    width: 0px;
    opacity: 0;
  }
`;
