import React, { useContext, useState } from "react";
import AppContext from "../context/store";
import Button from "../theme/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../assets/style/component/Table.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const get = require("lodash.get");

const Table = ({
  isDashboard,
  containerHeight,
  headings,
  disableHeadings,
  rows,
  buttons,
  replacements,
  loaded,
  skeletonCount,
  pagination,
  noDataText,
  showAlwaysPagination,
  checkboxOnChange,
  checkedCheckboxes,
  selectedAll,
  excepteds,
  refreshAction,
  isExist,
}) => {
  const context = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
    refreshAction();
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000); // Disable for 2 seconds
  };
  const isCheckboxChecked = (row) => {
    if (excepteds && excepteds.find((item) => item === row.id)) {
      return false;
    }
    return (
      selectedAll ||
      (checkedCheckboxes.find((item) => item === row.id) ?? false)
    );
  };

  return (
    <div>
      <div style={{ overflowY: "auto", maxHeight: containerHeight ?? "auto" }}>
        {isExist !== false && (
          <Wrapper>
            <RefreshButton onClick={handleClick} disabled={isDisabled}>
              <RefreshIcon style={{ width: "20px" }} />{" "}
              {context.t("branches.headings.listTable.refresh")}
            </RefreshButton>
          </Wrapper>
        )}

        <table className="table-container" border={0}>
          {disableHeadings !== true && (
            <thead>
              <tr>
                {checkboxOnChange && <th style={{ width: "0px" }}></th>}
                {[...Object.keys(headings)].map((key, index) =>
                  key === "_" ? (
                    <th className="action-column" scope="row" key={index}>
                      <div style={{ display: "flex", alignItems: "center", cursor: headings[key].sortable ? "pointer" : "default" }} onClick={headings[key].onClick}>
                        {headings[key].label}
                        {(headings[key].prefix || headings[key].suffix) &&
                          ` (${headings[key].prefix ?? ""}${headings[key].suffix ?? ""})`}
                        {headings[key].sortable && (
                          <div>
                            {headings[key].isClicked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </div>
                        )}
                      </div>
                    </th>
                  ) : (
                    <th
                      scope="row"
                      key={index}
                      style={{
                        ...headings[key].style,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", cursor: headings[key].sortable ? "pointer" : "default" }} onClick={headings[key].onClick}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {headings[key].label}
                            {(headings[key].prefix || headings[key].suffix) &&
                              ` (${headings[key].prefix ?? ""}${headings[key].suffix ?? ""})`}
                          </span>
                        </span>
                        {headings[key].sortable && (
                          <div>
                            {headings[key].isClicked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </div>
                        )}
                      </div>
                    </th>
                  )
                )}
              </tr>
            </thead>
          )}

          <tbody>
            {loaded === false
              ? [...new Array(skeletonCount ? skeletonCount : 5).keys()].map(
                (row, index) => (
                  <tr key={index}>
                    {[...Object.keys(headings)].map((key, index) =>
                      key === "_" ? (
                        <td key={index} className="action-column">
                          {buttons.map((bt, index) => (
                            <Button
                              {...bt}
                              disabled={!loaded}
                              key={index}
                              // size="xs"
                              onClick={() => {
                                bt.onClick
                                  ? bt.onClick(row)
                                  : console.log("Action is not defined!");
                              }}
                            />
                          ))}
                        </td>
                      ) : (
                        <td key={index}>
                          <SkeletonLine
                            className="skeleton"
                            style={
                              buttons === undefined || buttons.length === 0
                                ? { margin: "7px 0" }
                                : null
                            }
                          />
                        </td>
                      )
                    )}
                  </tr>
                )
              )
              : rows.map((row, index) => (
                <tr key={index}>
                  {typeof checkboxOnChange === "function" && (
                    <td style={{ minWidth: "0px" }}>
                      <input
                        key={row.id}
                        className="check"
                        type="checkbox"
                        checked={isCheckboxChecked(row)}
                        onChange={(e) =>
                          checkboxOnChange(row, e.target.checked)
                        }
                      />
                    </td>
                  )}
                  {[...Object.keys(headings)].map((key, index) =>
                    key === "_" ? (
                      <td key={index} className="action-column">
                        {buttons.map((bt, index) => (
                          <Button
                            key={index}
                            {...bt}
                            // size="xs"
                            disabled={
                              typeof bt.disabled === "function"
                                ? bt.disabled(row)
                                : bt.disabled
                            }
                            size="xs"
                            backgroundColor="white"
                            onClick={() => {
                              bt.onClick
                                ? bt.onClick(row)
                                : console.log("Action is not defined!");
                            }}
                          />
                        ))}
                      </td>
                    ) : (
                      <td
                        key={index}
                        style={{
                          ...headings[key].style,
                          height:
                            Object.keys(headings).filter((key) => key === "_")
                              .length === 0
                              ? 52
                              : "auto",
                        }}
                      >
                        {isDashboard ? (
                          <Tooltip
                            title={
                              replacements &&
                                replacements.hasOwnProperty(key) &&
                                replacements[key].hasOwnProperty(
                                  get(row, key)
                                ) ? (
                                replacements[key][get(row, key)] ? (
                                  <>
                                    {headings[key].prefix ?? ""}
                                    {replacements[key][get(row, key)]}
                                    {headings[key].suffix ?? ""}
                                  </>
                                ) : (
                                  "-"
                                )
                              ) : get(row, key) ? (
                                <h3 style={{ cursor: "pointer" }}>
                                  {(row?.type !== 1
                                    ? headings[key].prefix
                                    : "") ?? ""}

                                  {get(row, key)}
                                  {(row?.type === 1 &&
                                    headings[key].amount) ??
                                    ""}
                                  {headings[key].suffix ?? ""}
                                </h3>
                              ) : (
                                "-"
                              )
                            }
                          >
                            <div>
                              {" "}
                              <span style={{ display: "flex" }}>
                                {headings[key].with_photo ? (
                                  <CirclePhoto
                                    src={
                                      get(row, headings[key].with_photo)
                                        ? `${context.api_endpoint.replace(
                                          "api/v1",
                                          ""
                                        )}/${context.state.company_id
                                        }/${get(
                                          row,
                                          headings[key].with_photo
                                        )}`
                                        : require("../assets/images/profile_photo.svg")
                                    }
                                  />
                                ) : null}
                                <span
                                  className={
                                    headings[key].limited_line
                                      ? `limited-line-${headings[key].limited_line}`
                                      : null
                                  }
                                  style={{
                                    marginLeft: headings[key].with_photo
                                      ? 8
                                      : 0,
                                  }}
                                >
                                  {replacements &&
                                    replacements.hasOwnProperty(key) &&
                                    replacements[key].hasOwnProperty(
                                      get(row, key)
                                    ) ? (
                                    replacements[key][get(row, key)] ? (
                                      <>
                                        {headings[key].prefix ?? ""}
                                        {replacements[key][get(row, key)]}
                                        {headings[key].suffix ?? ""}
                                      </>
                                    ) : (
                                      "-"
                                    )
                                  ) : get(row, key) ? (
                                    <>
                                      {(row?.type !== 1
                                        ? headings[key].prefix
                                        : "") ?? ""}

                                      {get(row, key)}
                                      {(row?.type === 1 &&
                                        headings[key].amount) ??
                                        ""}
                                      {headings[key].suffix ?? ""}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          <span style={{ display: "flex" }}>
                            {headings[key].with_photo ? (
                              <CirclePhoto
                                src={
                                  get(row, headings[key].with_photo)
                                    ? `${context.api_endpoint.replace(
                                      "api/v1",
                                      ""
                                    )}/${context.state.company_id}/${get(
                                      row,
                                      headings[key].with_photo
                                    )}`
                                    : require("../assets/images/profile_photo.svg")
                                }
                              />
                            ) : null}
                            <span
                              className={
                                headings[key].limited_line
                                  ? `limited-line-${headings[key].limited_line}`
                                  : null
                              }
                              style={{
                                marginLeft: headings[key].with_photo ? 8 : 0,
                              }}
                            >
                              {replacements &&
                                replacements.hasOwnProperty(key) &&
                                replacements[key].hasOwnProperty(
                                  get(row, key)
                                ) ? (
                                replacements[key][get(row, key)] ? (
                                  <>
                                    {headings[key].prefix ?? ""}
                                    {replacements[key][get(row, key)]}
                                    {headings[key].suffix ?? ""}
                                  </>
                                ) : (
                                  "-"
                                )
                              ) : get(row, key) ? (
                                <>
                                  {(row?.type !== 1
                                    ? headings[key].prefix
                                    : "") ?? ""}

                                  {get(row, key)}
                                  {(row?.type === 1 &&
                                    headings[key].amount) ??
                                    ""}
                                  {headings[key].suffix ?? ""}
                                </>
                              ) : (
                                "-"
                              )}
                            </span>
                          </span>
                        )}
                      </td>
                    )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {loaded === true && rows?.length === 0 && (
        <>
          <NoDataContainer>
            <img
              src={require("../assets/images/undraw/no_data_table.svg")}
              alt="Veri bulunamadı!"
            />
            <span>
              {noDataText
                ? noDataText
                : context.t(`sms.bottom_table_titles.data_not_found`)}
            </span>
          </NoDataContainer>
        </>
      )}
      {/* Pagination Clear And Count Prev Next Added */}
      {(showAlwaysPagination ||
        (rows?.length > 0 && pagination && pagination.total_page)) && (
          <PaginationContainer>
            <div className="pagination p7">
              <ul>
                <button
                  disabled={pagination.page < 2}
                  onClick={() => {
                    const updatedPage = pagination.page - 1; // Calculate the new page number
                    if (updatedPage > 0) {
                      window.history.pushState(
                        { path: "" },
                        "",
                        "?page=" + updatedPage
                      );
                      pagination.onChange(updatedPage); // Trigger the page change callback
                    }
                  }}
                >
                  <li>
                    <ArrowBackIosIcon className="icon-back" fontSize="small" />
                    {/* {context.t(`receipts.previous`)} */}
                  </li>
                </button>
                <button
                  disabled={rows?.length < 10}
                  onClick={() => {
                    const updatedPage = pagination.page + 1; // Calculate the new page number
                    console.log(updatedPage)
                    console.log(pagination)
                    if (updatedPage > 0) {
                      window.history.pushState(
                        { path: "" },
                        "",
                        "?page=" + updatedPage
                      );
                      pagination.onChange(updatedPage); // Trigger the page change callback
                    }
                  }}
                >
                  <li>
                    {/* {context.t(`receipts.next`)}{" "} */}
                    <ArrowForwardIosIcon fontSize="small" />
                  </li>
                </button>
              </ul>
            </div>
          </PaginationContainer>
        )}
    </div>
  );
};

export default React.memo(Table);

export const SkeletonLine = styled.div`
  margin: 10px 0;
  height: 24px;
`;

const CirclePhoto = styled.img`
  display: inline-block;
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  object-fit: cover;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 0;
  outline: 0;
  float: left;

  @media only screen and (max-width: 576px) {
    display: none;

    & + span {
      margin: 0 !important;
    }
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  img {
    height: 160px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #a0a0a0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const RefreshButton = styled.div`
  width: 80px;
  background-color: ${process.env.APP_NAME === "salon"
    ? " #3189ec"
    : process.env.APP_NAME === "en"
      ? "#ffa000"
      : "#ed1e79"};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 1px;
  border-radius: 6px;
  gap: 2px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    opacity: 0.9; /* Hover effect: Change to desired color */
  }
`;
