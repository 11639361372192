import imageCompression from "browser-image-compression";

const DEFAULT_OPTIONS = {
  maxSizeMB: 0.1,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
  fileType: "image/jpg",
};

const CompressImage = async (file, options = DEFAULT_OPTIONS) => {
  try {
    const compressedFile = await imageCompression(file, options);

    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

export default CompressImage;
