import React, { useEffect, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import { GiSandsOfTime } from "react-icons/gi";

function Index() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  function calculateTimeLeft() {
    const now = new Date();

    const nextThreeAM = new Date(now);
    nextThreeAM.setHours(3, 0, 0, 0);

    if (now >= nextThreeAM) {
      nextThreeAM.setDate(nextThreeAM.getDate() + 1);
    }

    const differenceInSeconds = Math.floor((nextThreeAM - now) / 1000);

    return differenceInSeconds;
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return (
      <div
        style={{
          width: "100%",
          fontFamily: "Arial",
          fontSize: "20px",
          textAlign: "center",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <GiSandsOfTime
          style={{ color: formattedHours < 10 ? "#d63447" : "black" }}
          size={35}
        />
        <div>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
              fontSize: "12px",
              color: "rgb(213, 10, 88)",
            }}
          >
            Fırsatı Kaçırma!
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              color: formattedHours < 10 ? "#d63447" : "black",
            }}
          >
            <div>
              <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
              <span>{formattedSeconds}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: "0px",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img style={{ width: "150px" }} src={logo} />
        </div>

        <a
          href="#test"
          style={{
            marginRight: "20px",
            textDecoration: "none",
            color: "black",
          }}
        >
          {formatTime(timeLeft)}
        </a>
      </div>
    </div>
  );
}

export default Index;
