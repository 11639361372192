import React, { useContext, useState, useEffect, useRef } from "react";
import AppContext from "../../../../context/store";

import AuthContainer from "../../../../components/AuthContainer";
import Button from "../../../../theme/Button";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { Column, Grid } from "../../../../theme/Grid";
import { Delete, Edit, WhatsApp } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Table from "../../../../theme/Table";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";

import moment from "moment";
import Axios from "axios";
import { toast } from "react-toastify";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import AutoCompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const AgreementIndex = () => {
  const context = useContext(AppContext);

  const [agreement, setAgreement] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [updateClause, setUpdateClause] = useState(false);

  const [selectedClause, setSelectedClause] = useState({
    id: null,
    title: "",
    content: "",
    type: 1,
  });

  const [printDialog, setPrintDialog] = useState(false);

  // tam ekran için seçilen sözleşme
  const [selectedAgreement, setSelectedAgreement] = useState(null);

  // tam ekran sözleşme pop-up
  const [selectedAgreementDialogShow, setSelectedAgreementDialogShow] =
    useState(false);

  // kişisel bilgileri göster/gösterme
  const [personalInfoShow, setPersonalInfoShow] = useState(false);

  // kişisel bilgiler
  const [nameAndSurname, setNameAndSurname] = useState(true);
  const [phone, setPhone] = useState(true);
  // const [birthDate, setBirthDate] = useState(false);
  // const [job, setJob] = useState(false);
  const [idNumber, setIdNumber] = useState(false);
  const [taxNumber, setTaxNumber] = useState(false);
  const [address, setAddress] = useState(true);

  // ödeme bilgileri göster/gösterme
  const [paymentInfoShow, setPaymentInfoShow] = useState(false);

  // ödeme bilgileri
  const [staffName, setStaffName] = useState(true);
  const [service, setService] = useState(true);
  const [totalAmount, setTotalAmount] = useState(true);

  // sözleşme maddelerini göster/gösterme
  const [clauseInfoShow, setClauseInfoShow] = useState(false);

  // sözleşme maddeleri
  const [agreementClauses, setAgreementClauses] = useState([]);

  // şablon sözleşme ismi
  const [newPatternName, setNewPatternName] = useState("");

  // şirket ismi
  const [companyName, setCompanyName] = useState("");
  const [companyNameShow, setCompanyNameShow] = useState(true);

  // Sözleşme başlığı
  const [patternHeader, setPatternHeader] = useState("Özel Hizmet Sözleşmesi");
  const [patternHeaderShow, setPatternHeaderShow] = useState(true);

  const [patterns, setPatterns] = useState([]);

  const [companyInfoShow, setCompanyInfoShow] = useState(false);
  const [companyInfo, setCompanyInfo] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [deleteAgreementId, setDeleteAgreementId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  // düzenlenecek sözleşme
  const [editAgreement, setEditAgreement] = useState(null);

  // imzalanmış ya da yüklenmiş sözleşmeler
  const [customerAgreements, setCustomerAgreements] = useState([]);
  const [filteredCustomerAgreements, setFilteredCustomerAgreements] = useState(
    []
  );
  // kayıtlı sözleşmeyi silmek için
  const [customerAgreementDeleteId, setCustomerAgreementDeleteId] =
    useState(null);
  // silmek için açılan dialog
  const [deleteAgreementDialog, setDeleteAgreementDialog] = useState(false);
  const [agreementLoad, setAgreementLoad] = useState(true);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [totalByte, setTotalByte] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract("30", "days").tz("Europe/Istanbul").toISOString()
  );
  const [endDate, setEndDate] = useState(
    moment().tz("Europe/Istanbul").toISOString()
  );

  // sms onay dialogu
  const [smsAprovalModal, setSmsAprovalModal] = useState(false);
  // gönderilecek sms id,name,agreement name
  const [sendSmsId, setSendSmsId] = useState(null);
  const [sendSmsCustomerName, setSendSmsCustomerName] = useState(null);
  const [sendSmsAgreementName, setSendSmsAgreementName] = useState(null);

  // Whatsapp onay dialogu
  const [wpAprovalModal, setWpAprovalModal] = useState(false);
  // gönderilecek wp, id, name, agreement name
  const [sendWpId, setSendWpId] = useState(null);
  const [sendWpCustomerName, setSendWpCustomerName] = useState(null);
  const [sendWpAgreementName, setSendWpAgreementName] = useState(null);
  // wp phone number
  const [sendWpPhone, setSendWpPhone] = useState(null);
  const [sendWpMessage, setSendWpMessage] = useState(null);

  const [logoSrc, setLogoSrc] = useState(null);

  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getCustomerAgreements(page),
  });

  const [deleteModal, setDeleteModal] = useState(false);

  const getLogoSrc = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        setLogoSrc(data.data.settings.logo_src);
      })
      .catch((e) => console.log(e));
  };

  // send sms to customer
  const sendSMSAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 1,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  const sendWPAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 2,
    })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // delete customer agreement
  const deleteCustomerAgreement = (id) => {
    Axios.delete(`${context.api_endpoint}/company/contract/template/pdf/${id}`)
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          getCustomerAgreements();
        }
      })
      .catch((err) => {
        toast.error(
          context.t(`["settings/agreements"].agreementRequstFail`)
        );
      });
  };

  // imzalanan sözleşmeleri alı
  const getCustomerAgreements = (page) => {
    setAgreementLoad(false);

    let route;

    if (selectedCustomer) {
      route = `${context.api_endpoint}/company/contract/template/pdf?page=${page ? page : 1
        }&customer_id=${selectedCustomer.id}&start=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&end=${moment(endDate).format("YYYY-MM-DD")}&is_between=true`;
    } else {
      route = `${context.api_endpoint}/company/contract/template/pdf?page=${page ? page : 1
        }&start=${moment(startDate).format("YYYY-MM-DD")}&end=${moment(
          endDate
        ).format("YYYY-MM-DD")}&is_between=true`;
    }

    Axios.get(route)
      .then(({ data }) => {
        if (data.status === 200) {
          setTotalByte(data.data.total_byte);
          setCustomerAgreements(
            data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                fdate: item.created_at,
                opened: item.is_connection_opened,
              };
            })
          );
          setFilteredCustomerAgreements(
            data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                fdate: item.created_at,
                opened: item.is_connection_opened,
              };
            })
          );
          setAgreementLoad(true);
          setPagination({
            ...pagination,
            page: data.data.pdfs.page,
            total_page: data.data.pdfs.records.length,
          });
          console.log(data.data.pdfs.records.length, data.data.pdfs.page);
        }
      })
      .catch((err) => console.log(err));
  };
  // search for customer by name
  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(({ data }) => (arr = [...data.data.records]));
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  // İşletme Bilgilerini alır
  const getCompanyInfo = () => {
    Axios.get(`${context.api_endpoint}/company/itself`)
      .then((data) => {
        if (data.status === 201) {
          setCompanyAddress(data.data.data.address);
          setCompanyName(data.data.data.name);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // sözleşme maddelerini alır
  const getClauses = () => {
    Axios.get(`${context.api_endpoint}/company/contract/clause`)
      .then((data) => {
        setClauses(data.data.data);
      })
      .catch((err) => console.log("hata:", err));
  };

  // Sözleşme maddesini kaydeder
  const saveClauses = () => {
    Axios.post(`${context.api_endpoint}/company/contract/clause`, {
      title: selectedClause.title,
      content: selectedClause.content,
      type: selectedClause.type,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(context.t(`["settings/agreements"].agreementClausesSavedSucces`));
          setClauses([data.data.data, ...clauses]);
          setSelectedClause({});
        }
      })
      .catch((err) => console.log(err));
  };

  // Sözleşme maddesi günceller
  const updateClauses = () => {
    Axios.put(
      `${context.api_endpoint}/company/contract/clause/${selectedClause.id}`,
      {
        title: selectedClause.title,
        content: selectedClause.content,
        type: selectedClause.type,
      }
    )
      .then((data) => {
        toast.success(context.t(`["settings/agreements"].agreementClausesEditSucces`));
        setClauses(
          [...clauses].map((el) =>
            el.id === data.data.data.id ? data.data.data : el
          )
        );
      })
      .catch((err) => console.log(err));
  };

  // Sözleşme maddesi siler
  const deleteClauses = () => {
    Axios.delete(
      `${context.api_endpoint}/company/contract/clause/${selectedClause.id}`
    )
      .then((data) => {
        if (data.data.status === 200) {
          toast.success(context.t(`["settings/agreements"].agreementClausesDeleteSucces`));
          setClauses(
            [...clauses].filter((cla) => cla.id !== selectedClause.id)
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const getAgreements = () => {
    Axios.get(`${context.api_endpoint}/company/contract/template`)
      .then(({ data }) => {
        setPatterns(
          data.data.map((pat) =>
            pat.Clauses === null ? { Template: pat.Template, Clauses: [] } : pat
          )
        );
      })
      .catch((err) => console.log(err));
  };

  const updateAgreement = () => {
    Axios.put(
      `${context.api_endpoint}/company/contract/template/${editAgreement.Template.id}`,
      {
        id: editAgreement.Template.id,
        title: patternHeader,
        template_name: newPatternName,
        company_name: companyName,
        company_info: companyInfo,
        logo_url: false,
        address: address,
        nationality_id: idNumber,
        tax_no: taxNumber,
        phone: phone,
        services_employee_name: staffName,
        show_service: service,
        show_amount: totalAmount,
        clauses: agreementClauses,
      }
    )
      .then(({ data }) => {
        if (data.status === 201) {
          if (data.data.Clauses === null) {
            let newPattern;
            newPattern = { Template: data.data.Template, Clauses: [] };
            setPatterns(
              [...patterns].map((pat) =>
                pat.Template.id === newPattern.Template.id ? newPattern : pat
              )
            );
            setTab(0);
            setIsEdit(false);
            toast.success(context.t(`["settings/agreements"].agreementEditSucces`));
          } else {
            setPatterns(
              [...patterns].map((pat) =>
                pat.Template.id === data.data.Template.id ? data.data : pat
              )
            );
            setTab(0);
            setIsEdit(false);
          }
        } else {
          toast.error(
            context.t(`["settings/agreements"].agreementRequstFail`)
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const saveAgreement = () => {
    Axios.post(`${context.api_endpoint}/company/contract/template`, {
      title: patternHeader,
      template_name: newPatternName,
      company_name: companyName,
      company_info: companyInfo,
      logo_url: false,
      address: address,
      nationality_id: idNumber,
      tax_no: taxNumber,
      phone: phone,
      services_employee_name: staffName,
      show_service: service,
      show_amount: totalAmount,
      clauses: agreementClauses,
    })
      .then((data) => {
        if (data.status === 201) {
          toast.success(context.t(`["settings/agreements"].agreementSaveSucces`));
          if (data.data.data.Clauses === null) {
            setPatterns([
              { Template: data.data.data.Template, Clauses: [] },
              ...patterns,
            ]);
          } else {
            setPatterns([data.data.data, ...patterns]);
          }
          setTimeout(() => {
            setTab(0);
          }, 250);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteAgreement = (id) => {
    Axios.delete(`${context.api_endpoint}/company/contract/template/${id}`)
      .then((data) => {
        if (data.status === 201) {
          setPatterns([...patterns].filter((pat) => pat.Template.id !== id));
          toast.success(data.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const TabMenuItem = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        fullWidth
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "center" }}
        backgroundColor={tab === tab ? "primary-opacity" : ""}
        textColor={tab === tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };

  const Agreements = () => {
    const inputRef = useRef();

    return (
      <>
        <Grid>
          {patterns.map((pattern) => {
            return (
              <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                <div
                  style={{
                    fontSize: "0.5em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditAgreement(pattern);
                    setSelectedAgreement(pattern.Template.id);
                    setSelectedAgreementDialogShow(true);
                  }}
                >
                  <div style={{ textAlign: "center", fontSize: "2em" }}>
                    {pattern.Template.pattern_name}
                  </div>
                  <Column className="sx-12 sm-12 md-12 lg-12">
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2em",
                      }}
                      className="mt-4 mb-2"
                    >
                      {pattern.Template.template_name}
                    </div>
                    <div
                      style={{
                        boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        fontFamily: "roboto",
                        fontSize: "0.8em",
                        height: "60vh",
                        overflow: "scroll",
                      }}
                    >
                      {/* Sözleşme başlığı */}
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "1vw",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "80%",
                              height: "10vh",
                              overflow: "hidden",
                            }}
                          >
                            {logoSrc ? (
                              <img
                                style={{ objectFit: "cover", width: "100%" }}
                                src={`${`${JSON.stringify(context.api_endpoint).replace('/api/v1', '').replaceAll('"', '')}`.replace('/api/v1/', '')}/${context.state.company_id}/${logoSrc}`}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h1>{pattern.Template.company_name}</h1>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <h2 style={{ display: "block" }}>
                              {pattern.Template.title}
                            </h2>
                          </div>
                          <span
                            style={{
                              fontSize: "1.4em",
                              margin: "0 30px 0 auto",
                            }}
                          >
                            {moment().format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </div>

                      {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}
                      <div
                        style={{
                          border: "1px solid black",
                          width: "80%",
                          borderRadius: "3px",
                          padding: "5px 10px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {/* Ad soyad */}
                        <div
                          style={{
                            width: "45%",
                            fontWeight: "bold",
                          }}
                        >
                          {context.t(
                            `[settings/agreements].personal_info.name_and_surname`
                          )}
                          :
                        </div>
                        {/* Telefon Numarası */}
                        {pattern.Template.phone ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.phone`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Doğum Tarihi */}
                        {pattern.Template.birth_date ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.birth_date`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Meslek */}
                        {pattern.Template.job ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.job`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* tc No */}
                        {pattern.Template.nationality_id ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.id_number`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* vergi no */}
                        {pattern.Template.tax_no ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.tax_number`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* adres */}
                        {pattern.Template.address ? (
                          <div
                            style={{
                              width: "90%",
                              fontWeight: "bold",
                            }}
                          >
                            {context.t(
                              `[settings/agreements].personal_info.address`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}
                      {pattern.Template.services_employee_name ||
                        pattern.Template.show_service ? (
                        <div
                          style={{
                            border: "1px solid black",
                            width: "80%",
                            borderRadius: "3px",
                            margin: "10px 0 0 0",
                            padding: "5px 10px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {pattern.Template.services_employee_name ? (
                              <div style={{ width: "45%", fontWeight: "bold" }}>
                                {context.t(
                                  `[settings/agreements].payment_info.staff_name`
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {pattern.Template.show_service ? (
                              <div style={{ width: "45%", fontWeight: "bold" }}>
                                {context.t(
                                  `[settings/agreements].payment_info.service`
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div style={{ display: "flex" }}></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {pattern.Template.show_amount ? (
                        <div
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                            margin: "10px 30px 10px auto",
                          }}
                        >
                          {" "}
                          {context.t(
                            `[settings/agreements].payment_info.total_amount`
                          )}
                          : {process.env.APP_NAME === "management" ? "$" : "₺"}
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                      {clauses
                        .filter((clauseInfo) =>
                          pattern.Clauses.includes(clauseInfo.id)
                        )
                        .filter((el) => el.type === 2).length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "85%",
                              borderBottom: "1px solid #b3b3b3",
                            }}
                          >
                            <span
                              style={{ paddingRight: "24px", fontSize: "8px" }}
                            >
                              {context.t(`[settings/agreements].agreement.yes`)}{" "}
                            </span>
                            <span
                              style={{ paddingRight: "10px", fontSize: "8px" }}
                            >
                              {context.t(`[settings/agreements].agreement.no`)}{" "}
                            </span>
                          </div>
                        )}

                      {pattern.Clauses.map((el, index) => {
                        return (
                          <>
                            {clauses.filter(
                              (clauseInfo) =>
                                clauseInfo.id === el && clauseInfo.type === 2
                            ).length > 0 ? (
                              <div
                                style={{
                                  width: "85%",
                                  padding: "5px 0px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  textAlign: "start",
                                  height: "content-fit",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    {clauses
                                      .filter(
                                        (clauseInfo) => clauseInfo.id === el
                                      )[0]
                                      .content.split("\n")
                                      .map((str) => (
                                        <span
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          {str}
                                        </span>
                                      ))}
                                  </span>
                                  <div
                                    style={{
                                      height: "18px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Checkbox
                                      style={{ transform: "scale(0.2)" }}
                                      checked={true}
                                      fontSize="small"
                                      color="primary"
                                    />
                                    <Checkbox
                                      checked={false}
                                      style={{ transform: "scale(0.2)" }}
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      {clauses
                        .filter((clauseInfo) =>
                          pattern.Clauses.includes(clauseInfo.id)
                        )
                        .filter((el) => el.type === 1).length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "85%",
                              borderBottom: "1px solid #b3b3b3",
                            }}
                          ></div>
                        )}

                      {pattern.Clauses.map((el, index) => {
                        return (
                          <>
                            {clauses.filter(
                              (clauseInfo) =>
                                clauseInfo.id === el && clauseInfo.type !== 2
                            ).length > 0 ? (
                              <div
                                style={{
                                  width: "85%",
                                  padding: "5px 0px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  textAlign: "start",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    {clauses
                                      .filter(
                                        (clauseInfo) => clauseInfo.id === el
                                      )[0]
                                      .content.split("\n")
                                      .map((str) => (
                                        <p
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          {str}
                                        </p>
                                      ))}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "20px 0",
                        }}
                      >
                        <div
                          style={{
                            width: "70%",
                          }}
                        >
                          <div>{pattern.Template.company_name}</div>
                          <span>{pattern.Template.company_info}</span>
                        </div>
                        <div>
                          <span>
                            {" "}
                            {context.t(
                              `[settings/agreements].agreement.signiture`
                            )}
                          </span>
                          <div>{pattern.Template.company_name}</div>
                        </div>
                      </div>
                    </div>
                  </Column>
                </div>
              </Column>
            );
          })}
        </Grid>

        <AlertDialog
          title={""}
          open={selectedAgreementDialogShow}
          fullWidth
          closeHandler={() => {
            setSelectedAgreementDialogShow(false);
          }}
          disableBackdropClick={false}
          buttons={[
            {
              title: context.t(`[settings/agreements].delete`),
              textColor: "white",
              backgroundColor: "red",
              onClick: () => {
                setDeleteAgreementId(selectedAgreement);
                setDeleteConfirm(true);
              },
            },
            {
              title: context.t(`[settings/agreements].edit`),
              textColor: "white",
              backgroundColor: "grey",
              onClick: () => {
                setIsEdit(true);
                setTab(1);
                // Şirket bilgileri
                setCompanyName(editAgreement.Template.company_name);
                setPatternHeader(editAgreement.Template.title);
                setNewPatternName(editAgreement.Template.template_name);
                // Kişisel bilgiler
                setAddress(editAgreement.Template.address);
                setTaxNumber(editAgreement.tax_no);
                setIdNumber(editAgreement.Template.nationality_id);
                setPhone(editAgreement.Template.phone);
                // Ödeme Bilgileri
                setStaffName(editAgreement.Template.services_employee_name);
                setService(editAgreement.Template.show_service);
                setTotalAmount(editAgreement.Template.show_amount);
                // maddeler
                setAgreementClauses(editAgreement.Clauses);
              },
            },
            {
              title: context.t(`[settings/agreements].close`),
              textColor: "white",
              backgroundColor: "primary",
            },
          ]}
        >
          {patterns &&
            patterns
              .filter((pat) => pat.Template.id === selectedAgreement)
              .map((pattern) => {
                return (
                  <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
                    <div
                      ref={inputRef}
                      style={{
                        margin: `${printDialog ? "50px" : ""}`,
                      }}
                    >
                      {!printDialog ? (
                        <div style={{ textAlign: "center" }}>
                          {pattern.Template.pattern_name}
                        </div>
                      ) : (
                        <></>
                      )}
                      <Column className="sx-12 sm-12 md-12 lg-12">
                        <div
                          className="mt-3"
                          style={{
                            boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            fontFamily: "roboto",
                            fontSize: "0.8em",
                          }}
                        >
                          {/* Sözleşme başlığı */}

                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginLeft: "2vw",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "80%",
                                  height: "13vh",
                                  overflow: "hidden",
                                }}
                              >
                                {logoSrc ? (
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                    }}
                                    src={`${`${JSON.stringify(context.api_endpoint).replace('/api/v1', '').replaceAll('"', '')}`.replace('/api/v1/', '')}/${context.state.company_id}/${logoSrc}`}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <h1>{pattern.Template.company_name}</h1>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <h2>{pattern.Template.title}</h2>
                              </div>
                              <span
                                style={{
                                  fontSize: "1em",
                                  margin: "0 50px 0 auto",
                                }}
                              >
                                {moment().format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </div>

                          {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}

                          <div
                            style={{
                              border: "1px solid black",
                              width: "80%",
                              borderRadius: "3px",
                              padding: "5px 10px",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {/* Ad soyad */}
                            <div
                              style={{
                                width: "45%",
                                fontWeight: "bold",
                              }}
                            >
                              {context.t(
                                `[settings/agreements].personal_info.name_and_surname`
                              )}
                              :
                            </div>
                            {/* Telefon Numarası */}
                            {pattern.Template.phone ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.phone`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* Doğum Tarihi */}
                            {pattern.Template.birth_date ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.birth_date`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* Meslek */}
                            {pattern.Template.job ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.job`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* tc No */}
                            {pattern.Template.nationality_id ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.id_number`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* vergi no */}
                            {pattern.Template.tax_no ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.tax_number`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* adres */}
                            {pattern.Template.address ? (
                              <div
                                style={{
                                  width: "90%",
                                  fontWeight: "bold",
                                }}
                              >
                                {context.t(
                                  `[settings/agreements].personal_info.address`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}

                          {pattern.Template.services_employee_name ||
                            pattern.Template.show_service ? (
                            <div
                              style={{
                                border: "1px solid black",
                                width: "80%",
                                borderRadius: "3px",
                                margin: "10px 0 0 0",
                                padding: "5px 10px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                {pattern.Template.services_employee_name ? (
                                  <div
                                    style={{ width: "45%", fontWeight: "bold" }}
                                  >
                                    {context.t(
                                      `[settings/agreements].payment_info.staff_name`
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {pattern.Template.show_service ? (
                                  <div
                                    style={{ width: "45%", fontWeight: "bold" }}
                                  >
                                    {context.t(
                                      `[settings/agreements].payment_info.service`
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div style={{ display: "flex" }}></div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {pattern.Template.show_amount ? (
                            <div
                              style={{
                                fontSize: "1.2em",
                                fontWeight: "bold",
                                margin: "10px 40px 10px auto",
                              }}
                            >
                              {" "}
                              {context.t(
                                `[settings/agreements].payment_info.total_amount`
                              )}
                              :{" "}
                              {process.env.APP_NAME === "management"
                                ? "$"
                                : "₺"}
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                          {clauses
                            .filter((clauseInfo) =>
                              pattern.Clauses.includes(clauseInfo.id)
                            )
                            .filter((el) => el.type === 2).length > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  width: "85%",
                                  borderBottom: "1px solid #b3b3b3",
                                }}
                              >
                                <span
                                  style={{
                                    paddingRight: "24px",
                                    fontSize: "8px",
                                  }}
                                >
                                  {context.t(
                                    `[settings/agreements].agreement.yes`
                                  )}{" "}
                                </span>
                                <span
                                  style={{
                                    paddingRight: "10px",
                                    fontSize: "8px",
                                  }}
                                >
                                  {context.t(
                                    `[settings/agreements].agreement.no`
                                  )}
                                </span>
                              </div>
                            )}

                          {pattern.Clauses.map((el, index) => {
                            return (
                              <>
                                {clauses.filter(
                                  (clauseInfo) =>
                                    clauseInfo.id === el &&
                                    clauseInfo.type === 2
                                ).length > 0 ? (
                                  <div
                                    style={{
                                      width: "85%",
                                      padding: "5px 0px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      textAlign: "start",
                                      height: "content-fit",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {clauses
                                          .filter(
                                            (clauseInfo) => clauseInfo.id === el
                                          )[0]
                                          .content.split("\n")
                                          .map((str) => (
                                            <span
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {str}
                                            </span>
                                          ))}
                                      </span>
                                      <div
                                        style={{
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Checkbox
                                          style={{ transform: "scale(0.2)" }}
                                          checked={true}
                                          fontSize="small"
                                          color="primary"
                                        />
                                        <Checkbox
                                          style={{ transform: "scale(0.2)" }}
                                          checked={false}
                                          fontSize="small"
                                          color="primary"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                          {clauses
                            .filter((clauseInfo) =>
                              pattern.Clauses.includes(clauseInfo.id)
                            )
                            .filter((el) => el.type === 1).length > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  width: "85%",
                                  borderBottom: "1px solid #b3b3b3",
                                }}
                              ></div>
                            )}
                          {pattern.Clauses.map((el, index) => {
                            return (
                              <>
                                {clauses.filter(
                                  (clauseInfo) =>
                                    clauseInfo.id === el &&
                                    clauseInfo.type !== 2
                                ).length > 0 ? (
                                  <div
                                    style={{
                                      width: "85%",
                                      padding: "5px 0px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      textAlign: "start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {clauses
                                          .filter(
                                            (clauseInfo) => clauseInfo.id === el
                                          )[0]
                                          .content.split("\n")
                                          .map((str) => (
                                            <p
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {str}
                                            </p>
                                          ))}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}

                          <div
                            style={{
                              width: "85%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "20px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "70%",
                              }}
                            >
                              <div>{pattern.Template.company_name}</div>
                              <span>{pattern.Template.company_info}</span>
                            </div>
                            <div>
                              <span>
                                {" "}
                                {context.t(
                                  `[settings/agreements].agreement.signiture`
                                )}
                              </span>
                              <div>{context.t(`appointments.updateAppointment.customer`)}</div>
                            </div>
                          </div>
                        </div>
                      </Column>
                    </div>
                  </Column>
                );
              })}
        </AlertDialog>

        <AlertDialog
          title={context.t(`[settings/agreements].deleteAgreementModal.title`)}
          fullWidth
          open={deleteConfirm}
          closeHandler={() => setDeleteConfirm(false)}
          buttons={[
            {
              title: context.t(
                `[settings/agreements].deleteAgreementModal.delete`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () => deleteAgreement(deleteAgreementId),
            },
            {
              title: context.t(`services.delete.discardButtonTitle`),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>
              {context.t(`[settings/agreements].deleteAgreementModal.expTitle`)}
            </b>
            <br />
            {context.t(`[settings/agreements].deleteAgreementModal.expDetail`)}
          </Alert>
        </AlertDialog>
      </>
    );
  };

  const CreateNewAgreement = () => {
    return (
      <>
        <Column className="xs-12 sm-12 md-12 lg-6">
          {/* Kişisel bilgilerin seçildiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() => {
                setPersonalInfoShow(!personalInfoShow);
              }}
            >
              {context.t(
                `[settings/agreements].createTamplateArea.personalInformation`
              )}
            </InfoCard>
            {personalInfoShow ? (
              <InfoCardContent>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* ad soyad */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={nameAndSurname ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].personal_info.name_and_surname`
                      )}
                      outlined={nameAndSurname}
                      textColor={nameAndSurname ? "primary" : "black"}
                    />
                  </Column>

                  {/* Telefon Numarası */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={phone ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].personal_info.phone`
                      )}
                      outlined={phone}
                      textColor={phone ? "primary" : "black"}
                      onClick={() => {
                        setPhone(!phone);
                      }}
                    />
                  </Column>
                </Column>

                {/* Doğum tarihi */}
                {/* <Column className="xs-12 sm-12 md-12 lg-6">
                  <Button
                    className="mb-1"
                    fullWidth={true}
                    size="xs"
                    icon={birth_date ? "check" : "close"}
                    title={context.t(
                      `[settings/agreements].personal_info.birth_date`
                    )}
                    outlined={birth_date}
                    textColor={birth_date ? "primary" : "black"}
                    onClick={() => setBirthDate(!birthDate)}
                  />
                </Column> */}

                {/* Meslek */}
                {/* <Column className="xs-12 sm-12 md-12 lg-6">
                  <Button
                    className="mb-1"
                    fullWidth={true}
                    size="xs"
                    icon={job ? "check" : "close"}
                    title={context.t(
                      `[settings/agreements].personal_info.job`
                    )}
                    outlined={job}
                    textColor={job ? "primary" : "black"}
                    onClick={() => setJob(!job)}
                  />
                </Column> */}

                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={idNumber ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].personal_info.id_number`
                      )}
                      outlined={idNumber}
                      textColor={idNumber ? "primary" : "black"}
                      onClick={() => {
                        setIdNumber(!idNumber);
                      }}
                    />
                  </Column>

                  {/* Vergi no */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={taxNumber ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].personal_info.tax_number`
                      )}
                      outlined={taxNumber}
                      textColor={taxNumber ? "primary" : "black"}
                      onClick={() => {
                        setTaxNumber(!taxNumber);
                      }}
                    />
                  </Column>
                </Column>

                {/* Tc No */}

                {/* Adres */}
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={address ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].personal_info.address`
                      )}
                      outlined={address}
                      textColor={address ? "primary" : "black"}
                      onClick={() => {
                        setAddress(!address);
                      }}
                    />
                  </Column>
                </Column>
              </InfoCardContent>
            ) : (
              <></>
            )}
          </Column>

          {/* Ödeme bilgilerinin seçildiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() => {
                setPaymentInfoShow(!paymentInfoShow);
              }}
            >
              {context.t(
                `[settings/agreements].createTamplateArea.paymentInformation`
              )}
            </InfoCard>

            {paymentInfoShow ? (
              <InfoCardContent>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* Çalışan ismi */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={staffName ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].payment_info.staff_name`
                      )}
                      outlined={staffName}
                      textColor={staffName ? "primary" : "black"}
                      onClick={() => {
                        if (service) {
                          setStaffName(!staffName);
                        }
                      }}
                    />
                  </Column>

                  {/* Servis */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={service ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].payment_info.service`
                      )}
                      outlined={service}
                      textColor={service ? "primary" : "black"}
                      onClick={() => {
                        if (service === true) {
                          setService(false);
                          setStaffName(false);
                        } else {
                          setService(true);
                        }
                      }}
                    />
                  </Column>
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={totalAmount ? "check" : "close"}
                      title={context.t(
                        `[settings/agreements].payment_info.total_amount`
                      )}
                      outlined={totalAmount}
                      textColor={totalAmount ? "primary" : "black"}
                      onClick={() => {
                        setTotalAmount(!totalAmount);
                      }}
                    />
                  </Column>
                </Column>

                {/* Toplam tutar */}
              </InfoCardContent>
            ) : (
              <></>
            )}
          </Column>

          {/* Sözleşme maddelerini seçtiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() => {
                setClauseInfoShow(!clauseInfoShow);
              }}
            >
              {context.t(
                `[settings/agreements].createTamplateArea.clausesInformation`
              )}
            </InfoCard>
            <InfoCardContent>
              {clauseInfoShow ? (
                clauses.map((item) => (
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <div
                      style={{
                        display: "flex",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (agreementClauses.includes(item.id)) {
                          setAgreementClauses(
                            agreementClauses.filter((el) => el !== item.id)
                          );
                        } else {
                          setAgreementClauses([...agreementClauses, item.id]);
                        }
                      }}
                    >
                      {agreementClauses.includes(item.id) ? (
                        <CheckIcon
                          color="primary"
                          fontSize="small"
                          style={{ padding: "5px" }}
                        />
                      ) : (
                        <CloseIcon
                          fontSize="small"
                          style={{ padding: "5px" }}
                        />
                      )}
                      {agreementClauses.includes(item.id) ? (
                        <span
                          style={{
                            border: "1px solid rgba(49, 137, 236, 0.6)",
                            borderRadius: "5px",
                            color: "rgb(49, 137, 236)",
                            padding: "5px",
                          }}
                        >
                          {item.content.split("\n").map((str) => (
                            <p>{str}</p>
                          ))}
                        </span>
                      ) : (
                        <span
                          style={{
                            border: "1px solid rgb(255, 255, 255)",
                            borderRadius: "5px",
                            padding: "5px",
                          }}
                        >
                          {item.content.split("\n").map((str) => (
                            <p style={{ margin: "0 !important" }}>{str}</p>
                          ))}
                        </span>
                      )}
                      {item.type === 2 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid #3189ec",
                                background: "rgba(13, 78, 108, 0.04)",
                                color: "#3189ec",
                                fontSize: "10px",
                                borderRadius: "10px",
                                height: "fit-content",
                                padding: "5px",
                              }}
                            >
                              {context.t(`[settings/agreements].yesOrNo`)}
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Column>
                ))
              ) : (
                <></>
              )}
            </InfoCardContent>
          </Column>
        </Column>

        {/* PDF yerleşimi */}
        <Column className="xs-12 sm-12 md-12 lg-6 mt-1">
          <Column className="sx-12 sm-12 md-12 lg-12">
            <div
              className="mt-3"
              style={{
                boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontFamily: "roboto",
                fontSize: "0.8em",
              }}
            >
              {/* Sözleşme başlığı */}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginLeft: "2vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80%",
                      height: "15vh",
                      overflow: "hidden",
                    }}
                  >
                    {logoSrc ? (
                      <img
                        style={{ objectFit: "cover", width: "100%" }}
                        src={`${`${JSON.stringify(context.api_endpoint).replace('/api/v1', '').replaceAll('"', '')}`.replace('/api/v1/', '')}/${context.state.company_id}/${logoSrc}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!companyNameShow && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      placeholder={context.t(
                        `[settings/agreements].createPdf.companyNamePlaceHolder`
                      )}
                      InputProps={{
                        disableUnderline: true,
                        style: { textAlign: "right" },
                      }}
                      onBlur={() => {
                        setCompanyNameShow(true);
                      }}
                    />
                  )}
                  {companyNameShow && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setCompanyNameShow(false);
                      }}
                    >
                      <h1>{companyName}</h1>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}

                  {!patternHeaderShow && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={patternHeader}
                      onChange={(e) => {
                        setPatternHeader(e.target.value);
                      }}
                      onBlur={() => {
                        setPatternHeaderShow(true);
                      }}
                      placeholder={context.t(
                        `[settings/agreements].createPdf.agreementTitle`
                      )}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  )}
                  {patternHeaderShow && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setPatternHeaderShow(false);
                      }}
                    >
                      <h2>{patternHeader}</h2>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Tarih */}
              <div style={{ width: "80%", textAlign: "end" }}>
                {moment().format("DD/MM/YYYY")}
              </div>

              {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}
              {nameAndSurname || phone || idNumber || taxNumber || address ? (
                <div
                  style={{
                    border: "2px solid black",
                    width: "90%",
                    borderRadius: "3px",
                    padding: "5px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Ad soyad */}
                  {nameAndSurname ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {context.t(
                        `[settings/agreements].personal_info.name_and_surname`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Telefon Numarası */}
                  {phone ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {context.t(`[settings/agreements].personal_info.phone`)}:
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* tc No */}
                  {idNumber ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {context.t(
                        `[settings/agreements].personal_info.id_number`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* vergi no */}
                  {taxNumber ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {context.t(
                        `[settings/agreements].personal_info.tax_number`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* adres */}
                  {address ? (
                    <div
                      style={{
                        width: "90%",
                      }}
                    >
                      {context.t(`[settings/agreements].personal_info.address`)}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}
              {staffName || service ? (
                <div
                  style={{
                    border: "2px solid black",
                    width: "90%",
                    borderRadius: "3px",
                    margin: "10px 0",
                    padding: "5px 10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {staffName ? (
                      <div style={{ width: "45%" }}>
                        {" "}
                        {context.t(
                          `[settings/agreements].personal_info.name_and_surname`
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {service ? (
                      <div style={{ width: "45%" }}>
                        {context.t(
                          `[settings/agreements].payment_info.service`
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div style={{ display: "flex" }}></div>
                </div>
              ) : (
                <></>
              )}

              {totalAmount ? (
                <div
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    margin: "10px 30px 10px auto",
                  }}
                >
                  {" "}
                  {context.t(`[settings/agreements].payment_info.total_amount`)}
                  : {process.env.APP_NAME === "management" ? "$" : "₺"}
                </div>
              ) : (
                <></>
              )}

              {/* Sözleşmenin maddelerinin içerdiği bölüm */}

              {clauses
                .filter((clauseInfo) =>
                  agreementClauses.includes(clauseInfo.id)
                )
                .filter((el) => el.type === 2).length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "90%",
                      borderBottom: "1px solid #b3b3b3",
                    }}
                  >
                    <span style={{ paddingRight: "8px", fontSize: "16px" }}>
                      {context.t(`[settings/agreements].agreement.yes`)}
                    </span>
                    <span style={{ paddingRight: "12px", fontSize: "16px" }}>
                      {context.t(`[settings/agreements].agreement.no`)}
                    </span>
                  </div>
                )}

              {agreementClauses.map((el, index) => {
                return (
                  <>
                    {clauses.filter(
                      (clauseInfo) =>
                        clauseInfo.id === el && clauseInfo.type === 2
                    ).length > 0 ? (
                      <div
                        style={{
                          width: "90%",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          display: "flex",
                          flexWrap: "wrap",
                          textAlign: "start",
                          height: "content-fit",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {clauses
                              .filter((clauseInfo) => clauseInfo.id === el)[0]
                              .content.split("\n")
                              .map((str) => (
                                <p>{str}</p>
                              ))}
                          </span>
                          <div
                            style={{
                              height: "18px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Checkbox
                              style={{ transform: "scale(0.8)" }}
                              checked={true}
                              fontSize="small"
                              color="primary"
                            />
                            <Checkbox
                              checked={false}
                              style={{ transform: "scale(0.8)" }}
                              fontSize="small"
                              color="primary"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}

              {clauses
                .filter((clauseInfo) =>
                  agreementClauses.includes(clauseInfo.id)
                )
                .filter((el) => el.type === 1).length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "90%",
                      borderBottom: "1px solid #b3b3b3",
                    }}
                  ></div>
                )}

              {/* {agreement_clauses.map(el => el.type === 1 ? <>yok</> : <>var</>)} */}
              {agreementClauses.map((el, index) => {
                return (
                  <div
                    style={{
                      width: "90%",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "start",
                    }}
                  >
                    {clauses.filter(
                      (clauseInfo, index1) =>
                        clauseInfo.id === el && clauseInfo.type === 1
                    ).length > 0 ? (
                      <>
                        <span>
                          {clauses
                            .filter((clauseInfo) => clauseInfo.id === el)[0]
                            .content.split("\n")
                            .map((str) => (
                              <p>{str}</p>
                            ))}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}

              <div
                style={{
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 0",
                }}
              >
                <div style={{ width: "70%" }}>
                  <p>{companyName}</p>
                  {!companyInfoShow ? (
                    <TextField
                      margin="normal"
                      multiline
                      required
                      fullWidth
                      value={companyInfo}
                      onChange={(e) => {
                        setCompanyInfo(e.target.value);
                      }}
                      placeholder={context.t(
                        `['settings/agreements'].createTamplateArea.placeHolders`
                      )}
                      InputProps={{
                        disableUnderline: true,
                        style: { textAlign: "right" },
                      }}
                      onBlur={() => {
                        setCompanyInfoShow(true);
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                      onClick={() => {
                        setCompanyInfoShow(false);
                      }}
                    >
                      <p>{companyInfo}</p>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <p>{context.t(`appointments.updateAppointment.customer`)}</p>
                  <span>
                    {" "}
                    {context.t(`[settings/agreements].agreement.signiture`)}
                  </span>
                </div>
              </div>
            </div>
          </Column>
          {isEdit && (
            <Column className="sx-12 sm-12 md-12 lg-12 mt-3">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Column className="sx-12 sm-12 md-12 lg-4">
                  <Button
                    fullWidth
                    size="md"
                    title={context.t(
                      `['settings/agreements'].createTamplateArea.cancel`
                    )}
                    textColor="white"
                    backgroundColor="red"
                    onClick={() => {
                      setIsEdit(false);
                      setTab(0);
                      // Şirket bilgiler
                      setCompanyName("");
                      setPatternHeader("");
                      setNewPatternName("");
                      // Kişisel Bilgiler
                      setAddress(true);
                      setTaxNumber(false);
                      setIdNumber(false);
                      setPhone(true);
                      // Ödeme Bilgileri
                      setStaffName(true);
                      setService(true);
                      setTotalAmount(true);
                      // maddeler
                      setAgreementClauses([]);
                    }}
                  />
                </Column>
              </div>
            </Column>
          )}
        </Column>
      </>
    );
  };

  const EditClauseDetail = () => {
    return (
      <Grid>
        {/* Maddelerin edit sayfasındaki yeri */}
        <Column className="xs-12 sm-12 md-12 lg-6">
          {clauses.map((item) => (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    item.type === 2 ? (
                      <TypeShow>
                        {item.title}
                        <span>
                          {context.t(`[settings/agreements].yesOrNo`)}
                        </span>
                      </TypeShow>
                    ) : (
                      item.title
                    )
                  }
                  secondary={item.content.split("\n").map((str) => (
                    <p>{str}</p>
                  ))}
                  style={{
                    paddingRight: "1.75vw",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Delete
                      onClick={() => {
                        setSelectedClause({
                          title: item.title,
                          content: item.content,
                          id: item.id,
                          type: item.type,
                        });
                        setDeleteModal(true);
                      }}
                    />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit">
                    <Edit
                      onClick={() => {
                        setSelectedClause({
                          title: item.title,
                          content: item.content,
                          id: item.id,
                          type: item.type,
                        });
                        setEditMode(true);
                        setUpdateClause(true);
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
        </Column>

        {/* Maddelerin editleneceği bölüm */}
        <Column
          className="xs-12 sm-12 md-12 lg-6"
          style={{ borderLeft: "1px solid #b2b2b2" }}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3">
              <TextField
                label={context.t(
                  `[settings/agreements].createClausesArea.title`
                )}
                value={selectedClause.title}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setSelectedClause({
                            ...selectedClause,
                            type: selectedClause.type === 1 ? 2 : 1,
                          });
                        }}
                        style={{
                          background: "#fff",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          padding: "8px 16px",
                          border: "1px solid #b2b2b2",
                          borderRadius: "15px",
                          zIndex: "99999999",
                          color: "#3189ec",
                        }}
                      >
                        <span>
                          {" "}
                          {context.t(
                            `[settings/agreements].createClausesArea.yesOrNo`
                          )}
                        </span>
                        <Checkbox
                          style={{
                            transform: "scale(1.5)",
                            padding: "5px 15px",
                          }}
                          checked={selectedClause.type === 1 ? false : true}
                          // onChange={async () => {
                          //   await setClausesType(clauses_type === 1 ? 2 : 1);
                          // }}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSelectedClause({
                    ...selectedClause,
                    title: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12">
              <TextField
                label={context.t(
                  `[settings/agreements].createClausesArea.context`
                )}
                multiline
                value={selectedClause.content}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setSelectedClause({
                    ...selectedClause,
                    content: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12">
              {updateClause ? (
                <>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title={context.t(
                        `[settings/agreements].createClausesArea.update`
                      )}
                      backgroundColor="primary"
                      textColor="white"
                      icon="update"
                      onClick={() => {
                        updateClauses();
                        setEditMode(false);
                        setUpdateClause(false);
                      }}
                    />
                  </Column>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title={context.t(
                        `[settings/agreements].createClausesArea.cancel`
                      )}
                      backgroundColor="white"
                      textColor="black"
                      outlined
                      icon="close"
                      onClick={() => {
                        setSelectedClause({});
                        setEditMode(false);
                        setUpdateClause(false);
                      }}
                    />
                  </Column>
                </>
              ) : (
                <>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title={context.t(
                        `[settings/agreements].createClausesArea.save`
                      )}
                      backgroundColor="primary"
                      textColor="white"
                      icon="save"
                      onClick={() => {
                        if (
                          selectedClause.title.trim() !== "" &&
                          selectedClause.content.trim() !== ""
                        ) {
                          saveClauses();
                          setEditMode(false);
                        } else {
                          toast.warning(
                            context.t(
                              `[settings/agreements].createClausesArea.fillAreaComplately`
                            )
                          );
                        }
                      }}
                    // onClick={() => {
                    //   console.log(clauses_type);
                    // }}
                    />
                  </Column>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title={context.t(
                        `[settings/agreements].createClausesArea.cancel`
                      )}
                      backgroundColor="white"
                      textColor="black"
                      outlined
                      icon="close"
                      onClick={() => {
                        setSelectedClause({});
                        setEditMode(false);
                      }}
                    />
                  </Column>
                </>
              )}
            </Column>
          </Grid>
        </Column>
      </Grid>
    );
  };

  const ClauseDetail = () => {
    return (
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {clauses.map((item) => (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    item.type === 2 ? (
                      <TypeShow>
                        {item.title}
                        <span>
                          {context.t(`[settings/agreements].yesOrNo`)}
                        </span>
                      </TypeShow>
                    ) : (
                      item.title
                    )
                  }
                  secondary={item.content.split("\n").map((str) => (
                    <p>{str}</p>
                  ))}
                  style={{
                    paddingRight: "1.5vw",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Delete
                      onClick={() => {
                        setSelectedClause({
                          title: item.title,
                          content: item.content,
                          id: item.id,
                        });
                        setDeleteModal(true);
                      }}
                    />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit">
                    <Edit
                      onClick={() => {
                        setSelectedClause({
                          ...selectedClause,
                          title: item.title,
                          content: item.content,
                          id: item.id,
                          type: item.type,
                        });
                        setEditMode(true);
                        setUpdateClause(true);
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
        </Column>
      </Grid>
    );
  };

  useEffect(() => {
    getCustomerAgreements();
  }, [startDate, endDate])

  const CustomerAgreements = () => {
    return (
      <Grid>
        <Column className="sx-12 sm-12 md-12 lg-12">
          <Column className="sx-12 sm-12 md-12 lg-4">
            <DatePickerInput
              disableFuture
              className="mb-1"
              required
              label={context.t(
                `[settings/agreements].agreementsList.startDate`
              )}
              value={startDate}
              onChange={(date) => {
                setStartDate(moment(date).tz("Europe/Istanbul").toISOString());
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <DatePickerInput
              disableFuture
              className="mb-1"
              required
              label={context.t(`[settings/agreements].agreementsList.endDate`)}
              value={endDate}
              onChange={(date) => {
                setEndDate(moment(date).tz("Europe/Istanbul").toISOString());
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <AutoCompleteInput
              autoHighLight
              label={context.t(
                `[settings/agreements].agreementsList.searchCustomer`
              )}
              placeholder={context.t(
                `[settings/agreements].agreementsList.searchCustomer`
              )}
              labelKey="full_name"
              onRemove={() => {
                setSelectedCustomer(null);
              }}
              valueKey="id"
              selected={selectedCustomer !== null ? selectedCustomer : ""}
              returnFullObject
              selectedHandler={(selected_customer) => {
                setSelectedCustomer(selected_customer);
              }}
              asyncDataService={async (keyword) =>
                await searchCustomerByKey(keyword)
              }
            />
          </Column>
        </Column>
        <Column className="sx-12 sm-12 md-12 lg-12">
          <Table
            refreshAction={() => getCustomerAgreements(pagination.page)}
            headings={{
              date: {
                label: context.t(`[settings/agreements].agreementsList.date`),
              },
              name: {
                label: context.t(
                  `[settings/agreements].agreementsList.nameSurname`
                ),
              },
              agreement_name: {
                label: context.t(
                  `[settings/agreements].agreementsList.agreementName`
                ),
              },
              bytes: {
                label: context.t(`[settings/agreements].agreementsList.size`),
                suffix: "mb",
              },
              _: {
                label: context.t(
                  `[settings/agreements].agreementsList.actions`
                ),
              },
            }}
            rows={filteredCustomerAgreements}
            loaded={agreementLoad}
            pagination={pagination}
            buttons={[
              {
                title: "",
                icon: "find_in_page",
                textColor: "blue",
                onClick: (row) => window.open(row.url, "_blank"),
              },
              // {
              //   title: "",
              //   icon: <WhatsApp fontSize="small" />,
              //   textColor: "green",
              //   onClick: (row) => {
              //     if (context.state.whatsapp_permission) {
              //       toast.warning(
              //         "Ücretli içerik. Lütfen müşteri temsilciniz ile iletişime geçiniz."
              //       );
              //     } else {
              //       setWpAprovalModal(true);
              //       setSendWpId(row.id);
              //       setSendWpCustomerName(row.name);
              //       setSendWpAgreementName(row.agreement_name);
              //     }
              //   },
              // },
              {
                title: "",
                icon: "sms",
                textColor: "orange",
                onClick: (row) => {
                  setSmsAprovalModal(true);
                  setSendSmsId(row.id);
                  setSendSmsCustomerName(row.name);
                  setSendSmsAgreementName(row.agreement_name);
                },
              },
              {
                title: "",
                icon: "delete_forever",
                textColor: "red",
                onClick: (row) => {
                  setDeleteAgreementId(row.id);
                  setDeleteAgreementDialog(true);
                },
              },
            ]}
          />
        </Column>

        {/* **
        sms onayı için açılan dialog
        */}
        <AlertDialog
          title={context.t(`[settings/agreements].smsAproove.title`)}
          open={smsAprovalModal}
          closeHandler={() => setSmsAprovalModal(false)}
          buttons={[
            {
              title: context.t(`[settings/agreements].smsAproove.send`),
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                sendSMSAgreement(sendSmsId);
              },
            },
            {
              title: context.t(`[settings/agreements].smsAproove.cancel`),
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                setSendSmsId(null);
                setSendSmsCustomerName(null);
                setSendSmsAgreementName(null);
              },
            },
          ]}
        >
          {sendSmsCustomerName}{" "}
          {context.t(`[settings/agreements].smsAproove.textFirst`)} '
          {sendSmsAgreementName}'{" "}
          {context.t(`[settings/agreements].smsAproove.textSecond`)}
        </AlertDialog>

        {/* **
        wp onayı için açılan dialog
        */}
        <AlertDialog
          title={context.t(`[settings/agreements].wpAproove.title`)}
          // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          open={wpAprovalModal}
          closeHandler={() => setWpAprovalModal(false)}
          buttons={[
            {
              title: context.t(`[settings/agreements].wpAproove.send`),
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                sendWPAgreement(sendWpId);
              },
            },
            {
              title: context.t(`[settings/agreements].wpAproove.cancel`),
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                setSendWpId(null);
                setSendWpCustomerName(null);
                setSendWpAgreementName(null);
              },
            },
          ]}
        >
          {sendWpCustomerName}{" "}
          {context.t(`[settings/agreements].wpAproove.textFirst`)} '
          {sendWpAgreementName}'{" "}
          {context.t(`[settings/agreements].wpAproove.textSecond`)}
        </AlertDialog>

        {/* **
        Sözleşmesi silmek için açılan dialog 
        */}
        <AlertDialog
          title={context.t(`[settings/agreements].deleteAgreement.title`)}
          open={deleteAgreementDialog}
          closeHandler={() => setDeleteAgreementDialog(false)}
          buttons={[
            {
              title: context.t(`[settings/agreements].deleteAgreement.aproove`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                deleteCustomerAgreement(deleteAgreementId);
              },
            },
            {
              title: context.t(`[settings/agreements].deleteAgreement.cancel`),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>
              {context.t(`[settings/agreements].deleteAgreement.infoTitle`)}
            </b>
            <br />
            {context.t(`[settings/agreements].deleteAgreement.infoContext`)}
          </Alert>
        </AlertDialog>
      </Grid>
    );
  };

  useEffect(() => {
    getClauses();
    getAgreements();
    getCompanyInfo();
    getLogoSrc();
  }, []);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevState = usePrevious(selectedCustomer);

  useEffect(() => {
    // Önceki durum ile mevcut durumu karşılaştırın
    if (prevState !== selectedCustomer) {
      // customer_state değiştiğinde myFunc fonksiyonunu çağırın
      getCustomerAgreements();
    }
  }, [selectedCustomer]);

  return (
    <AuthContainer authorities={[1]} limited_permission="sms">
      <Grid>
        <Column
          className="sx-12 sm-12 md-12 lg-12"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column
            className="sx-12 sm-12 md-12 lg-8"
            style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px" }}
          >
            <Column className="sx-12 sm-12 md-12 lg-3">
              <TabMenuItem
                tab={0}
                btnProps={{
                  iconComponent: "",
                  title: context.t(`[settings/agreements].agreementTemplates`),
                  onClick: () => {
                    setIsEdit(false);
                    setTab(0);
                  },
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-3">
              <TabMenuItem
                tab={1}
                btnProps={{
                  iconComponent: "",
                  title: `${isEdit
                    ? context.t(`[settings/agreements].editAgreement`)
                    : context.t(`[settings/agreements].createNewAgreement`)
                    }`,
                  onClick: () => {
                    setTab(1);
                  },
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-3">
              <TabMenuItem
                tab={2}
                btnProps={{
                  iconComponent: "",
                  title: context.t(`[settings/agreements].agreementClauses`),
                  onClick: () => {
                    setIsEdit(false);
                    setTab(2);
                  },
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-3">
              <TabMenuItem
                tab={3}
                btnProps={{
                  iconComponent: "",
                  title: context.t(`[settings/agreements].agreements`),
                  onClick: () => {
                    setIsEdit(false);
                    setTab(3);
                    getCustomerAgreements();
                  },
                }}
              />
            </Column>
          </Column>
          {tab === 1 && (
            <Column className="sx-12 sm-12 md-12 lg-4">
              <Column
                className="sx-12 sm-12 md-12 lg-8"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <TextField
                  inputProps={{ maxLength: 45 }}
                  required
                  id="outlined-required"
                  label={context.t(
                    `[settings/agreements].createTamplateArea.label`
                  )}
                  placeholder={context.t(
                    `[settings/agreements].createTamplateArea.placeHolder`
                  )}
                  variant="outlined"
                  fullWidth
                  value={newPatternName}
                  onChange={(e) => {
                    setNewPatternName(e.target.value);
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-4">
                <Button
                  disabled={
                    newPatternName === "" ||
                    newPatternName === null ||
                    newPatternName.trim().length === 0
                  }
                  fullWidth={true}
                  size="md"
                  icon="save"
                  title={
                    isEdit
                      ? context.t(
                        `[settings/agreements].createTamplateArea.editTamplate`
                      )
                      : context.t(
                        `[settings/agreements].createTamplateArea.saveTamplate`
                      )
                  }
                  textColor="white"
                  backgroundColor="primary"
                  onClick={() => {
                    if (isEdit) {
                      updateAgreement();
                    } else {
                      saveAgreement();
                    }
                  }}
                />
              </Column>
            </Column>
          )}
          {tab === 2 && (
            <Column
              className="sx-12 sm-12 md-12 lg-4"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Column className="sx-12 sm-12 md-12 lg-6"></Column>
              <Column className="sx-12 sm-12 md-12 lg-6">
                <Button
                  fullWidth={true}
                  title={context.t(
                    `[settings/agreements].createClausesArea.createNewClauses`
                  )}
                  backgroundColor="primary"
                  textColor="white"
                  icon="add"
                  onClick={() => {
                    setEditMode(true);
                    setSelectedClause({
                      ...selectedClause,
                      type: 1,
                    });
                  }}
                />
              </Column>
            </Column>
          )}
          {tab === 3 && (
            <Column
              className="sx-12 sm-12 md-12 lg-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Column className="sx-12 sm-12 md-12 lg-4">
                {(totalByte / 1024 / 1024).toFixed(2)}mb / 50mb
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-8">
                <BorderLinearProgress
                  variant="determinate"
                  value={(2 * totalByte) / 1024 / 1024}
                />
              </Column>
            </Column>
          )}
        </Column>
      </Grid>
      <Grid>{tab === 0 && <Agreements />}</Grid>
      <Grid>{tab === 1 && <CreateNewAgreement />}</Grid>
      {editMode ? (
        <Grid>{tab === 2 && <EditClauseDetail />}</Grid>
      ) : (
        <Grid>{tab === 2 && <ClauseDetail />}</Grid>
      )}
      <Grid>{tab === 3 && <CustomerAgreements />}</Grid>
      <AlertDialog
        title={context.t(`[settings/agreements].deleteClausesModal.title`)}
        open={deleteModal}
        fullWidth
        closeHandler={() => {
          setDeleteModal(false);
        }}
        disableBackdropClick={false}
        buttons={[
          {
            title: context.t(`[settings/agreements].deleteClausesModal.delete`),
            textColor: "primary",
            backgroundColor: "primary-opacity",
            icon: "check",
            onClick: () => {
              deleteClauses();
            },
          },
          {
            title: context.t(`[settings/agreements].deleteClausesModal.cancel`),
            textColor: "grey",
            backgroundColor: "white",
            icon: "clear",
          },
        ]}
      >
        <div>
          <Alert severity="warning">
            <b>
              {" "}
              {context.t(`[settings/agreements].deleteClausesModal.expTitle`)}
            </b>
            <div style={{ marginTop: "15px" }}>{selectedClause.title}</div>
            <div>{selectedClause.content}</div>
            <br />
            <b>
              {context.t(`[settings/agreements].deleteClausesModal.expDetail`)}
            </b>
          </Alert>
        </div>
      </AlertDialog>
    </AuthContainer>
  );
};

export default AgreementIndex;

const InfoCard = styled.div`
  text-align: center;
  background-color: rgba(49, 137, 236, 0.6);
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;

const InfoCardContent = styled.div`
  margin-top: 5px;
`;

const Contents = styled.div`
  border: 1px solid red;
  width: 50%;
`;

const TypeShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    border: 1px solid #3189ec;
    background: rgba(13, 78, 108, 0.04);
    color: #3189ec;
    font-size: 10px;
    border-radius: 10px;
    height: fit-content;
    padding: 5px;
    margin-left: 10px;
  }
`;
