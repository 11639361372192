import styled from 'styled-components'

const FCLabel = styled.p`
  width: 100%;
  display: inline-flex;
  line-height: 24px;
  font-size: 14.4px;
  letter-spacing: 0;
  margin: 0;
  white-space: pre-wrap;
  font-weight: 500;

  a{
    color: var(--primary);
    text-decoration: none;
  }

  i.material-icons{
    line-height: 24px;
    font-size: 18px;
    margin-right: 4px;
  }

`;

export default FCLabel;