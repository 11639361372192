import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import AppContext from "../../context/store";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StaffOrderDialog = (props) => {
  const context = useContext(AppContext);
  const [orderedStaffs, setOrderedStaffs] = useState([]);

  const matches = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    // console.log("staff", props.staffs);
    setOrderedStaffs([...props.staffs]);
  }, [props.staffs]);

  const handleOnDragEnd = (result) => {
    if (result.destination !== null) {
      const items = Array.from(orderedStaffs);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const orderedList = [];
      items.forEach((staff, index) => {
        orderedList.push({
          staff_id: staff.id,
          order_no: index + 1,
        });
      });
      setOrderedStaffs([...items]);
      props.orderedHandler([...orderedList]);
    }
  };

  return (
    <AlertDialog
      title={context.t(`component.staffOrderDialog.staffOrderTitle`)}
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
      buttons={[
        {
          title: context.t(`component.staffOrderDialog.closeButtonTitle`),
          icon: "close",
          backgroundColor: "primary",
          textColor: "white",
        },
      ]}
      closeHandler={() => {
        props.closeHandler();
      }}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="staffs"
          direction={`${matches ? "vertical" : "horizontal"}`}
        >
          {(provided) => (
            <StaffContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              type={matches}
            >
              {orderedStaffs.map((staff, index) => {
                return (
                  <Draggable
                    key={staff.full_name}
                    draggableId={staff.full_name}
                    index={index}
                  >
                    {(provided) => {
                      return (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <StaffBox>
                            <h4>{index + 1}.</h4>
                            <img
                              src={
                                staff.detail.profile_photo
                                  ? `${context.api_endpoint}/${context.state.company_id}/${staff.detail.profile_photo}`.replace(
                                      "api/v1/",
                                      ""
                                    )
                                  : staff.sex === 0
                                  ? require("../../assets/images/staff_woman.jpg")
                                  : staff.sex === 1
                                  ? require("../../assets/images/staff_man.jpg")
                                  : require("../../assets/images/profile_photo.svg")
                              }
                              alt=""
                              className="staff-img"
                            />
                            <span>{staff.full_name}</span>
                          </StaffBox>
                        </li>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </StaffContainer>
          )}
        </Droppable>
      </DragDropContext>
    </AlertDialog>
  );
};

export default React.memo(StaffOrderDialog);

const StaffContainer = styled.ul`
  list-style-type: none;
  overflow: auto;
  display: flex;
  flex-direction: ${(props) => (props.type ? "column" : "row")};
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const StaffBox = styled.div`
  width: 100%;
  border-radius: 5px;
  list-style-type: none;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .staff-img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }
`;
