import React, { useContext } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { MdSupportAgent } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import AppContext from "../../../../context/store";

function Statisctic() {
  const context = useContext(AppContext);
  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
      }}
      className="container"
    >
      <Grid style={{ marginTop: "50px" }} item xs={12} sm={12} md={12}>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: 1.4,
            color: "#0b3052",
            height: "80px",
          }}
        >
          {context.t(`campaigns.statistics.title1`)}{" "}
          <span
            style={{
              color:
                process.env.APP_NAME === "salon"
                  ? "rgb(213, 10, 88)"
                  : "var(--primary)",
            }}
          >
            {process.env.APP_NAME === "salon"
              ? "Salon"
              : process.env.APP_NAME === "en"
              ? "EnRandevu"
              : "Management"}
          </span>{" "}
          {context.t(`campaigns.statistics.title2`)}
        </p>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <MdSupportAgent size={60} />
            </div>
          </div>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            {context.t(`campaigns.statistics.title3`)}
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            {context.t(`campaigns.statistics.title4`)}
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <FaMobileAlt size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            {context.t(`campaigns.statistics.title5`)}
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            {context.t(`campaigns.statistics.title6`)}
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <VscWorkspaceTrusted size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            {context.t(`campaigns.statistics.title7`)}
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            {context.t(`campaigns.statistics.title8`)}
          </p>
        </Column>
      </Grid>
    </div>
  );
}

export default Statisctic;
