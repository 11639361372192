/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import HoverHelperText from "../HoverHelperText";

const Select = (props) => {
  useEffect(() => {});
  return (
    <div className={props.className ? props.className : "mb-2"}>
      {props.hoverHelperText && (
        <HoverHelperText hoverHelperText={props.hoverHelperText} />
      )}
      <TextField
        style={
          props.label === "Ödeme Durumu"
            ? props.selected === "0"
              ? {
                  backgroundColor: "#F7DAB9",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : props.selected === "1"
              ? {
                  backgroundColor: "#ACD3B7",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : props.selected === "2"
              ? {
                  backgroundColor: "#FEB2CA",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : {
                  ...props.style,
                }
            : props.label === "Durumu"
            ? props.selected === "2"
              ? {
                  backgroundColor: "#F7DAB9",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : props.selected === "0"
              ? {
                  backgroundColor: "#ACD3B7",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : props.selected === "1"
              ? {
                  backgroundColor: "#FEB2CA",
                  borderRadius: "8px",
                  marginLeft: "8px",
                }
              : {
                  ...props.style,
                }
            : {
                ...props.style,
              }
        }
        select
        disabled={props.disabled}
        label={props.label}
        value={props.selected ? props.selected : ""}
        onChange={
          props.handler
            ? (e) =>
                props.returnFullObject === true
                  ? props.handler(
                      props.items.filter(
                        (item) => item[props.valueKey] == e.target.value
                      )[0]
                    )
                  : props.handler(`${e.target.value}`)
            : () => console.log("handler is not defined!")
        }
        helperText={props.helperText}
        required={props.required}
        fullWidth
        size={props.size}
        variant={"outlined"}
        title={props.title}
      >
        {props.items &&
          props.items.map((option, index) => (
            <MenuItem key={index} value={`${option && option[props.valueKey]}`}>
              {props.label === "Ödeme Durumu" ? (
                <>
                  {option[props.valueKey] === "0" ? (
                    <span
                      style={{
                        color: "#FF9016",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === "1" ? (
                    <span
                      style={{
                        color: "#1D8B3E",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === "2" ? (
                    <span
                      style={{
                        color: "#FF2C6F",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : (
                    <span>{option[props.labelKey]}</span>
                  )}
                </>
              ) : props.label === "İşlem Durumu" ? (
                <>
                  {option[props.valueKey] === 0 ? (
                    <span
                      style={{
                        color: "#1D8B3E",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === 1 ? (
                    <span
                      style={{
                        color: "#FF2C6F",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === 2 ? (
                    <span
                      style={{
                        color: "#FF9016",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : (
                    <span>{option[props.labelKey]}</span>
                  )}
                </>
              ) : props.label === "Durumu" ? (
                <>
                  {option[props.valueKey] === 0 ? (
                    <span
                      style={{
                        color: "#1D8B3E",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === 1 ? (
                    <span
                      style={{
                        color: "#FF2C6F",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : option[props.valueKey] === 2 ? (
                    <span
                      style={{
                        color: "#FF9016",
                      }}
                    >
                      {option[props.labelKey]}
                    </span>
                  ) : (
                    <span>{option[props.labelKey]}</span>
                  )}
                </>
              ) : (
                <span>{option && option[props.labelKey]}</span>
              )}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

export default React.memo(Select);
