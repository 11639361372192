import React from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { MdSupportAgent } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { VscWorkspaceTrusted } from "react-icons/vsc";

function Statisctic() {
  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
      }}
      className="container"
    >
      <Grid style={{ marginTop: "50px" }} item xs={12} sm={12} md={12}>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "21px",
            fontWeight: 500,
            //lineHeight: 1.4,
            color: "#0b3052",
            height: "80px"
          }}
        >Güvenle Adım Atın,
          <span style={{ color: "#ffa000" }}> EnRandevu</span>{" "}
          ile Yol Alın
        </p>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <MdSupportAgent size={60} />
            </div>
          </div>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Anında Canlı Destek
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            İhtiyaç duyduğunuz her an, ekibimiz 7/24 size destek olmak için hazır! İletişim bilgilerimiz üzerinden bize kolayca ulaşabilirsiniz.
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <FaMobileAlt size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Kullanıcı dostu kolaylık
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            Salonunuza dair tüm bilgileri isterseniz web panel üzerinden, isterseniz de mobil uygulama aracılığıyla her an kontrol edebilmenin rahatlığıyla tanışın.
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <VscWorkspaceTrusted size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            %100 Güven
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            Tüm verileriniz bizimle güvende! İşletmenize ve size dair her türlü bilgiyi titizlikle saklıyor ve koruyoruz.
          </p>
        </Column>
      </Grid>
    </div>
  );
}

export default Statisctic;
