import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";

import { Grid, Column } from "../../../../theme/Grid";
import Table from "../../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import FCLabel from "../../../../theme/FCLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import RoundedRow from "../../../../components/Table/RoundedRow";
import { toast } from "react-toastify";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import Select from "../../../../theme/CustomMUI/Select";

import Loader from "../../../../assets/images/loader.gif";
import LoaderEN from "../../../../assets/images/circular.gif";
import LoaderSM from "../../../../assets/images/loaderSM.gif";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const PackageSalesIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [deleteId, setDeleteId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isCreatedAtASCDESC, setIsCreatedAtASCDESC] = useState(false); // true -> asc | false -> desc
  const [isNameASCDESC, setIsNameASCDESC] = useState(false); // true -> asc | false -> desc
  const [fieldId, setFieldId] = useState(null)
  const [order, setOrder] = useState(null)
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);
  const [selectedFilterPicker, setSelectedFilterPicker] = useState(null);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getOpenedReceipts(page),
  });
  const [data, setData] = useState([]);

  const getOpenedReceipts = (page, fieldId, order) => {
    const urlPage = parseInt(new URLSearchParams(location.search).get("page"));
    setLoading(true);
    Axios.get(
      `${context.api_endpoint}/company/receipts/packets`,
      filterExpanded
        ? {
          params: {
            page: page ? page : urlPage ? urlPage : 1,
            field: fieldId,
            order: order ? 1 : 0,
            ...(selectedFilterPicker
              ? {
                offset: selectedFilterPicker.offset,
                isbetween: false,
                customerid: selectedCustomer ? selectedCustomer.id : null,
              }
              : {
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                isbetween: true,
                customerid: selectedCustomer ? selectedCustomer.id : null,
              }),
          },
        }
        : {
          params: {
            page: page ? page : urlPage ? urlPage : 1,
            field: fieldId,
            order: order ? 1 : 0,
          },
        }
    )
      .then((response) => {
        const { data } = response;
        setLoading(false);
        setLoaded(true);
        setData(
          data.data.records
            ? [
              // data.data.records
              ...data.data.records.map((record) => {
                return {
                  ...record,

                  all_amount: (
                    <RoundedRow color="orange">
                      {record.all_amount
                        ? `${Math.round(record.all_amount.toFixed(2))}.00`
                        : "0.00"}
                      {context.state.currency ? context.state.currency : "₺"}
                    </RoundedRow>
                  ),
                  paid: (
                    <RoundedRow color="green">
                      {record.paid > 0
                        ? `${Math.round(record.paid.toFixed(2))}.00`
                        : "0.00"}
                      {context.state.currency ? context.state.currency : "₺"}
                    </RoundedRow>
                  ),

                  debt_total: (
                    <RoundedRow color="red">
                      {(record.all_amount ?? 0) - (record.paid ?? 0) > 0
                        ? `${Math.round(
                          (
                            (record.all_amount ?? 0) - (record.paid ?? 0)
                          ).toFixed(2)
                        )}.00`
                        : "0.00"}
                      {context.state.currency ? context.state.currency : "₺"}
                    </RoundedRow>
                  ),

                  created_at: moment(record.created_at)
                    .local()
                    .format("DD/MM/YYYY HH:mm"),
                };
              }),
            ]
            : []
        );
        setPagination({
          ...pagination,
          page: data.data.page ? data.data.page : null,
          total_page: data.data.records ? data.data.records.length : null,
        });
      })
      .catch(() => {
        toast.error("something went wrong");
        history.push("/wallet");
      });
  };

  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(({ data }) => {
      setCustomers([...data.data.records]);
    });

    return customers;
  };

  useEffect(() => {
    getOpenedReceipts();
    // getCustomers();

    const unlisten = history.listen((location, action) => {
      pagination.page !== null && action === "POP" && getOpenedReceipts();
    });

    return () => unlisten();
  }, []);

  useEffect(() => {
    getOpenedReceipts(1, fieldId, order)
  }, [
    filterExpanded,
    start,
    end,
    selectedCustomer,
    selectedFilterPicker,
    order,
    fieldId
  ])
  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid className="mb-2">
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filterExpanded}
                onChange={(e) => {
                  setLoaded(false);
                  setFilterExpanded(e.target.checked);
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>
        {filterExpanded && (
          <>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <DatePickerInput
                className="mb-0"
                label={context.t(`receipts.filter.startInputLabel`)}
                value={new Date(moment(start).toISOString())}
                maxDate={end}
                onChange={(start) => {
                  setSelectedFilterPicker(null);
                  setStart(start);
                  setLoaded(false);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <DatePickerInput
                className="mb-0"
                label={context.t(`receipts.filter.endInputLabel`)}
                value={end}
                minDate={new Date(moment(start).toISOString())}
                onChange={(end) => {
                  setSelectedFilterPicker(null);
                  setEnd(end);
                  setLoaded(false);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <AutocompleteInput
                label={context.t(`[receipts/add].searchCustomerInputLabel`)}
                labelKey="full_name"
                valueKey="id"
                returnFullObject
                onRemove={() => {
                  setSelectedCustomer(null);
                }}
                selected={selectedCustomer || null}
                selectedHandler={(selected_customer) => {
                  setSelectedCustomer(selected_customer);
                }}
                asyncDataService={async (keyword) => getCustomers(keyword)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Select
                label={context.t(`appointments.dayWeekSelectLabel`)}
                items={filterPicker}
                labelKey="label"
                valueKey="id"
                selected={
                  selectedFilterPicker ? `${selectedFilterPicker.id}` : null
                }
                returnFullObject
                handler={(filterPicker) => {
                  setSelectedFilterPicker(filterPicker);
                }}
              />
            </Column>
          </>
        )}
      </Grid>

      <Grid>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={
                process.env.APP_NAME === "salon"
                  ? Loader
                  : process.env.APP_NAME === "en"
                    ? LoaderEN
                    : LoaderSM
              }
              width="80"
              height="80"
              alt="loading"
            />
            <>{context.t(`["packages/sale"].loaderTitle`)}</>
          </div>
        ) : (
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() => getOpenedReceipts(pagination.page)}
              headings={{
                id: {
                  label: context.t(`receipts.headings.id`),
                  style: { width: 60 },
                },
                is_package: {
                  label: context.t(`receipts.headings.type`),
                  style: { width: 150 },
                },
                created_at: {
                  label: context.t(`receipts.headings.createdAt`),
                  style: { width: 200 },
                  sortable: {
                    0: [{ field: "`packages`.`created_at`", order: "ASC" }],
                    1: [{ field: "`packages`.`created_at`", order: "DESC" }],
                  },
                  isClicked: isCreatedAtASCDESC,
                  onClick: () => {
                    setIsCreatedAtASCDESC(!isCreatedAtASCDESC)
                    setIsNameASCDESC(false)
                    setFieldId(1)
                    setOrder(order ? !order : true)
                  },
                },
                full_name: {
                  label: context.t(`receipts.headings.customerFullName`),
                  // with_photo: "customer.profile_photo",
                  style: { width: 300 },
                  limited_line: 1,
                  sortable: {
                    0: [
                      { field: "`customers`.`name`", order: "ASC" },
                      { field: "`customers`.`surname`", order: "ASC" },
                    ],
                    1: [
                      { field: "`customers`.`name`", order: "DESC" },
                      { field: "`customers`.`surname`", order: "DESC" },
                    ],
                  },
                  isClicked: isNameASCDESC,
                  onClick: () => {
                    setIsNameASCDESC(!isNameASCDESC)
                    setIsCreatedAtASCDESC(false)
                    setFieldId(2)
                    setOrder(order ? !order : true)
                  },
                },
                info: {
                  label: context.t(`receipts.headings.info`),
                  style: { width: 400 },
                  limited_line: 2,
                },
                all_amount: {
                  label: context.t(`receipts.headings.allAmount`),
                  style: { width: 100 },
                },
                paid: {
                  label: context.t(`receipts.headings.paid`),
                  style: { width: 120 },
                },
                debt_total: {
                  label: context.t(`receipts.headings.debtTotal`),
                  style: { width: 120 },
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={data}
              loaded={loaded}
              replacements={{
                is_package: {
                  true: context.t(`receipts.replacements.packageSale`),
                  false: context.t(`receipts.replacements.standartSale`),
                },
              }}
              pagination={pagination}
              buttons={[
                {
                  title: context.t(`receipts.showButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  onClick: (row) =>
                    history.push({
                      pathname: `/receipts/detail/${row.id}`,
                    }),
                },
              ]}
            />
          </Column>
        )}
      </Grid>
    </AuthContainer>
  );
};

export default PackageSalesIndex;
