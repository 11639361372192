import React, { useContext } from "react";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/tr";
import "moment/locale/en-gb";
import AppContext from "../../context/store";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const CustomMUIDatePicker = (props) => {
  const context = useContext(AppContext);
  moment.locale(context.state.default_locale);

  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          background: `${props.background} !important`,
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          justifyContent: "space-between",
        },
        iconButton: {
          background: `${props.background} !important`,
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: "999px",
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: "space-between",
        },
      },
    },
  });

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale={context.state.default_locale}
    >
      {props.expanded ? (
        <ThemeProvider theme={materialTheme}>
          <DatePicker {...props} />
        </ThemeProvider>
      ) : (
        <DatePicker {...props} />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default CustomMUIDatePicker;
