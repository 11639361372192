import React, { useEffect, useContext } from "react";
import AppRouter from "./AppRouter";
import "./assets/style/Main.scss";
import { ToastContainer, Slide } from "react-toastify";
import AppContext from "./context/store";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";

let theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif !important',
  },
  palette: {
    mode: "light",
    primary: {
      main: 
      process.env.APP_NAME === "salon"
          ? "#3189ec"
          : process.env.APP_NAME === "en"
          ? "#ffa000"
          : "#ed1e79",
    },
  },
});

const changeFavicon = (faviconUrl) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.rel = 'icon';
  link.href = faviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const App = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    const faviconUrl = 
      process.env.APP_NAME === "salon" 
        ? `${process.env.PUBLIC_URL}/favicon.ico` 
        : process.env.APP_NAME === "en" 
        ? `${process.env.PUBLIC_URL}/favicon_en.ico` 
        : `${process.env.PUBLIC_URL}/favicon_sm.ico`;

    changeFavicon(faviconUrl);

    window.onblur = () => {
      document.title = context.t(`definePopup.appTitleOnBrowser`) + context.app.NAME;
    };

    window.onfocus = () => {
      document.title = context.app.NAME;
    };

    return () => {
      changeFavicon("favicon.ico");
    };
  }, [context])

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppRouter />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          closeButton={<></>}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          pauseOnVisibilityChange={false}
          draggable={false}
          transition={Slide}
          pauseOnHover
        ></ToastContainer>
      </ThemeProvider>
    </>
  );

}

export default App;
