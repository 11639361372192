const moment = require("moment");

const CalculateLicense = (license) => {
  /**
   * Şimdiden itibaren kaç gün kaldığını gün sayısı olarak dönen fonksiyon.
   */
  const getRemainingDaysFromNow = () => Math.round(moment.duration(moment(license.end_date).diff(new Date())).asDays());


  /**
   * Lisans başlangıç ve bitişine göre lisans uzunluğunu gün sayısı olarak dönen fonksiyon.
   */
  const getLicenseDayLength = () =>  moment.duration(moment(license.end_date).diff(moment(license.created_at))).asDays();


  /**
   * Lisans başlangıcından şimdiye kadar yüzdelik olarak ne kadar kullandığını döndüren fonksiyon.
   */
  const getUsagePercent = () => ((getRemainingDaysFromNow() / getLicenseDayLength()) * 100);

  
  return {
    getRemainingDaysFromNow,
    getLicenseDayLength,
    getUsagePercent,
  }
 }

export default CalculateLicense;