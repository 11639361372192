import React from "react";
import styled from "styled-components";

const RoundedRow = ({ color, children }) => {
  return (
    <Element className={`fc-${color} bg-${color}-opacity`}>{children}</Element>
  );
};

const Element = styled.span`
  display: inline-flex;
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 8px;
  align-items: center;

  svg {
    font-size: 18px;
    margin-right: 4px;
  }

  &:nth-last-item {
    margin-right: 0;
  }
`;

export default React.memo(RoundedRow);
