import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AppContext from "../../context/store";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import FullScreenDialog from "../../theme/CustomMUI/FullScreenDialog";
import { Column, Grid } from "../../theme/Grid";
import RoundedRow from "../Table/RoundedRow";
import { Alarm, AlarmOff, AlarmOn, EventNote } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import moment from "moment";
import Table from "../../theme/Table";

const OnlineAppointmentRequestDailog = (props) => {
  const context = useContext(AppContext);

  const [delete_confirm, setDeleteConfirm] = useState(false);
  const [delete_id, setDeleteID] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: null,
    total_page: null,
    onChange: (page) => getAppointmentRequests(page),
  });
  const [customerData, setCustomerData] = useState([]);

  const [phone, setPhone] = useState(null);
  const [dialCode, setDialCode] = useState(null);
  const [addBlockListPopUp, setAddBlockListPopUp] = useState(false);

  const [confirmConflictPopUp, setConfirmConflictPopUp] = useState(false);
  const [isConfirmedConflict, setIsConfirmedConflict] = useState(false);
  const [confirmConflictId, setConfirmConflictId] = useState("");

  const getAppointmentRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    }).then(({ data }) => {
      // console.log(data)
      setLoaded(true);
      setPagination({
        ...pagination,
        page: data.data.page,
        total_page: data.data.records.length,
      });
      setCustomerData(
        [
          ...data.data.records.map((item) => ({
            ...item,
            customer_statics: {
              ...item.customer_statics,
              sumCol: (
                <>
                  <RoundedRow color="blue">
                    <EventNote />{" "}
                    {item.customer_statics.success +
                      item.customer_statics.wait +
                      item.customer_statics.fail}
                  </RoundedRow>
                  <RoundedRow color="green">
                    <AlarmOn /> {item.customer_statics.success}
                  </RoundedRow>
                  <RoundedRow color="orange">
                    <Alarm /> {item.customer_statics.wait}
                  </RoundedRow>
                  <RoundedRow color="red">
                    <AlarmOff /> {item.customer_statics.fail}
                  </RoundedRow>
                </>
              ),
            },
            appointment_start_date: moment(item.appointment_start_date)
              .local()
              .format("DD/MM/YYYY HH:mm"),
          })),
        ].filter((item) => item !== undefined)
      );
    });
  };

  const approveSingleAppointmentRequest = (id) => {
    Axios.post(`${context.api_endpoint}/company/appointment/request/approve`,
      isConfirmedConflict === false ? {
        request_id: id,
      } : {
        request_id: id,
        force: true
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['appointments/online'].approveToast`));
          getAppointmentRequests();
          setIsConfirmedConflict(false);
        }
      })
      .catch((err) => {
        if (err.response.data.status !== 409) {
          toast.error(err.response.data.message);
        } else if (err.response.data.status === 409) {
          setConfirmConflictPopUp(true);
          setIsConfirmedConflict(false);
        }
      });
  };

  const rejectSingleAppointmentRequest = (id) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['appointments/online'].rejectToast`));
          getAppointmentRequests();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const addBlacklist = async () => {
    await Axios.post(
      `${context.api_endpoint}/company/shortcode/add/blacklist`,
      {
        phone: phone ? phone : null,
        dialCode: dialCode ? dialCode : null,
      }
    )
      .then(() => { })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        setDialCode(null);
        setPhone(null);
      });
  };
  useEffect(() => {
    props.open && getAppointmentRequests();
  }, [props.open]);

  useEffect(() => {
    if (isConfirmedConflict === true) {
      approveSingleAppointmentRequest(confirmConflictId);
    }
  }, [isConfirmedConflict]);

  return (
    <FullScreenDialog
      open={props.open}
      title={context.t(`component.OARDialog.title`)}
      onClose={props.onClose}
    >
      <Grid>
        <Column className="xs-12 sm-12">
          {context.state.user.permission !== 1 && (
            <Alert className="mb-3" severity="info">
              {context.t(`['appointments/online'].alertText`)}
            </Alert>
          )}
          <Table
            refreshAction={() => getAppointmentRequests(pagination.page)}
            loaded={loaded}
            headings={{
              appointment_start_date: {
                label: context.t(
                  `['appointments/online'].headings.appointmentStartDate`
                ),
                style: { width: 150 },
              },
              customer_full_name: {
                label: context.t(
                  `['appointments/online'].headings.customerFullName`
                ),
                //with_photo: "customer.photo",
                limited_line: 3,
                style: { width: "200px" },
              },
              customer_phone: {
                label: context.t(
                  `['appointments/online'].headings.customerPhone`
                ),
              },

              "staff.full_name": {
                label: context.t(
                  `['appointments/online'].headings.staffFullName`
                ),
                with_photo: "staff.detail.profile_photo",
                limited_line: 1,
              },
              "service.name": {
                label: context.t(
                  `['appointments/online'].headings.serviceName`
                ),
                limited_line: 1,
              },
              note: {
                label: context.t(`['appointments/online'].headings.note`),
                limited_line: 1,
              },
              "customer_statics.sumCol": {
                label: context.t(`dashboard.stats.appointment.heading`),
              },
              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={customerData}
            // replacements={this.state.replacements}
            pagination={pagination}
            buttons={[
              {
                // title: this.context.t(`['appointments/online'].approveButtonTitle`),
                icon: "check",
                textColor: "green",
                transitionEffect: true,
                pushEffect: true,
                disabled: context.state.user.permission === 0,
                onClick: (row) => {
                  setConfirmConflictId(row.id);
                  approveSingleAppointmentRequest(row.id)
                },
              },
              {
                // title: this.context.t(`['appointments/online'].rejectButtonTitle`),
                icon: "close",
                textColor: "red",
                transitionEffect: true,
                pushEffect: true,
                disabled: context.state.user.permission === 0,
                onClick: (row) => {
                  setDeleteID(row.id);
                  setDeleteConfirm(true);
                },
              },
              {
                icon: "block",
                textColor: "black",
                transitionEffect: true,
                pushEffect: true,
                disabled: context.state.user.permission === 0,
                onClick: (row) => {
                  setPhone(row?.customer_phone);
                  setDialCode(row?.dialCode);
                  setAddBlockListPopUp(true);
                  setDeleteID(row.id);
                },
              },
            ]}
          />

          <AlertDialog
            title={context.t(`['appointments/online'].rejectConfirm.title`)}
            open={delete_confirm}
            closeHandler={() => setDeleteConfirm(false)}
            buttons={[
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.confirmButtonTitle`
                ),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => rejectSingleAppointmentRequest(delete_id),
              },
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.discardButtonTitle`
                ),
                icon: "close",
                textColor: "grey",
              },
            ]}
          >
            <Alert severity="warning">
              <b>
                {context.t(
                  `['appointments/online'].rejectConfirm.alertBoldText`
                )}
              </b>
              <br />
              {context.t(`['appointments/online'].rejectConfirm.alertText`)}
            </Alert>
          </AlertDialog>
          <AlertDialog
            title={
              <h5>
                {context.t(`['appointments/online'].addToNumberBlackList`)} (
                {phone})
              </h5>
            }
            open={addBlockListPopUp}
            closeHandler={() => setAddBlockListPopUp(false)}
            buttons={[
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.confirmButtonTitle`
                ),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  addBlacklist();
                  rejectSingleAppointmentRequest(delete_id);
                },
              },
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.discardButtonTitle`
                ),
                icon: "close",
                textColor: "grey",
              },
            ]}
          >
            <Alert severity="warning">
              {context.t(`['appointments/online'].blackListWarning`)}
            </Alert>
          </AlertDialog>
          <AlertDialog
            title={context.t(`['appointments/online'].conflictConfirm.title`)}
            open={confirmConflictPopUp}
            closeHandler={() => setConfirmConflictPopUp(false)}
            buttons={[
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.confirmButtonTitle`
                ),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  setIsConfirmedConflict(true);
                  setConfirmConflictPopUp(false);
                }
              },
              {
                title: context.t(
                  `['appointments/online'].rejectConfirm.discardButtonTitle`
                ),
                icon: "close",
                textColor: "grey",
                onClick: () => {
                  setIsConfirmedConflict(false);
                  setConfirmConflictPopUp(false);
                }
              },
            ]}
          >
            <Alert severity="warning">
              <b>
                {context.t(`['appointments/online'].conflictConfirm.alertBoldText`)}
              </b>
            </Alert>
          </AlertDialog>
        </Column>
      </Grid>
    </FullScreenDialog>
  );
};

export default OnlineAppointmentRequestDailog;
