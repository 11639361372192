import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button";
import TimerButton from "../../components/Buttons/TimerButton";
import { makeStyles } from "@material-ui/core/styles";

const AlertDialog = (props) => {
	const [open, setOpen] = useState(props.open ?? false);

	// Control that button is clicked.
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	useEffect(() => {
		setOpen(props.open ?? false);
		setIsButtonDisabled(false);
	}, [props.open]);

	const handleClose = () => {
		props.closeHandler();
		setOpen(false);
	};

	const useStyles = makeStyles({
		dialogPaper: {
			minHeight: "20%",
			maxHeight: "auto",
			overflow: props.overflowContent === true ? "hide" : "visible",
			backgroundColor: props.backgroundColor ? props.backgroundColor : "",
		},
	});

	return (
		<Dialog
			// style={{
			//   zIndex: 9999999,
			// }}
			open={open}
			onClose={handleClose}
			scroll={props.scroll ?? "paper"}
			disableBackdropClick={props.disableBackdropClick ?? true}
			maxWidth={props.maxWidth}
			fullWidth={props.fullWidth}
			className={props.className ? props.className : ""}
			classes={{ paper: useStyles().dialogPaper }}
			disableEscapeKeyDown={props.disableEscapeKeyDown ?? true}
		>
			{props.title ? (
				<DialogTitle id="alert-dialog-title" children={props.title} />
			) : null}
			<DialogContent style={props.containerStyle ? props.containerStyle : null}>
				{props.textType === true ? (
					<DialogContentText>
						{props.children && props.children}
					</DialogContentText>
				) : (
					props.children && props.children
				)}
			</DialogContent>
			{props.buttons && props.buttons.length > 0 ? (
				<DialogActions style={{ padding: "0px 24px 16px" }}>
					{props.buttons.map((btn, index) =>
						btn.timer ? (
							<TimerButton
								key={index}
								{...btn}
								onClick={async () => {
									if (btn.disableCloseDialog !== true) {
										await handleClose();
										if (btn.onClick !== undefined) await btn.onClick();
									}
								}}
							/>
						) : (
							<Button
								key={index}
								{...btn}
								fullWidth
								disabled={isButtonDisabled}
								onClick={async () => {
									if (btn.disableCloseDialog !== true) {
										if (!btn.disabledHandleClose) {
											await handleClose();
										}
										if (btn.onClick !== undefined) {
											setIsButtonDisabled(true);
											props.buttonDisabledTimeout &&
												setTimeout(() => {
													setIsButtonDisabled(false);
												}, 1000);
											await btn.onClick();
										}
									}
								}}
							/>
						)
					)}
				</DialogActions>
			) : null}
		</Dialog>
	);
};

export default React.memo(AlertDialog);
