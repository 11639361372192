import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid, Column } from '../theme/Grid';
import Button from '../theme/Button';
import AppContext from '../context/store'
import { withRouter } from 'react-router-dom';


const PlanUpgrade = (props) => {
  const context = useContext(AppContext);

  return (
    <Grid>
      <Column className="xs-12 sm-12">
        <ContainerUpgrade>
          <DetailImage src={require("../assets/images/manypixels/plan_upgrade.svg")} />
          <InfoCaption>{context.t(`planUpgrade.heroHeadingText`)}</InfoCaption>
          <InfoText>
            <b>
              {context.t(`planUpgrade.boldText`)}
              <br />
              {context.t(`planUpgrade.text`)}
            </b>
          </InfoText>
          <ButtonContainer>
            <Button
              className="mb-1"
              icon="shopping_cart"
              title={context.t(`planUpgrade.showPackagesButtonTitle`)}
              backgroundColor="primary-opacity"
              textColor="primary"
              onClick={() => props.history.push('/wallet')}
            />
            <Button
              className="mb-1"
              icon="call"
              title={context.t(`planUpgrade.callUsButtonTitle`)}
              backgroundColor="green-opacity"
              textColor="green"
              onClick={() => window.open('tel:02127060510', 'blank')}
            />
          </ButtonContainer>
        </ContainerUpgrade>
      </Column>
    </Grid>
  )
}

export default withRouter(PlanUpgrade);


const ContainerUpgrade = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const DetailImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 32px;
`;

const InfoCaption = styled.h2`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
`;

const InfoText = styled.p`
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  margin: 0 0 16px;

  b {
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
@media only screen and (min-width: 992px){
  display: flex;
  button{margin-right: 8px; &:nth-last-child(){margin-right: 0}}
}
`;
