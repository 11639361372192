/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Button from "../../../theme/Button";

import Input from "../../../theme/CustomMUI/Input";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";

import moment from "moment";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import { getCities } from "../../../functions/CityDistrict";
import getDayIndex from "../../../functions/GetDayIndex";
import { toast } from "react-toastify";
import {
  Grid,
  Container,
  CircularProgress,
  ButtonBase,
  TextField,
} from "@material-ui/core";
import { Column } from "../../../theme/Grid";
import Pagination from "@material-ui/lab/Pagination";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  StorefrontOutlined,
  AccountCircleOutlined,
  Clear,
  Info,
} from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import LanguageButton from "../../../components/Buttons/LanguageButton";
import TranslateView, {
  LanguagesContainer,
} from "../../../components/Scaffold/TranslateView";

import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import ReCaptcha from "../../../components/ReCaptcha";
import AreaCode from "./AreaCode";

class BusinessWebsite extends Component {
  static contextType = AppContext;

  state = {
    TAB_TYPE: 0,
    loaded: false,

    instagram: { loaded: false, photos: [] },
    comments: { records: [] },
    business: {
      id: null,
      name: null,
      mail: null,
      phone: null,
      province: null,
      district: null,
      address: null,
      map: null,
      shift: {
        start_hour: "00:00",
        end_hour: "23:59",
        slice: 20,
      },
      staffs: [],
      services: [],
      show_amount_on_appointment: null,
      show_customer_comments_at_url: null,
    },

    //İstek atarken kullanılacak şablon
    appointment: {
      customer_name: null,
      customer_surname: null,
      customer_phone: null,
      dialCode: null,
      staff_id: null,
      service_id: null,
      date: moment(new Date()).format("DD/MM/YYYY"),
      time: null,
      note: "",
    },
    appointment_check_modal: false,

    // Yeni saat sistemi için data
    available_data: {},
    approvement_code_model: false,
    approvement_code: null,
    current_hour: "",

    code: "",
    is_code_wrong: false,

    // show more or show less
    showMoreList: [],

    select_language: false,
    lang: this.context.state.default_locale,
    reCaptchaCode: null,
    confirmModal: false,
    getCodeCheck: false,
  };

  translateServiceDescription = async (text, targetLanguage) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    if (text === '' || text === null) {
      return text
    } else {
      const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          q: text,
          target: targetLanguage,
        }),
      });

      if (!response.ok) {
        throw new Error('Çeviri isteği başarısız oldu');
      }

      const data = await response.json();
      return data.data.translations[0].translatedText;
    }
  };

  getCode = () => {
    Axios.post(
      `${this.context.api_endpoint.slice(
        0,
        34
      )}/api/platform/appointment/request`,
      {
        company_id: this.state.business.id,
        phone: this.state.appointment.customer_phone.slice(
          this.state.appointment.dialCode.length
        ),
        dialCode: "+" + this.state.appointment.dialCode,
      }
    )
      .then((response) => {
        if (response.data.status === 201) {
          toast.success(response.data.data);
          this.setState({
            //approvement_code_model: true,
            getCodeCheck: true,
          });
        }
      })
      .catch((err) => {
        toast.error("Somenting went wrong!");
        console.log(err);
      });
  };

  getBusiness = async () => {
    const { params } = this.props.match;
    try {
      const response = await Axios.get(`${this.context.api_endpoint}/request/index/${params.id}`);
      if (response.status === 201) {
        const { data } = response;
        let translatedServices = await Promise.all(data?.data?.services?.map(async (service) => {
          const translatedServiceName = await this.translateServiceDescription(service?.name ?? '', this.state?.lang ?? 'en');
          const translatedServiceDescription = await this.translateServiceDescription(service?.description, this.state?.lang);
          return {
            ...service,
            name: translatedServiceName,
            description: translatedServiceDescription,
            staffs: [
              ...service?.staffs
                ?.map((staff) =>
                  staff.staff !== null ? { ...staff.staff } : undefined
                )
                ?.filter((item) => item !== undefined),
            ],
          };
        }));

        this.setState({
          loaded: true,
          business: {
            ...this.state?.business,
            ...data?.data,
            instagram:
              data?.data?.instagram !== "" && data?.data?.instagram !== null
                ? data?.data?.instagram
                : this.context.app.INSTAGRAM,
            show_amount_on_appointment:
              data?.data?.company_settings?.show_amount_platform === 1
                ? true
                : false,
            province: data?.data?.province
              ? getCities()?.filter(
                (city) => city.id === data?.data?.province
              )[0]?.name
              : false,
            shift: data?.data?.hours,
            staffs: data?.data?.staffs ? [...data?.data?.staffs] : [],
            services: translatedServices,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getInstagramPosts = () => {
    const {
      business: { instagram },
    } = this.state;
    fetch(
      `https://www.instagram.com/${instagram ? instagram : this.context.app.INSTAGRAM
      }/?__a=1`
    )
      .then((res) => res.json())
      .then((response) => {
        const { edge_owner_to_timeline_media } = response.graphql.user;

        // edge_owner_to_timeline_media.edges.slice(0,6).map((post, index) => {
        this.setState({
          instagram: {
            loaded: true,
            data: [...edge_owner_to_timeline_media.edges],
          },
        });
      });
  };

  componentDidMount() {
    this.getBusiness();
  }

  render() {
    const Loader = this._LoaderBuilder;
    const BusinessHeader = this._BusinessHeaderBuilder;
    const GalleryCard = this._GalleryCardBuilder;
    const TabNavigator = this._TabNavigatorBuilder;

    const InstagramCard = this._InstagramCardBuilder;
    const FacebookCard = this._FacebookCardBuilder;
    const AboutCard = this._AboutCardBuilder;

    const { business, loaded } = this.state;

    return (
      <SiteContainer>
        <Helmet>
          <title>
            {this.context.app.NAME}{" "}
            {business.name !== null ? `- ${business.name}` : ""}
          </title>
        </Helmet>

        {loaded ? (
          <div className="animated fadeIn">
            <Container>
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                  <BusinessHeader />
                  {/* <GalleryCard /> */}
                  <TabNavigator />

                  {/*<LanguagesContainer className="mt-3">
                    <LanguageButton heading="Türkçe" lang="tr" />
                    <LanguageButton heading="English" lang="en" />
                  </LanguagesContainer>*/}
                </Grid>

                <Grid item xs={12} md={4}>
                  <InstagramCard />
                  <FacebookCard />
                  <AboutCard />
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          <Loader />
        )}
      </SiteContainer>
    );
  }

  /**
   * İstekten cevap gelene kadar olan süreçte ilgili alanda loader gösteren builder.
   */
  _LoaderBuilder = () => {
    return (
      <LoaderContainer>
        <CircularProgress variant="indeterminate" color="primary" />
      </LoaderContainer>
    );
  };

  /**
   * İşletme bilgisi altında tab navigator oluşturan ve
   * ilgili tab'in component'ini sayfaya dahil eden builderfonksiyonudur..
   */
  _TabNavigatorBuilder = () => {
    const { business, select_language } = this.state;

    const Tab = this._NavigatorSingleTabBuilder;

    // Components
    const AppointmentCard = this._AppointmentCardBuilder;
    const ServicesCard = this._ServicesCardBuilder;
    const StaffsCard = this._StaffsCardBuilder;
    const CommentsCard = this._CommentsCardBuilder;

    // Navigator Settings
    const TabNavigator = [
      {
        title: this.context.t(`businessURL.appointment.tab`),
        component: <AppointmentCard />,
      },
      {
        title: this.context.t(`businessURL.services.tab`),
        component: <ServicesCard />,
      },
      {
        title: this.context.t(`businessURL.staffs.tab`),
        component: <StaffsCard />,
      },
      {
        title: this.context.t(`businessURL.comments.tab`),
        component: <CommentsCard />,
      },
    ];

    return (
      <Column>
        <TNavigator className="mt-3">
          {TabNavigator.filter((_tab) => _tab !== undefined).map(
            (_tab, index) => (
              <Tab
                key={index}
                title={_tab.title}
                control={this.state.TAB_TYPE === index ? "active" : ""}
                onClick={() => this.setState({ TAB_TYPE: index })}
              />
            )
          )}
          <ResponsiveDiv>
            <LanguageSelective
              id="languageBox"
              onClick={() => {
                this.setState({ select_language: !this.state.select_language });
              }}
            >
              <Flag
                src={require(`../../../assets/images/flags/${this.state.lang}.svg`)}
              />
              {this.state.lang === "tr" && "Türkçe"}
              {this.state.lang === "en" && "English"}
              {this.state.lang === "ru" && "Russia"}
              {this.state.lang === "ar" && "Arabic"}
            </LanguageSelective>
            <TranslateView
              state={select_language}
              closeHandler={() => {
                this.setState({
                  select_language: false,
                });
              }}
              stopPropagation={(e) => e.stopPropagation()}
            />
          </ResponsiveDiv>
        </TNavigator>

        {TabNavigator[this.state.TAB_TYPE].component}
      </Column>
    );
  };

  /**
   * _TabNavigatorBuilder içerisinde kullanılacak olan tekli tab buttonun builder fonksiyonudur.
   */
  _NavigatorSingleTabBuilder = ({ title, control, onClick }) => {
    return (
      <TNavigatorTab>
        <ButtonBase className={control} onClick={onClick}>
          {title}
        </ButtonBase>
      </TNavigatorTab>
    );
  };

  /**
   * İşletme adı, il/ilçe ve Hemen Ara buttonlarının bulunduğu kart görünümünün builder fonksiyonudur.
   */
  _BusinessHeaderBuilder = () => {
    const { business } = this.state;
    return (
      <Card>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <BusinessHeading>
              <h1 className="limited-line-2">{business.name}</h1>
              {business.province && (
                <span>
                  {business.province} / {business.district}
                </span>
              )}
            </BusinessHeading>
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: "flex" }}>
            <Button
              icon="call"
              title={this.context.t(`businessURL.callButtonTitle`, {
                phone: business.phone,
              })}
              textColor="green"
              backgroundColor="green-opacity"
              size="md"
              fullWidth
              onClick={() => window.open(`tel:0${business.phone}`)}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  /**
   * İşletme fotoğrafları -varsa- galeri alanını oluşturan alandır.
   */
  _GalleryCardBuilder = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const { params } = this.props.match;
    const { business } = this.state;

    const images =
      business.gallery && business.gallery.length > 0
        ? business.gallery.map(
          ({ photo }, index) =>
            `${this.context.api_endpoint.replace("api/v1", "")}${params.id}/${photo.path
            }`
        )
        : [];

    return business.gallery && business.gallery.length > 0 ? (
      <Card className="mt-3">
        <LeadingPhoto
          src={`${this.context.api_endpoint.replace("api/v1", "")}${params.id
            }/${business.gallery[0].photo.path}`}
          onClick={async () => {
            await setPhotoIndex(0);
            await setModalOpen(true);
          }}
        />
        <Grid container spacing={2}>
          {business.gallery.length <= 4
            ? business.gallery.map(({ photo }, index) => (
              <Grid item key={index} xs={3} sm={3}>
                <SmallPhoto
                  src={`${this.context.api_endpoint.replace("api/v1", "")}${params.id
                    }/${photo.path}`}
                  onClick={async () => {
                    await setPhotoIndex(index);
                    await setModalOpen(true);
                  }}
                />
              </Grid>
            ))
            : business.gallery.slice(0, 4).map(({ photo }, index) => (
              <Grid item key={index} xs={3} sm={3}>
                <SmallPhoto
                  src={`${this.context.api_endpoint.replace("api/v1", "")}${params.id
                    }/${photo.path}`}
                  onClick={async () => {
                    await setPhotoIndex(index);
                    await setModalOpen(true);
                  }}
                />
              </Grid>
            ))}
        </Grid>

        {modalOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setModalOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Card>
    ) : null;
  };

  /**
   * Randevu talebi oluşturma görünümü ve attığı isteklerin bulunduğu builder fonksiyonudur.
   */
  _AppointmentCardBuilder = () => {
    const {
      business,
      appointment,
      appointment_check_modal,
      hours_expanded,
      selected_service,
      current_hour,
      __service,
    } = this.state;

    const getUnavailableHours = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const date = new Date(
        `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T00:00:00`
      );

      Axios.get(
        `${this.context.api_endpoint}/request/${params.id}/staff/active`,
        {
          params: {
            staff_id: appointment.staff_id,
            day_index: getDayIndex(moment(date).format("YYYY-MM-DD")),
            date: moment(date).format("YYYY-MM-DD"),
            process_time: selected_service.process_time,
          },
        }
      ).then(({ data }) => {
        if (data.data.free_hours === null) {
          this.setState({
            hours_expanded: true,
            available_data: { ...data.data, free_hours: [] },
            current_hour: data.data.current_date,
          });
        } else {
          this.setState({
            hours_expanded: true,
            available_data: data.data,
            current_hour: data.data.current_date,
          });
        }
      });
    };
    const phoneNumberHandler = (value, data, event, formattedValue) => {
      this.setState({
        appointment: {
          ...appointment,
          customer_phone: value,
          dialCode: data.dialCode,
        },
      });
    };
    React.useEffect(() => {
      if (this.state.getCodeCheck) {
        setTimeout(() => {
          makeAppointmentRequest();
        }, 500);
      }
    }, [this.state.getCodeCheck]);
    const makeAppointmentRequest = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const time_arr = appointment.time.split(":");

      Axios.post(
        `${this.context.api_endpoint.slice(
          0,
          34
        )}/api/platform/appointment/request/approve`,
        {
          dialCode: appointment.dialCode ? "+" + appointment.dialCode : "",
          company_id: this.state.business.id,
          customer_name: appointment.customer_name,
          customer_phone: appointment.customer_phone
            ? appointment.customer_phone.slice(appointment.dialCode.length)
            : "",
          customer_surname: appointment.customer_surname,
          date: appointment.time,
          email: "",
          note: appointment.note,
          zone: 0,
          service_id: appointment.service_id,
          staff_id: appointment.staff_id,
        }
      )
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              this.context.t(`businessURL.appointment.insertToast`)
            );
            this.setState({
              is_code_wrong: false,
              approvement_code_model: false,
              hours_expanded: false,
              appointment: {
                customer_name: null,
                customer_surname: null,
                customer_phone: null,
                company_id: params.id,
                staff_id: null,
                service_id: null,
                date: moment(new Date()).format("DD/MM/YYYY"),
                time: null,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.status);
          this.setState({
            is_code_wrong: true,
          });
          toast.warning(this.context.t(`businessURL.wrong_code`));
        });
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.appointment.title`)}</h2>
        </SectionHeading>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={this.context.t(`businessURL.appointment.nameInputLabel`)}
              value={
                appointment.customer_name !== null
                  ? appointment.customer_name
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  appointment: {
                    ...appointment,
                    customer_name: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={this.context.t(
                `businessURL.appointment.surnameInputLabel`
              )}
              value={
                appointment.customer_surname !== null
                  ? appointment.customer_surname
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  appointment: {
                    ...appointment,
                    customer_surname: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PhoneInput
              inputStyle={{
                width: "100%", // Set a default width
              }}
              country={AreaCode?.find((el) => el?.areaCode === this.context.state.dialCode)?.country ?? 'tr'}
              countryCodeEditable={false}
              value={
                this.state.appointment.customer_phone !== null
                  ? this.state.appointment.customer_phone
                  : ""
              }
              onChange={phoneNumberHandler}
              specialLabel={this.context.t(
                `businessURL.appointment.phoneInputLabel`
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <AutocompleteInput
              label={this.context.t(
                `businessURL.appointment.serviceInputLabel`
              )}
              placeholder={this.context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              helperText={this.context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              selected={selected_service || null}
              labelKey="name"
              valueKey="id"
              returnFullObject
              options={[
                ...business.services.map((item) => ({
                  ...item,
                  name: `${item.name} ${business.show_amount_on_appointment
                    ? `(${item.amount.toFixed(2)}${this.context.state.currency
                    })`
                    : ""
                    }`,
                })),
              ]}
              onRemove={() => {
                this.setState({
                  hours_expanded: false,
                  selected_service: null,
                  appointment: {
                    ...appointment,
                    __service: null,
                    service_id: null,
                    staff_id: null,
                  },
                });
              }}
              selectedHandler={(service) =>
                this.setState({
                  hours_expanded: false,
                  selected_service: service,
                  appointment: {
                    ...appointment,
                    __service: service,
                    service_id: service.id,
                    staff_id: null,
                  },
                })
              }
              asyncDataService={(keyword) => {
                const data = [
                  ...business.services.map((item) => ({
                    ...item,
                    name: `${item.name} ${business.show_amount_on_appointment
                      ? `(${item.amount.toFixed(2)}${this.context.state.currency
                      })`
                      : ""
                      }`,
                  })),
                ];

                return keyword
                  ? data.filter((item) =>
                    item.name
                      .toLocaleLowerCase()
                      .includes(keyword.toLocaleLowerCase())
                  )
                  : data;
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Select
              required
              label={this.context.t(`businessURL.appointment.staffInputLabel`)}
              helperText={this.context.t(
                `businessURL.appointment.staffInputHint`
              )}
              items={selected_service ? selected_service.staffs : []}
              selected={
                appointment.staff_id !== null ? appointment.staff_id : ""
              }
              labelKey="full_name"
              valueKey="id"
              returnFullObject
              handler={(selected_employee) =>
                this.setState({
                  hours_expanded: false,
                  selected_employee,
                  appointment: {
                    ...appointment,
                    staff_id: selected_employee.id,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <DatePickerInput
              required
              label={this.context.t(`businessURL.appointment.dateInputLabel`)}
              minDate={new Date()}
              // maxDate={new Date().setDate(new Date().getDate() + 30)}
              helperText={this.context.t(
                `businessURL.appointment.dateInputHint`
              )}
              onChange={(date) =>
                this.setState({
                  hours_expanded: false,
                  appointment: {
                    ...appointment,
                    date: moment(date).format("DD/MM/YYYY"),
                  },
                })
              }
            />
          </Grid>
        </Grid>

        <Button
          disabled={
            business.shift.slice === "0" ||
            appointment.customer_name === null ||
            appointment.customer_last_name === null ||
            appointment.customer_phone === null ||
            appointment.staff_id === null ||
            appointment.staff_id === null ||
            appointment.service_id === null
          }
          icon="search"
          title={this.context.t(
            `businessURL.appointment.availableHoursButtonTitle`
          )}
          backgroundColor="primary"
          textColor="white"
          fullWidth={true}
          onClick={() => getUnavailableHours()}
        />

        {hours_expanded === true && this.state.reCaptchaCode !== null && (
          <Grid container spacing={2} className="mt-4">
            {this.state.available_data.free_hours
              .filter((item) => moment(item) >= moment(this.state.current_hour))
              .map((item, index) => {
                // const time_arr = item.split("T")[0];
                const date_arr = `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                  }`;

                return (
                  <Grid date_arr key={index} xs={6} sm={6} md={3} lg={2}>
                    <Button
                      title={date_arr}
                      fullWidth={true}
                      textColor={"green"}
                      onClick={() => {
                        this.setState({
                          confirmModal: true,
                          appointment: {
                            ...appointment,
                            time: item,
                          },
                        });
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
        {hours_expanded === true &&
          this.state.reCaptchaCode !== null &&
          this.state.available_data.free_hours?.filter(
            (item) => moment(item) >= moment(this.state.current_hour)
          ).length === 0 && (
            <Grid>*Seçili tarihte uygun randevu saati bulunamadı.</Grid>
          )}

        {hours_expanded === true && this.state.reCaptchaCode === null && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ReCaptcha
              reCaptchaCodeHandler={(code) => {
                this.setState({
                  reCaptchaCode: code,
                });
              }}
            />
          </div>
        )}

        <AlertDialog
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Randevu Onayı</h3>
              <Clear />
            </div>
          }
          open={this.state.approvement_code_model}
          closeHandler={() => this.setState({ approvement_code_model: false })}
        >
          <Alert severity="warning">
            <b>
              Randevunuzun onaylanması için telefonunuza gelen 4 haneli onay
              kodunu giriniz.
            </b>
          </Alert>
          <form>
            <TextField
              className="mt-2 mb-2"
              fullWidth
              type="tel"
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontSize: 28,
                  fontWeight: "400",
                },
                maxLength: 4,
              }}
              value={this.state.code}
              onChange={(e) => {
                this.setState({
                  code: e.target.value,
                });
              }}
            />
            {this.state.is_code_wrong && (
              <div
                className="mt-1 mb-1"
                style={{ fontSize: "22", fontWeight: "500", color: "red" }}
              >
                Geçersiz Kod
              </div>
            )}
            <Button
              disabled={this.state.code.length < 4}
              icon={"check"}
              title={"Onayla"}
              fullWidth
              backgroundColor={"primary-opacity"}
              onClick={() => {
                //makeAppointmentRequest();
              }}
            />
            <div
              className="mt-1 mb-3"
              style={{
                display: "flex",
                textAlign: "center",
                cursor: "pointer",
                color: "orange",
                marginLeft: "auto",
                width: "fit-content",
              }}
              onClick={() => this.getCode()}
            >
              <Info />
              Tekrar onay kodu gönder
            </div>
          </form>
        </AlertDialog>
        {/* Confirm appointment */}
        <ModalDialog
          open={this.state.confirmModal}
          closeHandler={() => this.setState({ confirmModal: false })}
          title="Do you confirm this appointment ?"
          buttons={[
            {
              title: this.context.t(
                `component.CADialog.delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => this.getCode(), // makeAppointmentRequest(),
            },
            {
              title: this.context.t(
                `component.CADialog.delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            {/*    <b>{this.context.t(`component.CADialog.confirmCustomerTitle`)}</b>
            {this.context.t(`component.CADialog.confirmTitle1`)}{" "}
            <b>{this.context.t(`component.CADialog.confirmTitle2`)}</b>{" "}
            {this.context.t(`component.CADialog.confirmTitle3`)}  */}
            <b>
              If you confirm this appointment, a notification will be sent to
              the business where you made the appointment.
            </b>
          </Alert>
        </ModalDialog>
      </Card>
    );
  };

  /**
   * İşletmenin sunduğu tüm hizmetlerin listelendiği builder fonksiyonudur.
   */
  _ServicesCardBuilder = () => {
    const { business } = this.state;
    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.services.title`)}</h2>
        </SectionHeading>

        {[...business.services].map((service, index) => (
          <SingleRowItem key={index}>
            <Grid container>
              <Grid item xs={12} sm={8}>
                <span id="heading">{service.name}</span>
                <span id="subtext">
                  <p>
                    {this.context.t(`businessURL.services.processTime`, {
                      time: service.process_time,
                    })}
                  </p>
                  {business.show_amount_on_appointment && (
                    <p>
                      {service.amount.toFixed(2)}
                      {this.context.state.currency}
                    </p>
                  )}
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}
      </Card>
    );
  };

  /**
   * İşletmenin sunduğu tüm hizmetlerin listelendiği builder fonksiyonudur.
   */
  _StaffsCardBuilder = () => {
    const { business } = this.state;
    const { params } = this.props.match;

    React.useEffect(() => getStaffs(), []);

    const getStaffs = () => {
      Axios.get(
        `${this.context.api_endpoint}/request/index/${params.id}/staffs`
      ).then(({ data }) =>
        this.setState({ business: { ...business, staffs: [...data.data] } })
      );
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.staffs.title`)}</h2>
        </SectionHeading>

        {[...business.staffs].map((staff, index) => (
          <SingleRowItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm="auto">
                <StaffPhoto
                  src={
                    staff.detail.profile_photo
                      ? `${this.context.api_endpoint.replace("api/v1", "")}${params.id
                      }/${staff.detail.profile_photo}`
                      : require("../../../assets/images/profile_photo.svg")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <span id="heading" className="limited-line-1">
                  {staff.full_name}
                </span>
                <span id="subtext">
                  <p>{staff.detail.job_title}</p>
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}
      </Card>
    );
  };

  /**
   * İşletmeye gelen yorumların listelendiği builder fonksiyonudur.
   */
  _CommentsCardBuilder = () => {
    const { comments, showMoreList } = this.state;
    const { params } = this.props.match;

    React.useEffect(() => getComments(), []);

    const getComments = (page = 1) => {
      Axios.get(
        `${this.context.api_endpoint}/request/index/${params.id}/comments`,
        { params: { page } }
      ).then(({ data }) => this.setState({ comments: { ...data.data } }));
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.comments.title`)}</h2>
        </SectionHeading>

        {[...comments.records].map((comment, index) => (
          <SingleRowItem key={index}>
            <Grid container>
              <Grid item xs={12} sm={11}>
                <span
                  id="heading"
                  className={
                    showMoreList.includes(index) ? "" : "limited-line-2"
                  }
                >
                  {comment.description !== "" && comment.description !== null
                    ? comment.description
                    : "-"}
                </span>
                <span
                  style={{ cursor: "pointer", display: "block" }}
                  onClick={() => {
                    if (showMoreList.includes(index)) {
                      this.setState({
                        showMoreList: this.state.showMoreList.filter(
                          (item) => item !== index
                        ),
                      });
                    } else {
                      this.setState({
                        showMoreList: [...this.state.showMoreList, index],
                      });
                    }
                  }}
                >
                  {showMoreList.includes(index)
                    ? this.context.t(`businessURL.showLess`)
                    : this.context.t(`businessURL.showMore`)}
                </span>
                <span id="subtext">
                  <p>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StorefrontOutlined style={{ height: 20 }} />
                      <Rating
                        readOnly
                        size="small"
                        style={{ display: "flex" }}
                        precision={0.5}
                        max={5}
                        value={comment.company_star.toFixed(2)}
                      />
                      <span style={{ marginLeft: "6px" }}>
                        {comment.company_star.toFixed(2)}
                      </span>
                    </span>
                  </p>

                  <p>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountCircleOutlined style={{ height: 20 }} />
                      <Rating
                        readOnly
                        size="small"
                        style={{ display: "flex" }}
                        precision={0.5}
                        max={5}
                        value={comment.staff_star.toFixed(2)}
                      />
                      <span style={{ marginLeft: "6px" }}>
                        {comment.staff_star.toFixed(2)}
                      </span>
                    </span>
                  </p>
                </span>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}

        <PaginationContainer>
          <Pagination
            count={comments.total_page}
            onChange={(_, value) =>
              comments.page !== value && getComments(value)
            }
          />
        </PaginationContainer>
      </Card>
    );
  };

  /**
   * Sidebar alanda işletmenin varsa Instagram -yoksa @salonrandevu- hesabının son fotoğraflarının listeletildiği
   * builder fonksiyonudur.
   */
  _InstagramCardBuilder = () => {
    const { instagram, business } = this.state;
    return (
      <Card>
        <SectionHeading>
          <h2>{this.context.t(`businessURL.instagramHeading`)}</h2>
          <SocialProfileLink
            href={`https://instagram.com/${business.instagram}`}
            target="_blank"
          >
            <span>
              {this.context.t(`businessURL.instagramText`, {
                username: business.instagram ?? "Enrandevu",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>

        {/* <Grid container spacing={2}>
          {
            instagram.loaded
              ? instagram.data.map((post, index) => {
                const { shortcode, thumbnail_resources } = post.node;
                return (
                  <Grid key={index} item xs={12} sm={4}>
                    <PhotoLink href={`https://www.instagram.com/p/${shortcode}`} target="_blank">
                      <img src={thumbnail_resources[1].src} alt={business.name} />
                    </PhotoLink>
                  </Grid>
                )
              })
              : [...new Array(12)].map((_, index) =>
                <Grid key={index} item xs={12} sm={4}>
                  <InstagramPhotoSkeleton className="skeleton" />
                </Grid>
              )
          }
        </Grid> */}
      </Card>
    );
  };

  _FacebookCardBuilder = () => {
    const { business } = this.state;

    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.facebookHeading`)}</h2>
          <SocialProfileLink
            href={`https://facebook.com/${business.facebook ? business.facebook : "Enrandevu"
              }`}
            target="_blank"
          >
            <span>
              {this.context.t(`businessURL.facebookText`, {
                username: business.facebook ? business.facebook : "Enrandevu",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>
        <iframe
          style={{ width: "100%", minHeight: 181, border: 0 }}
          src={`https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/${business.facebook ?? "salonrandevucom"
            }/&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media`}
        ></iframe>
      </Card>
    );
  };

  /**
   * Sidebar alanda işletmenin varsa Instagram -yoksa @salonrandevu- hesabının son fotoğraflarının listeletildiği
   * builder fonksiyonudur.
   */
  _AboutCardBuilder = () => {
    const { business } = this.state;
    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.aboutHeading`)}</h2>
        </SectionHeading>
        <Grid container spacing={2}>
          {business.map !== null && business.map !== "" && (
            <Grid item xs={12} sm={12}>
              <iframe
                title={Date.now()}
                src={`https://maps.google.com/maps?q=@${business.map}&zoom=14&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                width="100%"
                height="240"
                frameBorder={0}
                style={{ border: 0 }}
                aria-hidden="false"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <div className="mb-1">
              <b>{this.context.t(`businessURL.phoneText`)}:</b> 0
              {business.phone ?? "-"}
            </div>
            <div className="mb-1">
              <b>{this.context.t(`businessURL.mailText`)}:</b>{" "}
              {business.mail ?? "-"}
            </div>
            <div className="mb-1">
              <b>{this.context.t(`businessURL.addressText`)}:</b>{" "}
              {business.address ?? "-"}
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  };
}

export default BusinessWebsite;

const SiteContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0;
  box-sizing: border-box;
  background: #fcfcfc;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TNavigator = styled.ul`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  background: #fff;
  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column; /* Change to column layout on smaller screens */
    border-radius: 0; /* Remove border radius on smaller screens */
    /* Add any additional styles for smaller screens */
  }
`;
const ResponsiveDiv = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; /* Make sure it doesn't exceed the screen width */
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    /* Adjust styles for smaller screens */
    width: 100%; /* Set width to 100% on smaller screens */
    max-width: none; /* Remove max-width on smaller screens if needed */
  }
`;
const TNavigatorTab = styled.li`
  width: auto;
  flex: none;
  text-align: center;

  button {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 16px;
    line-height: 26px;
    color: #303030;

    &.active {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }
`;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02);
`;

const BusinessHeading = styled.div`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 22px;
    line-height: 24px;

    & + span {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: #aaa;
      margin-top: 4px;
    }
  }
`;

const LeadingPhoto = styled.img`
  width: 100%;
  height: 300px;
  object-fit: fill;
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const SmallPhoto = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionHeading = styled.h2`
  margin: 0 0 16px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;

const InstagramPhotoSkeleton = styled.div`
  width: 100%;
  height: 105px;
  border-radius: 10px;
`;

const PhotoLink = styled.a`
  img {
    width: 100%;
    height: 105px;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const SocialProfileLink = styled.a`
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  color: #7713b5;
  text-decoration: none;

  i {
    font-size: 15px;
  }
  b {
    font-weight: 600;
  }
`;

const SingleRowItem = styled.div`
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  color: #303030;
  border-bottom: 1px solid #efefef;

  &:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &:nth-of-type(1) {
    padding-top: 0 !important;
  }

  span#heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }

  span#subtext {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    margin-top: 0px;

    p {
      font-size: 13px;
      line-height: 1.614;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-right: 16px;

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
`;

const StaffPhoto = styled.img`
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 10px;
  background: #fafafa;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LanguageSelective = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`;

const Flag = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 8px;

  & + p {
    font-size: 12px !important;
    color: #303030;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;
