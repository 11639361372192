import Axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Alert from "@material-ui/lab/Alert";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import moment from "moment";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Blacklist = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const [isOrderASCDESC, setIsOrderASCDESC] = useState(false); // true -> asc | false -> desc
  const [searchNumber, setSearchNumber] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);

  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => searchNumber ? getBlacklist(searchNumber, page) : getBlacklist("", page),
  });
  const [blacklistNumbers, setBlacklistNumbers] = useState([]);

  const [newTemplate, setNewTemplate] = useState({
    id: null,
    title: null,
    content: "",
  });

  const [deleteId, setDeleteId] = useState(null);

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] =
    useState(false);

  const [sumBlacklistCount, setSumBlacklistCount] = useState(5);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [blockNumbers, setBlockNumbers] = useState("");
  const [dialCode, setDialCode] = useState(null);


  // GET Users that or on the blacklist
  const getBlacklist = (key, page = 1) => {
    Axios.get(
      `${context.api_endpoint}/company/shortcode/list/blacklist?page=${page}${isOrderASCDESC && isOrderASCDESC ? "&order=1" : "&order=0"}${searchNumber && searchNumber > 3 ? "&number=" + searchNumber : ""}`
    ).then(({ data }) => {
      setLoaded(true);
      setPagination({
        ...pagination,
        total_page: data.data.records.length,
        page: data.data.page,
      });
      setBlacklistNumbers([
        ...data.data.records.map((item) => {
          return {
            ...item,
            created_at: moment(item.created_at).local().format("LLL"),
            phone:
              context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                ? phoneNumberDisplay(item.phone)
                : item.phone,
          };
        }),
      ]);
      setSumBlacklistCount(blacklistNumbers.length);
      // setState(prevState => {
      //   const team = [...prevState.blacklistNumbers];
      //   team[0] = { ...team[0], created_at: "2" };
      //   return { team };
      // });
    });
  };

  //  Add new Block Numbers
  /* addNewBlockNumbers = (e) => {
    e.preventDefault();
    const errors = [];

    const numbers = state.blockNumbers.split(",");
    numbers.forEach((number) => {
      const numberInt = parseInt(number);
      // console.log(numberInt);
      // Validation that number is converted and is not longer
      if (numberInt === NaN || number.length !== 10) {
        // console.log(number.length);
        errors.push(1);
      }
    });

    // If There are no Errors add Numbers to the Block List
    if (errors.length === 0) {
      numbers.forEach((number) => {
        addBlacklist(number);
      });
    } else {
      toast.warning(context.t(`['sms/blacklist'].addToBlacklistError`));
    }
  }; */
  // POST / ADD User to the blacklist
  const addBlacklist = () => {
    Axios.post(`${context.api_endpoint}/company/shortcode/add/blacklist`, {
      phone: blockNumbers.slice(dialCode.length),
      dialCode: "+" + dialCode,
      // created_at: state.newTemplate.content,
    })
      .then(({ data }) => {
        setBlockNumbers("");
        setOpenTemplateDialog(false);
        toast.success(context.t(`['sms/blacklist'].addSuccessToast`));
        getBlacklist();
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(e.response.data.data);
        } else {
          toast.warning(e.response.data.data);
        }
      });
  };

  // DELETE / REMOVE User from the blacklist
  const deleteBlacklist = (id) => {
    // console.log(id);
    Axios.post(`${context.api_endpoint}/company/shortcode/delete/blacklist`, {
      id: id,
    })
      .then(({ data }) => {
        setDeleteId(null);
        setOpenDeleteTemplateDialog(false);
        toast.success(context.t(`['sms/template'].deleteSuccessToast`));
        getBlacklist();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  const Phone_handleOnChange = (value, data, event, formattedValue) => {
    setBlockNumbers(value);
    setDialCode(data.dialCode);
    /*    setState({
         customer: {
           ...state.customer,
           secondary_phone: value,
           s_dialCode: data.dialCode,
         
         },
       }); */
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      pagination.page !== null && action === "POP" && getBlacklist();
    });
    searchNumber ? getBlacklist(searchNumber, pagination.page) : getBlacklist("", pagination.page);
    return () => {
      unlisten();
    };
  }, [isOrderASCDESC, pagination.page]);

  useEffect(() => {
    searchNumber
      ?
      setTypingTimeout(
        setTimeout(() => {
          getBlacklist(searchNumber, 1);
        }, 500)
      )
      : getBlacklist();
  }, [searchNumber]);

  return (
    <AuthContainer authorities={[1]} limited_permission="sms">
      <Grid className="mb-2">
        {/* Input to add Number to the blacklist */}
        <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
          <PhoneInput
            country={context.default_locale ? context.default_locale : "tr"}
            onChange={Phone_handleOnChange}
            specialLabel={context.t(`['sms/blacklist'].numberInputLabel`)}
          />
        </Column>
        <Column className="xs-12 sm-12 md-4 lg-auto">
          <Button
            icon="add"
            title={context.t(`['sms/blacklist'].addToBlacklist`)}
            className="inputBtn mt-2"
            backgroundColor="black"
            textColor="white"
            //   fullWidth={true}
            onClick={() => addBlacklist()}
          />
        </Column>

        <Column className="xs-12 sm-12 md-4 lg-auto inputBtn2">
          <PhoneInput
            onChange={(e) => {
              const newValue = e;
              if (typingTimeout) clearTimeout(typingTimeout);
              console.log(newValue, e);
              console.log(newValue.replace("90", ""));
              setSearchNumber(newValue.replace("90", ""));
            }}
            country={context.default_locale ? context.default_locale : "tr"}
            specialLabel={context.t(`['sms/blacklist'].searchNumberInputLabel`)}
          />
        </Column>

        {/* Download Users as Excell Buton */}
        <Column className="xs-12 sm-12 md-4 lg-auto inputBtn2">
          <ExcelButton
            route="shortcode/list/blacklist"
            requestData={{ is_excel: true }}
            className=""
          />
        </Column>
      </Grid>
      <Grid>
        {/* Sum Count Users in the Blacklist*/}
        <Column
          className="xs-12 sm-12 md-12"
          style={{
            color: "#7c7c7c",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {sumBlacklistCount > 0 &&
            context.t(`[sms/blacklist].totalNumber`) + sumBlacklistCount}
        </Column>

        {/* Table with the Users in the Blacklist listed*/}
        <Column className="xs-12 sm-12 md-12">
          <Table
            refreshAction={() => getBlacklist(pagination.page)}
            loaded={loaded}
            headings={{
              phone: {
                label: context.t(`['sms/blacklist'].blacklistHeadings.number`),
              },
              created_at: {
                label: context.t(`['sms/blacklist'].blacklistHeadings.addDate`),
                limited_line: 1,
                style: {
                  maxWidth: "350px",
                },
                sortable: {
                  0: [{ field: "`blacklist`.`created_at`", order: "ASC" }],
                  1: [{ field: "`blacklist`.`created_at`", order: "DESC" }],
                },
                isClicked: isOrderASCDESC,
                onClick: () => setIsOrderASCDESC(!isOrderASCDESC),
              },
              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={blacklistNumbers}
            pagination={pagination}
            showAlwaysPagination
            buttons={[
              {
                title: context.t(`['sms/blacklist'].deleteUserBlacklist`),
                icon: "delete_forever",
                transitionEffect: true,
                textColor: "red",
                onClick: (row) => {
                  setDeleteId(row.id);
                  setOpenDeleteTemplateDialog(true);
                },
              },
            ]}
          />
        </Column>

        {/* Alertbox Modal for Deleting User from Blacklist*/}
        <AlertDialog
          title={context.t(`['sms/blacklist'].deleteConfirm.title`)}
          open={openDeleteTemplateDialog}
          disableBackdropClick={false}
          buttons={[
            {
              title: context.t(
                `['sms/template'].deleteConfirm.confirmButtonTitle`
              ),
              disabledHandleClose: true,
              onClick: () => deleteBlacklist(deleteId),
            },
            {
              title: context.t(
                `['sms/template'].deleteConfirm.discardButtonTitle`
              ),
            },
          ]}
          closeHandler={() => {
            setDeleteId(null);
            setOpenDeleteTemplateDialog(false);
          }}
        >
          <Alert severity="warning">
            <b>{context.t(`['sms/template'].deleteConfirm.alertBoldText`)}</b>
            <br />
            {context.t(`['sms/template'].deleteConfirm.alertText`)}
          </Alert>
        </AlertDialog>
      </Grid>
    </AuthContainer>
  );
};

export default Blacklist;
