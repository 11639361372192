import { SvgIcon } from "@material-ui/core";
import React from "react";

function Store() {
  return (
    <SvgIcon
      width="31"
      height="31"
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.86">
        <path d="M7.44153 3.46391C2.56153 5.64391 0.54153 6.57891 0.43153 6.70491L0.269531 6.8889V23.9609L0.42453 24.1369C0.53653 24.2569 2.78653 25.2899 7.26553 27.2799C10.9425 28.9109 14.0785 30.2959 14.2475 30.3529C14.5475 30.4579 14.5475 30.4579 15.1615 30.1529C15.9135 29.7729 16.0895 29.5409 15.9565 29.0979C15.909 28.9459 15.8168 28.8116 15.692 28.7126C15.5671 28.6137 15.4154 28.5545 15.2565 28.5429C15.0916 28.5619 14.9317 28.6121 14.7855 28.6909L14.4855 28.8319L8.11553 26.0049L1.75253 23.1779V15.7779C1.75253 11.6999 1.77353 8.3669 1.79453 8.3669C1.81553 8.3669 4.65653 9.6189 8.10153 11.1509C13.1495 13.3939 14.4085 13.9279 14.5845 13.8999C14.7045 13.8789 17.5515 12.6409 20.9125 11.1439C24.2735 9.64691 27.0855 8.40189 27.1565 8.38789C27.2685 8.35289 27.2765 8.5429 27.2765 11.6429C27.2765 14.6589 27.2905 14.9549 27.4035 15.0879C27.4633 15.1775 27.543 15.2521 27.6364 15.3058C27.7297 15.3596 27.8342 15.3911 27.9417 15.3979C28.0492 15.4046 28.1568 15.3865 28.2562 15.3449C28.3555 15.3033 28.444 15.2393 28.5145 15.1579L28.7535 14.9189V10.9489C28.7535 7.1939 28.7465 6.9759 28.6195 6.7719C28.4925 6.5679 27.7335 6.2239 21.6445 3.5099C17.8755 1.8369 14.6835 0.457894 14.5505 0.443894C14.3385 0.419894 13.1085 0.939914 7.44153 3.46391ZM18.9165 4.0049C18.8605 4.0609 7.59653 9.06691 7.42753 9.10491C7.31553 9.13991 2.94153 7.23491 2.94153 7.15691C2.94153 7.13591 5.54153 5.9569 8.72153 4.5479L14.5015 1.98192L16.7305 2.9659C17.9595 3.5209 18.9375 3.98491 18.9165 4.00591V4.0049ZM23.4865 5.98091C24.9135 6.61391 26.0735 7.14091 26.0735 7.16191C26.0735 7.18291 23.4735 8.34989 20.2865 9.77089L14.4995 12.3369L11.8835 11.1769C10.4425 10.5369 9.26753 9.99591 9.26753 9.97691C9.27453 9.92791 20.6675 4.85791 20.7985 4.85091C20.8565 4.84291 22.0695 5.35592 23.4865 5.98192V5.98091Z" />
        <path d="M21.8896 15.4659C20.1365 15.6753 18.5127 16.4941 17.302 17.7793C16.0914 19.0646 15.371 20.7344 15.2666 22.4969C15.1971 23.792 15.4646 25.0829 16.043 26.2437C16.6214 27.4045 17.4909 28.3955 18.5666 29.1199C19.6926 29.8765 21.0031 30.3128 22.3579 30.3822C23.7127 30.4516 25.0609 30.1514 26.2583 29.5138C27.4557 28.8761 28.4572 27.925 29.1559 26.7622C29.8545 25.5993 30.2239 24.2685 30.2247 22.9119C30.2267 21.0709 29.5502 19.2938 28.3246 17.9201C27.0989 16.5465 25.41 15.6727 23.5806 15.4659C23.0202 15.3812 22.4501 15.3812 21.8896 15.4659V15.4659ZM24.3717 17.1389C25.3501 17.4254 26.2412 17.9527 26.9634 18.6724C27.6855 19.3921 28.2158 20.2814 28.5056 21.2589C28.6734 21.7925 28.7379 22.3532 28.6956 22.9109C28.7379 23.4686 28.6734 24.0293 28.5056 24.5629C28.2158 25.5404 27.6855 26.4297 26.9634 27.1494C26.2412 27.8691 25.3501 28.3964 24.3717 28.6829C23.4596 28.9006 22.5135 28.9363 21.5876 28.7879C20.4066 28.5711 19.3281 27.9762 18.5146 27.0929C18.0544 26.6975 17.687 26.2055 17.4386 25.6519C16.9719 24.8155 16.7433 23.8671 16.7776 22.9099C16.746 22.0155 16.9422 21.1277 17.3476 20.3299C17.6565 19.6686 18.0913 19.0739 18.6276 18.5789C19.4264 17.7905 20.4382 17.2527 21.5386 17.0319C22.4821 16.9033 23.4407 16.9399 24.3717 17.1399V17.1389Z" />
        <path d="M25.3717 20.775C25.2717 20.838 24.4787 21.605 23.5997 22.475L21.9998 24.064L21.2268 23.312C20.4748 22.574 20.4467 22.56 20.1267 22.56C20.0281 22.5464 19.9277 22.5576 19.8345 22.5925C19.7413 22.6274 19.6582 22.6849 19.5928 22.76C19.5172 22.8265 19.4593 22.9107 19.4244 23.0052C19.3895 23.0996 19.3786 23.2013 19.3927 23.301V23.639L20.5038 24.739C21.7038 25.939 21.8608 26.033 22.2968 25.822C23.0582 25.1755 23.7836 24.4878 24.4698 23.762C26.1148 22.117 26.4178 21.772 26.4598 21.562C26.4846 21.4239 26.4699 21.2816 26.4175 21.1514C26.365 21.0213 26.2768 20.9087 26.1632 20.8264C26.0495 20.7442 25.9149 20.6957 25.7749 20.6866C25.6349 20.6775 25.4951 20.7081 25.3717 20.775V20.775Z" />
      </g>
    </SvgIcon>
  );
}

export default Store;
