import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Dialog, DialogContent } from "@material-ui/core";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import AuthContainer from "../../../components/AuthContainer";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import moment from "moment";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";

import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import Loader from "../../../assets/images/loader.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BountyTransactions = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getBountyList(page),
  });
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(null);
  const [transactionDate, setTransactionDate] = useState(ConvertTimeToZulu(moment().unix()));
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [detailsPopUp, setDetailsPopUp] = useState(false);
  const [detailsData, setDetailsData] = useState([]);

  const [bountyInput, setBountyInput] = useState(null);

  const getBountyList = (page = 1) => {
    setLoaded(false);
    Axios.get(
      `${context.api_endpoint}/company/accounting/bounty?page=${page}`
    )
      .then(({ data: { data } }) => {
        setLoaded(true);
        setData([
          ...data.records.map((item) => ({
            ...item,
            full_name: `${item.name} ${item.surname}`
          }))
        ]);
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          toast.error(e?.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      });
  };

  const payBounty = () => {
    Axios.put(`${context.api_endpoint}/company/staff/bounty/set`, {
      staff_id: selectedEmployee.id,
      payment_type: selectedPayment
        ? parseInt(selectedPayment.value)
        : null,
      transaction_date: transactionDate
        ? transactionDate
        : null,
      amount: amount
        ? parseFloat(amount) >
          parseFloat(selectedEmployee?.bounty)
          ? parseFloat(selectedEmployee?.bounty)
          : parseFloat(amount)
        : null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.data);
          getBountyList();
          setSelectedEmployee(null);
          history.push("/accounting/expense");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getBountyList();
  }, []);

  const validateBountyInput = () => {
    return Validate([
      {
        field: "Ödenecek Tutar",
        value: bountyInput,
        condition: Number,
      },
    ]);
  };

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column className="xs-12 sm-12">
            {loaded ? (
              <Table
                loaded={loaded}
                headings={{
                  full_name: {
                    label: context.t(
                      `['accounting/bounty'].headings.staffFullName`
                    ),
                  },
                  bounty: {
                    label: context.t(
                      `['accounting/bounty'].headings.bounty`
                    ),
                    suffix: context.state.currency
                      ? context.state.currency
                      : "₺",
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={data}
                pagination={pagination}
                buttons={[
                  {
                    icon: "payment",
                    title: context.t(
                      `['accounting/bounty'].payButtonTitle`
                    ),
                    disabled: (row) => (row.bounty ? row.bounty === 0 : true),
                    onClick: (row) => {
                      setPaymentModal(true);
                      setSelectedEmployee(row);
                    }
                  },
                  {
                    title: `${context.t(
                      `["accounting/credit"].headings.view`
                    )}`,
                    icon: "launch",
                    textColor: "primary",
                    disabled: (row) => (row.bounty ? row.bounty === 0 : true),
                    onClick: (row) => {
                      setDetailsData(data.filter((m) => m.id === row.id));
                      setDetailsPopUp(true);
                    }
                  },
                ]}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={
                    process.env.APP_NAME === "salon"
                      ? Loader
                      : process.env.APP_NAME === "en"
                      ? LoaderEN
                      : LoaderSM
                  }
                  width="100"
                  height="100"
                  alt="loading"
                />
              </div>
            )}

            <AlertDialog
              open={paymentModal}
              title={context.t(`['accounting/bounty'].check.title`)}
              closeHandler={() => setPaymentModal(false)}
              textType
              buttons={[
                {
                  icon: "check",
                  title: context.t(
                    `['accounting/bounty'].check.confirmButtonTitle`
                  ),
                  onClick: () => {
                    selectedPayment !== null
                      ? payBounty()
                      : toast.warning(context.t(`["accounting/bounty"].paymentType`));
                  },
                },
                {
                  icon: "close",
                  title: context.t(
                    `['accounting/bounty'].check.discardButtonTitle`
                  ),
                  textColor: "grey",
                },
              ]}
            >
              <b>
                {context.t(`['accounting/bounty'].check.alertBoldText`, {
                  selected_employee: selectedEmployee?.full_name,
                  bounty: selectedEmployee?.bounty ?? "0",
                })}
              </b>
              <Grid>
                <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                  <DatePickerInput
                    label={context.t(
                      `['accounting/expense'].add.processDate`
                    )}
                    onChange={async (process_date) => {
                      await setTransactionDate(ConvertTimeToZulu(moment(process_date).unix()));
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <Select
                    label={context.t(
                      `['accounting/credit'].payments.typeInputLabel`
                    )}
                    placeholder={context.t(
                      `['accounting/credit'].payments.typeInputPlaceholder`
                    )}
                    items={[
                      {
                        label: context.t(
                          `['accounting/credit'].payments.typeCASH`
                        ),
                        value: "0",
                      },
                      {
                        label: context.t(
                          `['accounting/credit'].payments.typeCARD`
                        ),
                        value: "1",
                      },
                      {
                        label: context.t(
                          `['accounting/credit'].payments.typeEFT`
                        ),
                        value: "2",
                      },
                      {
                        label: context.t(
                          `['accounting/credit'].payments.typePOINT`
                        ),
                        value: "3",
                      },
                    ]}
                    selected={
                      selectedPayment &&
                      selectedPayment.value
                    }
                    labelKey="label"
                    valueKey="value"
                    handler={async (payment_type) => {
                      await setSelectedPayment({...selectedPayment, value: payment_type});
                    }}
                  />
                </Column>
                <Grid>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-8">
                    <Input
                      label={context.t(
                        `['accounting/credit'].payments.amountInputLabel`
                      )}
                      type="number"
                      value={amount}
                      onChange={(e) =>
                        e.target.value.trim() > -1 && setAmount(
                          parseFloat(e.target.value) > parseFloat(selectedEmployee?.bounty)
                              ? parseFloat(selectedEmployee?.bounty)
                              : parseFloat(e.target.value)
                        )
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                    <Button
                      icon="add"
                      style={{
                        height: "78%",
                      }}
                      title={context.t(
                        `['accounting/bounty'].allPayButtonTitle`
                      )}
                      backgroundColor="primary-opacity"
                      textColor="primary"
                      size="sm"
                      fullWidth
                      onClick={() => setAmount(selectedEmployee?.bounty)}
                    />
                  </Column>
                </Grid>
              </Grid>
            </AlertDialog>
            <AlertDialog
              open={detailsPopUp}
              maxWidth="sm"
              fullWidth={true}
              closeHandler={() => setDetailsPopUp(false)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  icon="close"
                  iconColor="black"
                  iconSize={25}
                  transitionEffect={true}
                  onClick={() => setDetailsPopUp(false)}
                />
              </div>

              <table
                style={{
                  width: "100%",
                }}
              >
                <thead style={{ marginBottom: "15px" }}>
                  <tr>
                    <th style={{ textAlign: "center" }}>{context.t(`["accounting/bounty"].customerInfo.name`)}</th>
                    <th style={{ textAlign: "center" }}>
                      {context.t(`["accounting/bounty"].customerInfo.paid`)} (
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                      )
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {context.t(`["accounting/bounty"].customerInfo.primAmount`)} (
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                      )
                    </th>
                    <th style={{ textAlign: "center" }}>{context.t(`["accounting/bounty"].customerInfo.transactions`)}</th>
                  </tr>
                </thead>
                {detailsData.length === 1 &&
                  detailsData[0]?.bounty_details.map((m, index) => (
                    <tbody key={index}>
                      <tr style={{ marginBottom: "10px" }}>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {m?.customer_name === "" ? (
                            <p
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              -
                            </p>
                          ) : (
                            <p
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              {m?.customer_name}
                            </p>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p>{m?.paid_amount}</p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p>{m?.bounty}</p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            icon="launch"
                            transitionEffect={true}
                            title={context.t(`["accounting/bounty"].customerInfo.routeReceipt`)}
                            onClick={() => {
                              if (m.receipt_id !== 0) {
                                history.push({
                                  pathname: `/receipts/detail/${m.receipt_id}`,
                                  state: { row: m.receipt_id }
                                }
                                );
                              } else {
                                toast.warn(context.t(`["accounting/bounty"].customerInfo.routeError`));
                              }
                            }}
                          ></Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </AlertDialog>
          </Column>
        </Grid>
      </AuthContainer>
    );
}

export default BountyTransactions;
