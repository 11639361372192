import React from "react";
import Axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";

const AuthWithToken = async (token, context) => {
  const { obj } = jwt_decode(token);
  console.log("obj", obj);
  // console.log('DECODED_TOKEN', decoded_token);

  Axios.defaults.headers.Authorization = await `Bearer ${token}`;

  await context.dispatch({
    type: "SET_COMPANY_SHIFT",
    payload: { ...obj.hours },
  });

  await context.dispatch({
    type: "SET_COMPANY_LICENSE",
    payload: { company_license: obj.license },
  });

  await context.dispatch({
    type: "SET_COMPANY_SERVICES",
    payload: obj.company_services !== null ? [...obj.company_services] : [],
  });

  await context.dispatch({
    type: "SET_COMPANY_SHIFT",
    payload: obj.hours !== null ? { ...obj.hours } : {},
  });

  await context.dispatch({
    type: "SET_COMPANY_ID",
    payload: {
      company_id: obj.company_id,
    },
  });

  await context.dispatch({
    type: "SET_COMPANY_BRANCH_SETTINGS",
    payload: {
      company_is_branch: obj.company === null || obj.company.is_dealer,

      company_branches: obj.company !== null ? obj.company.dealers : [],

      company_branch_selected:
        !obj.company?.dealers?.length > 0 ||
        obj.permission === 0 ||
        obj.company === null ||
        obj.company.is_dealer,
    },
  });

  await context.dispatch({
    type: "SET_COMPANY_FIRST_BRANCH",
    payload: {
      id: obj.company?.id, // İşletme ID
      name: obj.company?.name, // İşletme adı
      staffs: [
        {
          id: obj.id,
          name: obj.name,
          surname: obj.surname,
          full_name: obj.full_name,
          detail: {
            phone: obj.detail.phone,
            mail: obj.detail.mail,
          },
        },
      ],
      license: {
        ...obj.license,
        end_date_text: obj.license?.end_date
          ? moment(obj.license.end_date)
              .tz("Europe/Istanbul")
              .format("DD/MM/YYYY HH:mm")
          : null,
      },
    },
  });

  await context.dispatch({
    type: "SET_COMPANY_TUTORIAL",
    payload: {
      company_tutorial: obj.company_tutorial,
    },
  });

  await context.dispatch({
    type: "SET_LIMITED_PERMISSION",
    payload: {
      limited_permission: { ...obj.limited_perm },
    },
  });

  await context.dispatch({
    type: "LOGIN_USER",
    payload: {
      is_authenticated: true,
      token_expire_date: new Date().setDate(new Date().getDate() + 1),
      token: token,
      user: {
        id: obj.id,
        full_name: obj.full_name,
        permission: obj.permission,
        profile_photo: obj.detail.profile_photo,
      },
    },
  });

  await context.dispatch({
    type: "SET_WHATSAPP_PERMISSON",
    payload: {
      whatsapp_permission: obj.license?.whatsapp,
    },
  });

  await context.dispatch({
    type: "SET_ONLINE_LINK",
    payload: {
      online_link: obj.license?.online_link,
    },
  });

  await context.dispatch({
    type: "SET_LICENSE_CHOOSEN",
    payload: {
      license_choosen: obj.company.is_selected_starting_license,
    },
  });

  await context.dispatch({
    type: "SET_DIAL_CODE",
    payload: {
      dialCode: obj.company.dialCode,
    },
  });

  await context.dispatch({
    type: "SET_COUNTRY_CODE",
    payload: {
      countryCode: obj.company.countryCode,
    },
  });

  await context.dispatch({
    type: "CURRENCY",
    payload: {
      currency: obj.company?.currency,
    },
  });
  await context.dispatch({
    type: "CURRENCY_CODE",
    payload: {
      currencyCode: obj.company?.currencyCode,
    },
  });
  return <></>;
};

export default AuthWithToken;
