import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../context/store";
import AuthorityNotFound from "./AuthorityNotFound";
import PlanUpgrade from "./PlanUpgrade";

class AuthContainer extends Component {
  static contextType = AppContext;

  render() {
    const { history } = this.props;
    const { state, dispatch } = this.context;

    if (state.is_authenticated === true && state.token_expire_date !== null) {
      // console.log(
      //   `Şimdi: ${new Date()} \nToken Bitişi: ${new Date(
      //     state.token_expire_date
      //   )}`
      // );
      if (new Date(state.token_expire_date) < new Date()) {
        (async () => {
          await dispatch({ type: "LOGOUT_USER" });
          await history.push("/");
        })();
      }
    }

    return this.props.makePlanUpgrade &&
      this.props.makePlanUpgrade.includes(
        this.context.state.company_license.accounting ?? 2
      ) ? (
      <PlanUpgrade />
    ) : this.props.authorities ? (
      this.props.authorities.includes(this.context.state.user.permission) ? (
        this.props.children
      ) : this.context.state.user.permission === 2 &&
        this.context.state.limited_permission[this.props.limited_permission] >
          0 ? (
        this.props.children
      ) : (
        <AuthorityNotFound />
      )
    ) : this.props.limited_permission === undefined ||
      (this.context.state.user.permission === 2 &&
        this.context.state.limited_permission[this.props.limited_permission] >
          0) ||
      this.context.state.user.permission === 0 ||
      this.context.state.user.permission === 1 ? (
      this.props.children
    ) : (
      <AuthorityNotFound />
    );
  }
}

export default withRouter(AuthContainer);

/* import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../context/store";
import AuthorityNotFound from "./AuthorityNotFound";
import PlanUpgrade from "./PlanUpgrade";

class AuthContainer extends Component {
  static contextType = AppContext;

  render() {
    const { history } = this.props;
    const { state, dispatch } = this.context;

    if (state.is_authenticated === true && state.token_expire_date !== null) {
      // console.log(`Şimdi: ${new Date()} \nToken Bitişi: ${new Date(state.token_expire_date)}`);
      if (new Date(state.token_expire_date) < new Date()) {
        (async () => {
          await dispatch({ type: "LOGOUT_USER" });
          await history.push("/");
        })();
      }
    }

    return this.props.makePlanUpgrade &&
      this.props.makePlanUpgrade.includes(
        this.context.state.company_license.accounting ?? 2
      ) ? (
      <PlanUpgrade />
    ) : this.props.authorities ? (
      this.props.authorities.includes(this.context.state.user.permission) ? (
        this.props.children
      ) : (
        <AuthorityNotFound />
      )
    ) : (
      this.props.children
    );
  }
}

export default withRouter(AuthContainer); */
