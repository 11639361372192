import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../context/store";
import AuthContainer from "../components/AuthContainer";
import { Grid, Column } from "../theme/Grid";
import Axios from "axios";
import EmpoolesPackagesTable from "../theme/EmpoolesPackagesTable";
import { toast } from "react-toastify";
// import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../theme/FCLabel";
import Input from "../theme/CustomMUI/Input";
import AlertDialog from "../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";

const EmpoolesPackages = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getPackages(page),
  });
  const [data, setData] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [packets_name, setPacketsName] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemForDeleteId, setItemForDeleteId] = useState("");

  //Backend Bekleniyor
  const getPackages = useCallback((page) => {
    const urlPage = parseInt(
      new URLSearchParams(window.location.search).get("page")
    );
    Axios.get(`${context.api_endpoint}/company/packets`, {
      params: {
        page: page ? page : urlPage ? urlPage : 1,
        name: packets_name ?? "",
      },
    })
      .then(({ data: { data } }) => {
        setLoaded(true);
        setData([...data.packets.records]);
        setPagination({
          ...pagination,
          page: data.packets.page,
          total_page: data.packets.records.length,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  });

  const deletePackage = () => {
    Axios.delete(
      `${context.api_endpoint}/company/packet/delete/${itemForDeleteId}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`['packages/detail/id'].deleteToast`));
          history.push("/packages/list");
          setItemForDeleteId("");
          getPackages();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getPackages();
  }, [packets_name]);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid className="mb-2">
        <>
          <Column className="xs-12 sm-12 md-12 lg-6">
            <Input
              label={context.t(`[packages/sales/add].searchPackegeInputLabel`)}
              placeholder={context.t(`[packages/sales/add].enterPackageName`)}
              style={{ width: "100%" }}
              fullWidth={true}
              onChange={async (e) => {
                if (typingTimeout) {
                  clearTimeout(typingTimeout);
                }
                setPacketsName(e.target.value);
                await setTypingTimeout(
                  setTimeout(() => {
                    getPackages();
                  }, 1500)
                );
              }}
            />
          </Column>
        </>
      </Grid>
      <Grid>
        <Column className="xs-12 sm-12">
          <EmpoolesPackagesTable
            packets={props.packets}
            updateState={props.updateState}
            headings={{
              name: {
                label: context.t(`['packages/list'].headings.name`),
              },

              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={data}
            loaded={loaded}
            pagination={pagination}
            buttons={[
              {
                title: "seç",
                icon: "check",
                textColor: "primary",
                transitionEffect: true,
                onClick: (row) => console.log(row),
              },
            ]}
          />
        </Column>
      </Grid>

      <AlertDialog
        title={context.t(`['packages/detail/id'].delete.title`)}
        open={deleteModal}
        closeHandler={() => setDeleteModal(false)}
        buttons={[
          {
            title: context.t(
              `['packages/detail/id'].delete.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deletePackage(),
          },
          {
            title: context.t(
              `['packages/detail/id'].delete.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          {context.t(`['packages/detail/id'].delete.alertText`)}
        </Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default EmpoolesPackages;
