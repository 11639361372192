import moment from "moment";

const BetweenTimeGenerator = (config) => {
  //TODO start 10:00  end 02:00
  if (config.slice === "0") return [];

  const slice = config.slice;
  const start_date = moment(`1970-01-01 ${config.start_hour}:00`);
  const end_date = moment(`1970-01-01 ${config.end_hour}:00`);

  if (parseInt(config.start_hour) > parseInt(config.end_hour)) {
    end_date.add(1, "days");
  }

  const diff = end_date.diff(start_date, "minutes");
  const slice_piece = diff / slice;
  // console.log(diff, slice_piece);

  const hour_slices = [];
  for (let i = 0; i < slice_piece; i++) {
    const y = moment(start_date);
    hour_slices.push(y.add("minutes", slice * i).format("HH:mm"));
  }

  return hour_slices.length > 0 ? hour_slices : [];
};

export default BetweenTimeGenerator;
