const AreaCode = [
  { areaCode: "+1", country: "us" },
  { areaCode: "+1", country: "ca" },
  { areaCode: "+7", country: "ru" },
  { areaCode: "+20", country: "eg" },
  { areaCode: "+27", country: "za" },
  { areaCode: "+30", country: "gr" },
  { areaCode: "+31", country: "nl" },
  { areaCode: "+32", country: "be" },
  { areaCode: "+33", country: "fr" },
  { areaCode: "+34", country: "es" },
  { areaCode: "+36", country: "hu" },
  { areaCode: "+39", country: "it" },
  { areaCode: "+40", country: "ro" },
  { areaCode: "+41", country: "ch" },
  { areaCode: "+43", country: "at" },
  { areaCode: "+44", country: "gb" },
  { areaCode: "+45", country: "dk" },
  { areaCode: "+46", country: "se" },
  { areaCode: "+47", country: "no" },
  { areaCode: "+48", country: "pl" },
  { areaCode: "+49", country: "de" },
  { areaCode: "+51", country: "pe" },
  { areaCode: "+52", country: "mx" },
  { areaCode: "+53", country: "cu" },
  { areaCode: "+54", country: "ar" },
  { areaCode: "+55", country: "br" },
  { areaCode: "+56", country: "cl" },
  { areaCode: "+57", country: "co" },
  { areaCode: "+58", country: "ve" },
  { areaCode: "+60", country: "my" },
  { areaCode: "+61", country: "au" },
  { areaCode: "+62", country: "id" },
  { areaCode: "+63", country: "ph" },
  { areaCode: "+64", country: "nz" },
  { areaCode: "+65", country: "sg" },
  { areaCode: "+66", country: "th" },
  { areaCode: "+81", country: "jp" },
  { areaCode: "+82", country: "kr" },
  { areaCode: "+84", country: "vn" },
  { areaCode: "+86", country: "cn" },
  { areaCode: "+90", country: "tr" },
  { areaCode: "+91", country: "in" },
  { areaCode: "+92", country: "pk" },
  { areaCode: "+93", country: "af" },
  { areaCode: "+94", country: "lk" },
  { areaCode: "+95", country: "mm" },
  { areaCode: "+98", country: "ir" },
  { areaCode: "+211", country: "ss" },
  { areaCode: "+212", country: "ma" },
  { areaCode: "+213", country: "dz" },
  { areaCode: "+216", country: "tn" },
  { areaCode: "+218", country: "ly" },
  { areaCode: "+220", country: "gm" },
  { areaCode: "+221", country: "sn" },
  { areaCode: "+222", country: "mr" },
  { areaCode: "+223", country: "ml" },
  { areaCode: "+224", country: "gn" },
  { areaCode: "+225", country: "ci" },
  { areaCode: "+226", country: "bf" },
  { areaCode: "+227", country: "ne" },
  { areaCode: "+228", country: "tg" },
  { areaCode: "+229", country: "bj" },
  { areaCode: "+230", country: "mu" },
  { areaCode: "+231", country: "lr" },
  { areaCode: "+232", country: "sl" },
  { areaCode: "+233", country: "gh" },
  { areaCode: "+234", country: "ng" },
  { areaCode: "+235", country: "td" },
  { areaCode: "+236", country: "cf" },
  { areaCode: "+237", country: "cm" },
  { areaCode: "+238", country: "cv" },
  { areaCode: "+239", country: "st" },
  { areaCode: "+240", country: "gq" },
  { areaCode: "+241", country: "ga" },
  { areaCode: "+242", country: "cg" },
  { areaCode: "+243", country: "cd" },
  { areaCode: "+244", country: "ao" },
  { areaCode: "+245", country: "gw" },
  { areaCode: "+246", country: "io" },
  { areaCode: "+248", country: "sc" },
  { areaCode: "+249", country: "sd" },
  { areaCode: "+250", country: "rw" },
  { areaCode: "+251", country: "et" },
  { areaCode: "+252", country: "so" },
  { areaCode: "+253", country: "dj" },
  { areaCode: "+254", country: "ke" },
  { areaCode: "+255", country: "tz" },
  { areaCode: "+256", country: "ug" },
  { areaCode: "+257", country: "bi" },
  { areaCode: "+258", country: "mz" },
  { areaCode: "+260", country: "zm" },
  { areaCode: "+261", country: "mg" },
  { areaCode: "+262", country: "re" },
  { areaCode: "+263", country: "zw" },
  { areaCode: "+264", country: "na" },
  { areaCode: "+265", country: "mw" },
  { areaCode: "+266", country: "ls" },
  { areaCode: "+267", country: "bw" },
  { areaCode: "+268", country: "sz" },
  { areaCode: "+269", country: "km" },
  { areaCode: "+290", country: "sh" },
  { areaCode: "+291", country: "er" },
  { areaCode: "+297", country: "aw" },
  { areaCode: "+298", country: "fo" },
  { areaCode: "+299", country: "gl" },
  { areaCode: "+350", country: "gi" },
  { areaCode: "+351", country: "pt" },
  { areaCode: "+352", country: "lu" },
  { areaCode: "+353", country: "ie" },
  { areaCode: "+354", country: "is" },
  { areaCode: "+355", country: "al" },
  { areaCode: "+356", country: "mt" },
  { areaCode: "+357", country: "cy" },
  { areaCode: "+358", country: "fi" },
  { areaCode: "+359", country: "bg" },
  { areaCode: "+370", country: "lt" },
  { areaCode: "+371", country: "lv" },
  { areaCode: "+372", country: "ee" },
  { areaCode: "+373", country: "md" },
  { areaCode: "+374", country: "am" },
  { areaCode: "+375", country: "by" },
  { areaCode: "+376", country: "ad" },
  { areaCode: "+377", country: "mc" },
  { areaCode: "+378", country: "sm" },
  { areaCode: "+380", country: "ua" },
  { areaCode: "+381", country: "rs" },
  { areaCode: "+382", country: "me" },
  { areaCode: "+383", country: "xk" },
  { areaCode: "+385", country: "hr" },
  { areaCode: "+386", country: "si" },
  { areaCode: "+387", country: "ba" },
  { areaCode: "+389", country: "mk" },
  { areaCode: "+420", country: "cz" },
  { areaCode: "+421", country: "sk" },
  { areaCode: "+423", country: "li" },
  { areaCode: "+500", country: "fk" },
  { areaCode: "+501", country: "bz" },
  { areaCode: "+502", country: "gt" },
  { areaCode: "+503", country: "sv" },
  { areaCode: "+504", country: "hn" },
  { areaCode: "+505", country: "ni" },
  { areaCode: "+506", country: "cr" },
  { areaCode: "+507", country: "pa" },
  { areaCode: "+508", country: "pm" },
  { areaCode: "+509", country: "ht" },
  { areaCode: "+590", country: "gp" },
  { areaCode: "+591", country: "bo" },
  { areaCode: "+592", country: "gy" },
  { areaCode: "+593", country: "ec" },
  { areaCode: "+594", country: "gf" },
  { areaCode: "+595", country: "py" },
  { areaCode: "+596", country: "mq" },
  { areaCode: "+597", country: "sr" },
  { areaCode: "+598", country: "uy" },
  { areaCode: "+599", country: "cw" },
  { areaCode: "+670", country: "tl" },
  { areaCode: "+672", country: "nf" },
  { areaCode: "+673", country: "bn" },
  { areaCode: "+674", country: "nr" },
  { areaCode: "+675", country: "pg" },
  { areaCode: "+676", country: "to" },
  { areaCode: "+677", country: "sb" },
  { areaCode: "+678", country: "vu" },
  { areaCode: "+679", country: "fj" },
  { areaCode: "+680", country: "pw" },
  { areaCode: "+681", country: "wf" },
  { areaCode: "+682", country: "ck" },
  { areaCode: "+683", country: "nu" },
  { areaCode: "+685", country: "ws" },
  { areaCode: "+686", country: "ki" },
  { areaCode: "+687", country: "nc" },
  { areaCode: "+688", country: "tv" },
  { areaCode: "+689", country: "pf" },
  { areaCode: "+690", country: "tk" },
  { areaCode: "+691", country: "fm" },
  { areaCode: "+692", country: "mh" },
  { areaCode: "+850", country: "kp" },
  { areaCode: "+852", country: "hk" },
  { areaCode: "+853", country: "mo" },
  { areaCode: "+855", country: "kh" },
  { areaCode: "+856", country: "la" },
  { areaCode: "+880", country: "bd" },
  { areaCode: "+886", country: "tw" },
  { areaCode: "+960", country: "mv" },
  { areaCode: "+961", country: "lb" },
  { areaCode: "+962", country: "jo" },
  { areaCode: "+963", country: "sy" },
  { areaCode: "+964", country: "iq" },
  { areaCode: "+965", country: "kw" },
  { areaCode: "+966", country: "sa" },
  { areaCode: "+967", country: "ye" },
  { areaCode: "+968", country: "om" },
  { areaCode: "+970", country: "ps" },
  { areaCode: "+971", country: "ae" },
  { areaCode: "+972", country: "il" },
  { areaCode: "+973", country: "bh" },
  { areaCode: "+974", country: "qa" },
  { areaCode: "+975", country: "bt" },
  { areaCode: "+976", country: "mn" },
  { areaCode: "+977", country: "np" },
  { areaCode: "+992", country: "tj" },
  { areaCode: "+993", country: "tm" },
  { areaCode: "+994", country: "az" },
  { areaCode: "+995", country: "ge" },
  { areaCode: "+996", country: "kg" },
  { areaCode: "+998", country: "uz" },
];

export default AreaCode;
