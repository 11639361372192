import React, { useState, useContext, useEffect, useRef } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { Card, CardContent, Typography, Divider, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { GiCheckMark } from "react-icons/gi";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import AppContext from "../../../../context/store";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ribbon from "../../../../assets/images/ribbon.png";
import Axios from "axios";
import { Tooltip } from "@material-ui/core";
import { BsInfoSquareFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import PlusGoldDetail from "./PlusDetails";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-scroll";

const stylesSW = {
  buttonRowTop: {
    position: "relative",
    width: "100%", // Responsive width
    maxWidth: "300px", // Max width to prevent it from getting too large
    height: "40px",
    backgroundColor: "#ececec",
    borderRadius: "20px",
    cursor: "pointer",
    overflow: "hidden",
  },
  buttonSwitch: {
    position: "absolute",
    width: "50%", // Responsive width
    height: "100%",
    backgroundColor: "#007bff",
    borderRadius: "20px",
    zIndex: 1,
    transition: "transform 0.5s ease-in-out",
  },
  textContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    zIndex: 2,
    padding: "0 5%",
  },
  textSwitchActive: {
    color: "#ffffff",
    fontWeight: "bold",
    flex: 1, // Responsive width
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
  },
  textSwitch: {
    color: "#000000",
    flex: 1, // Responsive width
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },
};

const packageNames = {
  goldMonthly: "Gold Paket (1 Ay)",
  goldYearly: "Gold Paket (12 Ay)",
  platiniumMonthly: "Platinium Paket (1 Ay)",
  platiniumYearly: "Platinium Paket (12 Ay)",
  diamondMonthly: "Diamond Paket (1 Ay)",
  diamondYearly: "Diamond Paket (12 Ay)",
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Licences({ packages, token }) {
  const context = useContext(AppContext);
  const [selectLicenceType, setSelectLicenceType] = useState(null);
  const [licenceDetail, setLicenceDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);
  const [monthly, setMonthly] = useState(1);
  const [selectPackge, setSelectPackage] = useState([]);
  const [currency, setCurrency] = useState("TRY");
  const [extras, setExtras] = useState(false);
  const [plusOpen, setPlusOpen] = useState(false);
  const [preview, setPreview] = useState(false);

  const buyLicenceHandler = async () => {
    await Axios.post(`${context.api_endpoint}/first`, {
      token: token,
      packetId: selectPackge && selectPackge[monthly].ID,
      extras: extras,
      currency: currency,
    })
      .then((data) => window.open(data.data.data, "_blank"))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (selectLicenceType) {
      setSelectPackage(
        packages.filter((m) => m.name.includes(selectLicenceType))
      );
    }
  }, [selectLicenceType]);

  const total =
    packages.filter((m) => m.name === "whatsapp")[0]?.discounted +
    packages.filter((m) => m.name === "onlineLink")[0]?.discounted +
    packages.filter((m) => m.name === "goldPlusGiftSms")[0]?.discounted +
    packages.filter((m) => m.name === "barcode")[0]?.discounted;

  const total2 =
    packages.filter((m) => m.name === "whatsapp")[0]?.discounted +
    packages.filter((m) => m.name === "onlineLink")[0]?.discounted +
    packages.filter((m) => m.name === "goldPlusGiftSms")[0]?.discounted;

  const [switchState, setSwitchState] = useState(false);
  const buttonRef = useRef(null);

  const slideRight = () => {
    buttonRef.current.style.transform = "translateX(150px)";
  };

  const slideLeft = () => {
    buttonRef.current.style.transform = "translateX(0px)";
  };

  const changeSwitch = () => {
    if (!switchState) {
      slideRight();
    } else {
      slideLeft();
    }
    setSwitchState(!switchState);
  };

  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "20px",
        marginTop: "40px",
        //  backgroundImage: "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
      }}
    >
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          marginBottom: "50px",
        }}
        className="container"
      >
        <Grid item xs={12} sm={12} md={12}>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-2 ">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  width: "80%",
                  textAlign: "center",
                  marginTop: "30px",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "36px",
                    fontWeight: "bold",
                    background:
                      "linear-gradient(to right, #ed1e79 0%, #85298b 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "Mulish, sans-serif",
                    textShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  İlk Alıma Özel
                </span>
                <span
                  style={{
                    fontSize: "22px",

                    background: "#d70c58",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "Mulish, sans-serif",
                    textShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {" "}
                  İndirimli Fiyatlar !
                </span>
              </p>
            </div>
          </Column>
          <Column className="xs-12 sm-6 md-6 lg-4 xl-4 mt-5 ">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "92%", position: "relative" }}>
                <Card
                  style={{
                    border: "3px solid rgb(215,12,88)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                      backgroundPosition: "center",
                      padding: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#FFFFF7",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
                      }}
                    >
                      <Tooltip
                        key={1}
                        title={
                          "Randevu Yönetimi Yapmak İsteyen İşletmeler İçin Uygun"
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        placement="bottom"
                      >
                        <span>
                          <BsInfoSquareFill
                            style={{
                              cursor: "pointer",
                            }}
                            color={"white"}
                            size={18}
                          />
                        </span>
                      </Tooltip>{" "}
                      Gold Paket{" "}
                    </h1>
                  </CardContent>
                  <CardContent>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />5 Personele Kadar
                        Sınırsız Randevu
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" /> Çoklu Şube
                        Yönetimi{" "}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" /> Özel Müşteri
                        Temsilcisi{" "}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" /> İşletme Galerisi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        SMS Gönderimi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Yeni Üyelere Özel "250 SMS Hediye"
                      </div>
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px 0",
                    }}
                  >
                    <Divider style={{ flex: 1 }} />
                    <div
                      id="test"
                      style={{
                        cursor: "pointer",
                        color: "#808080",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        height: "20px",
                        padding: "5px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "10px",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={() => {
                        setOpen(true);
                        setLicenceDetail(1);
                      }}
                    >
                      <BsSearch
                        style={{
                          height: "20px",
                          display: "flex",
                          alignContent: "center",
                          marginRight: "5px",
                        }}
                        size={12}
                      />

                      <div>tüm özellikleri gör</div>
                    </div>
                    <Divider style={{ flex: 1 }} />
                  </div>
                  <CardContent>
                    <Typography
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      variant="body2"
                      component="p"
                    >
                      <div
                        style={{
                          width: "50%",
                          position: "relative",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "-20%",
                            right: "3%",
                            fontSize: "10px",
                            fontFamily: "Arial, sans-serif",
                            padding: "4px",
                            backgroundColor: "#00bf63",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <FaCheckCircle />{" "}
                          <span style={{ marginTop: "2px" }}>Kazancın</span>
                          <span style={{ fontSize: "13px", marginTop: "2px" }}>
                            ₺ 233
                          </span>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textDecorationLine: "line-through",
                          }}
                        >
                          <span> ₺</span>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            599
                          </p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>₺</span>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          >
                            366
                          </p>
                          <span
                            style={{
                              height: "25px",
                              display: "flex",
                              alignItems: "end",
                              marginLeft: "1px",
                            }}
                          >
                            / aylık
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                          // marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={() => {
                            window.location.href =
                              "https://enrandevu.com/uye-ol";
                          }}
                          style={{
                            padding: "7px",
                            width: "120px",
                            borderRadius: "30px",
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            cursor: "pointer",
                            backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                            color: "white",
                            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ShoppingCartIcon style={{ fontSize: "20px" }} />{" "}
                            <span
                              style={{
                                fontWeight: 400,
                                marginLeft: "6px",
                              }}
                            >
                              Şimdi Al
                            </span>
                          </div>
                        </button>
                        <Link
                          to="wpdensor"
                          smooth={true}
                          duration={1200}
                          style={{
                            color: "rgba(132,0,135,1)",
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: "12px",
                            marginTop: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Daha fazla bilgi al
                        </Link>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Column>

          <Column className="xs-12 sm-6 md-6 lg-4 xl-4 mt-5">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "92%", position: "relative" }}>
                <Card
                  style={{
                    border: "3px solid rgb(215,12,88)",
                    borderRadius: "10px",
                  }}
                >
                  <div>
                    <img
                      src={ribbon}
                      style={{
                        width: "45%",
                        objectFit: "contain",
                        position: "absolute",
                        top: "2%",
                        right: "-10%",
                        transform: "rotate(40deg)", // 30 derece dönüş
                      }}
                    />
                  </div>

                  <CardContent
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                      backgroundPosition: "center",
                      padding: "20px",
                    }}
                  >
                    <h1
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "white",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <Tooltip
                        key={2}
                        title={
                          "Randevu,Ön Muhasebe ve Prim Yönetimi Yapmak İsteyen İşletmeler İçin Uygun"
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        placement="bottom"
                      >
                        <span>
                          <BsInfoSquareFill
                            style={{
                              cursor: "pointer",
                            }}
                            color={"white"}
                            size={18}
                          />
                        </span>
                      </Tooltip>{" "}
                      Platinium Paket
                    </h1>
                  </CardContent>
                  <CardContent>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />7 Personele Kadar
                        Sınırsız Randevu
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <GiCheckMark color="rgb(0, 191, 99)" /> Adisyon
                        İşlemleri
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <GiCheckMark color="rgb(0, 191, 99)" /> Paket Yönetimi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Stok Yönetimi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Prim İşlemleri
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Yeni üyelere özel "500 SMS hediye"
                      </div>
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px 0",
                    }}
                  >
                    <Divider style={{ flex: 1 }} />
                    <div
                      id="test"
                      style={{
                        cursor: "pointer",
                        color: "#808080",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        height: "20px",
                        padding: "5px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "10px",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={() => {
                        setOpen(true);
                        setLicenceDetail(2);
                      }}
                    >
                      <BsSearch
                        style={{
                          height: "20px",
                          display: "flex",
                          alignContent: "center",
                          marginRight: "5px",
                        }}
                        size={12}
                      />

                      <div>tüm özellikleri gör</div>
                    </div>
                    <Divider style={{ flex: 1 }} />
                  </div>
                  <CardContent>
                    <Typography
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      variant="body2"
                      component="p"
                    >
                      <div
                        style={{
                          width: "50%",
                          position: "relative",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "-20%",
                            right: "3%",
                            fontSize: "10px",
                            fontFamily: "Arial, sans-serif",
                            padding: "4px",
                            backgroundColor: "#00bf63",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <FaCheckCircle />{" "}
                          <span style={{ marginTop: "2px" }}>Kazancın</span>
                          <span style={{ fontSize: "13px", marginTop: "2px" }}>
                            ₺ 262
                          </span>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textDecorationLine: "line-through",
                          }}
                        >
                          <span> ₺</span>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            949
                          </p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span> ₺</span>
                          <div
                            style={{
                              padding: "0px",
                              margin: "0px",
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          >
                            687
                          </div>
                          <span
                            style={{
                              height: "25px",
                              display: "flex",
                              alignItems: "end",
                              marginLeft: "1px",
                            }}
                          >
                            / aylık
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <button
                          onClick={() => {
                            window.location.href =
                              "https://enrandevu.com/uye-ol";
                          }}
                          style={{
                            padding: "7px",
                            width: "120px",
                            borderRadius: "30px",
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            cursor: "pointer",
                            backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                            color: "white",
                            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ShoppingCartIcon style={{ fontSize: "20px" }} />{" "}
                            <span
                              style={{
                                fontWeight: 400,
                                marginLeft: "6px",
                              }}
                            >
                              Şimdi Al
                            </span>
                          </div>
                        </button>
                        <Link
                          to="wpdensor"
                          smooth={true}
                          duration={1200}
                          style={{
                            color: "rgba(132,0,135,1)",
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: "12px",
                            marginTop: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Daha fazla bilgi al
                        </Link>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Column>

          <Column className="xs-12 sm-6 md-6 lg-4 xl-4 mt-5">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "92%", position: "relative" }}>
                <Card
                  style={{
                    border: "3px solid rgb(215,12,88)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                      backgroundPosition: "center",
                      padding: "20px",
                    }}
                  >
                    <h1
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "white",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <Tooltip
                        key={3}
                        title={
                          "Sözleşme, Randevu, Ön Muhasebe ve Prim Yönetimi Yapmak İsteyen İşletmeler İçin Uygun"
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        placement="bottom"
                      >
                        <span>
                          <BsInfoSquareFill
                            style={{
                              cursor: "pointer",
                            }}
                            color={"white"}
                            size={18}
                          />
                        </span>
                      </Tooltip>{" "}
                      Diamond Paket
                    </h1>
                  </CardContent>
                  <CardContent>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        10 Personele Kadar Sınırsız Randevu
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" /> E-İmza Eklentisi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" /> Sözleşme
                        Yönetimi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Adisyon İşlemleri
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Paket Yönetimi
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <GiCheckMark color="rgb(0, 191, 99)" />
                        Yeni üyelere özel "1000 SMS Hediye"
                      </div>
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px 0",
                    }}
                  >
                    <Divider style={{ flex: 1 }} />
                    <div
                      id="test"
                      style={{
                        cursor: "pointer",
                        color: "#808080",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        height: "20px",
                        padding: "5px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "10px",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={() => {
                        setOpen(true);
                        setLicenceDetail(3);
                      }}
                    >
                      <BsSearch
                        style={{
                          height: "20px",
                          display: "flex",
                          alignContent: "center",
                          marginRight: "5px",
                        }}
                        size={12}
                      />

                      <div>tüm özellikleri gör</div>
                    </div>
                    <Divider style={{ flex: 1 }} />
                  </div>
                  <CardContent>
                    <Typography
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      variant="body2"
                      component="p"
                    >
                      <div
                        style={{
                          width: "50%",
                          position: "relative",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "-10%",
                            right: "-1%",
                            fontSize: "10px",
                            fontFamily: "Arial, sans-serif",
                            padding: "4px",
                            backgroundColor: "#00bf63",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <span>
                            <FaCheckCircle />
                          </span>
                          <span style={{ marginTop: "2px" }}>Kazancın</span>
                          <span style={{ fontSize: "13px", marginTop: "2px" }}>
                            ₺ 287
                          </span>
                        </p>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "4px",
                            textDecorationLine: "line-through",
                          }}
                        >
                          <span> ₺</span>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            1249
                          </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span> ₺</span>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          >
                            962
                          </p>
                          <span
                            style={{
                              height: "25px",
                              display: "flex",
                              alignItems: "end",
                              marginLeft: "1px",
                            }}
                          >
                            / aylık
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <button
                          onClick={() => {
                            window.location.href =
                              "https://enrandevu.com/uye-ol";
                          }}
                          style={{
                            padding: "7px",
                            width: "120px",
                            borderRadius: "30px",
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            cursor: "pointer",
                            backgroundImage: `linear-gradient(90deg, rgba(215,12,88,1) 0%, rgba(132,0,135,1) 35%)`,
                            color: "white",
                            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ShoppingCartIcon style={{ fontSize: "20px" }} />{" "}
                            <span
                              style={{
                                fontWeight: 400,
                                marginLeft: "6px",
                              }}
                            >
                              Şimdi Al
                            </span>
                          </div>
                        </button>
                        <Link
                          to="wpdensor"
                          smooth={true}
                          duration={1200}
                          style={{
                            color: "rgba(132,0,135,1)",
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: "12px",
                            marginTop: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Daha fazla bilgi al
                        </Link>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Column>
        </Grid>
        {/* Tüm özellikler için Pop-up */}
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="xs" // Maksimum genişlik
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpen(false)}
          >
            <p
              style={{
                width: "100%",
                fontSize: "22px",
                fontWeight: 100,
                lineHeight: 1.4,
                color: "#0b3052",
              }}
            >
              Tüm Özellikler
            </p>
          </DialogTitle>
          <DialogContent>
            <Typography>
              <div>
                {[
                  ...context.t(
                    `licenseDetails.${licenceDetail && licenceDetail === 1
                      ? "gold"
                      : licenceDetail === 2
                        ? "platinum"
                        : "diamond"
                    }`
                  ),
                  ...context.t("licenseDetails.includesAllLicence"),
                ].map((item, index) => (
                  <p
                    key={index}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <GiCheckMark color="rgb(0, 191, 99)" />{" "}
                    {item === `Yeni üyelere özel "2000 SMS Hediye"`
                      ? `Yeni üyelere özel "1000 SMS Hediye"`
                      : item}
                  </p>
                ))}
              </div>
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={() => setOpen(false)} color="primary">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>

        {/* Satın alımlar için Pop-up */}
        <AlertDialog
          closeHandler={() => {
            setOpenBuy(false);
            setSelectLicenceType(null);
            setSelectPackage([]);
            setMonthly(1);
            setExtras(false);
          }}
          open={openBuy}
          maxWidth="xs"
          fullWidth={true}
          title={
            <DialogTitle
              onClose={() => {
                setOpenBuy(false);
                setSelectLicenceType(null);
                setSelectPackage([]);
                setMonthly(1);
                setExtras(false);
              }}
            >
              <p
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: 100,
                  lineHeight: 1.4,
                  color: "#0b3052",
                }}
              >
                "İlk Alıma Özel" Bu Fırsatı Kaçırma!
              </p>
            </DialogTitle>
          }
        >
          <DialogContent>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <div onClick={changeSwitch} style={stylesSW.buttonRowTop}>
                <div
                  style={{
                    ...stylesSW.buttonSwitch,
                    ...stylesSW.transition,
                    transform:
                      monthly === 1 ? "translateX(100%)" : "translateX(0%)",
                    background:
                      monthly === 1
                        ? " linear-gradient(109.6deg, rgb(78, 62, 255) 11.2%, rgb(164, 69, 255) 91.1%)" // Gradient for the active state
                        : "#007FFF", // Gradient for the inactive state
                  }}
                  ref={buttonRef}
                ></div>

                <div
                  onClick={() => setMonthly(monthly === 0 ? 1 : 0)}
                  style={stylesSW.textContainer}
                >
                  <span
                    style={
                      monthly !== 1
                        ? stylesSW.textSwitchActive
                        : stylesSW.textSwitch
                    }
                  >
                    Aylık
                  </span>
                  <span
                    style={
                      monthly === 1
                        ? stylesSW.textSwitchActive
                        : stylesSW.textSwitch
                    }
                  >
                    Yıllık
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  height: "38px",
                  display: "flex",
                  alignItems: "end",
                  marginRight: "3px",
                }}
              >
                ₺
              </span>{" "}
              <span style={{ fontSize: "30px" }}>
                {selectPackge && monthly === 0
                  ? selectPackge[monthly]
                    ? (selectPackge[monthly]?.discounted).toFixed(0)
                    : ""
                  : selectPackge[monthly]
                    ? (selectPackge[monthly]?.discounted / 12).toFixed(0)
                    : ""}
              </span>{" "}
              <span
                style={{ height: "38px", display: "flex", alignItems: "end" }}
              >
                / aylık {monthly === 0 ? "" : "öde (12 ay)"}
              </span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  if (selectLicenceType !== "diamond") {
                    setPlusOpen(true);
                  } else {
                    setPreview(true);
                  }
                }}
                className="sms_btn_hover"
                style={{
                  width: "100%",
                  padding: "12px",
                  marginTop: "20px",
                  border: "none",
                  borderRadius: "20px",
                  backgroundColor: "rgb(13, 110, 253)",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Satın Al
              </button>
            </div>
          </DialogContent>
        </AlertDialog>
        <AlertDialog
          maxWidth="md"
          title={
            selectLicenceType === "gold" ? (
              <div style={{ marginTop: "40px", textAlign: "center" }}>
                Gold Plus özelliklerinden faydalanmak ister misin?
              </div>
            ) : (
              <div style={{ marginTop: "40px", textAlign: "center" }}>
                Platinium Plus özelliklerinden faydalanmak ister misin?
              </div>
            )
          }
          //  fullWidth={true}
          open={plusOpen}
          closeHandler={() => {
            setPlusOpen(false);
            setExtras(false);
            setMonthly(1);
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => {
              setPlusOpen(false);
              setExtras(false);
              setMonthly(1);
            }}
          ></DialogTitle>

          {selectLicenceType !== "diamond" ? (
            <div
              style={{
                width: "88%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  border: !extras
                    ? "0px solid #f2f2f2"
                    : "1px solid rgb(0, 191, 99)",
                  borderRadius: "20px",
                  padding: "20px",
                  transition: "border 0.2s ease",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",

                      color: "#ff9016",
                      fontSize: "18px",
                    }}
                  >
                    {selectLicenceType === "platinium" ? "Platinium" : "Gold"}{" "}
                    PLUS Paket Özellikleri
                  </p>
                </div>
                <PlusGoldDetail
                  extras={extras}
                  selectLicenceType={selectLicenceType}
                  packages={packages}
                />
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ position: "absolute", left: "10px", top: "10%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "3px",
                          padding: "4px",
                          backgroundColor: "#00bf63",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "11px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div style={{ fontSize: "11px" }}>Kazancın</div>
                        <div>
                          <div>
                            ₺
                            {selectLicenceType === "platinium"
                              ? total -
                              (packages.filter(
                                (m) => m.name === "plusPlatiniumMonthly"
                              )[0]?.discounted || 0)
                              : selectLicenceType === "gold"
                                ? total2 -
                                (packages.filter(
                                  (m) => m.name === "plusGoldMonthly"
                                )[0]?.discounted || 0)
                                : total2}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <strong>yerine</strong>
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "end",
                          display: "flex",
                          alignItems: "center",
                          textDecorationLine: "line-through",
                        }}
                      >
                        <span> ₺</span>
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          {selectLicenceType === "platinium" ? total : total2}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <span style={{ marginRight: "5px", fontWeight: "bold" }}>
                      SADECE
                    </span>{" "}
                    <span> ₺</span>
                    <p
                      style={{
                        padding: "0px",
                        margin: "0px",
                        fontSize: "20px",
                      }}
                    >
                      {selectLicenceType === "gold" &&
                        packages
                          .filter((m) => m.name === "plusGoldMonthly")[0]
                          ?.discounted.toFixed(0)}
                      {selectLicenceType === "platinium" &&
                        packages
                          .filter((m) => m.name === "plusPlatiniumMonthly")[0]
                          ?.discounted.toFixed(0)}

                      <span style={{ fontSize: "12px" }}>/ aylık</span>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  {extras ? (
                    <button
                      onClick={() => setExtras(false)}
                      className="sms_btn_hover"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "none",
                        borderRadius: "30px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "red",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                        transition: "background-color 0.5s ease",
                      }}
                    >
                      Çıkar
                    </button>
                  ) : (
                    <button
                      onClick={() => setExtras(true)}
                      className="sms_btn_hover"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "none",
                        borderRadius: "30px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "#1d8b3e",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                        transition: "background-color 0.5s ease",
                      }}
                    >
                      Ekle
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setPreview(true);
                      setPlusOpen(false);
                    }}
                    className="sms_btn_hover"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "none",
                      borderRadius: "30px",
                      color: "white",
                      cursor: "pointer",
                      backgroundColor: "#3f70ee",
                      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Devam et
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </AlertDialog>
        <AlertDialog
          open={preview}
          maxWidth="sm"
          title={
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
              onClose={() => {
                setPreview(false);
                setExtras(false);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <h3>Satın Alım Detayları</h3>
            </DialogTitle>
          }
          fullWidth={true}
          closeHandler={() => {
            setPreview(false);
            setExtras(false);
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                width: "90%",
                p: 2,
                color: selectPackge[monthly]?.name.includes("gold")
                  ? "#FF8C00"
                  : "#4682B4", // Dark Orange for Gold, Steel Blue for Platinium
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
                border: `1px solid ${selectPackge[monthly]?.name.includes("gold")
                  ? "#FF8C00"
                  : "#4682B4"
                  }`,
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              {" "}
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                {" "}
                {packageNames[selectPackge[monthly]?.name] ||
                  "Paket not found"}{" "}
              </Typography>
            </Box>

            {extras && !selectPackge[monthly]?.name.includes("diamond") && (
              <Box
                sx={{
                  width: "90%",
                  p: 2,
                  color: selectPackge[monthly]?.name.includes("gold")
                    ? "#FF8C00"
                    : "#4682B4", // Dark Orange for Gold, Steel Blue for Platinium
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  border: `1px solid ${selectPackge[monthly]?.name.includes("gold")
                    ? "#FF8C00"
                    : "#4682B4"
                    }`,
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  {selectPackge[monthly]?.name.includes("gold")
                    ? "Gold Plus Özellikleri"
                    : "Platinium Plus Özellikleri"}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Ensures vertical alignment
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  mr: 0.5, // Adjusted margin for spacing
                  fontWeight: "bold",
                  color: "#333", // Color for the currency symbol
                }}
              >
                ₺
              </Typography>
              <Typography
                variant="h4" // Adjusted for better size consistency
                sx={{
                  fontWeight: "bold",
                  color: "#FF5722", // A vibrant color for the amount, can be customized
                }}
              >
                {selectPackge[monthly]?.discounted +
                  (extras && !selectPackge[monthly]?.name.includes("diamond")
                    ? monthly === 0 &&
                      selectPackge[monthly]?.name.includes("platinium")
                      ? packages.find((m) => m.name === "plusPlatiniumMonthly")
                        ?.discounted
                      : monthly === 1 &&
                        selectPackge[monthly]?.name.includes("platinium")
                        ? packages.find((m) => m.name === "plusPlatiniumYearly")
                          ?.discounted
                        : monthly === 0 &&
                          selectPackge[monthly]?.name.includes("gold")
                          ? packages.find((m) => m.name === "plusGoldMonthly")
                            ?.discounted
                          : monthly === 1 &&
                            selectPackge[monthly]?.name.includes("gold")
                            ? packages.find((m) => m.name === "plusGoldYearly")
                              ?.discounted
                            : 0
                    : 0)}
              </Typography>
            </Box>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={buyLicenceHandler}
                className="sms_btn_hover"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "20px",
                  border: "none",
                  borderRadius: "20px",
                  backgroundColor: "rgb(13, 110, 253)",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Hemen Öde
              </button>
            </div>
            <div
              style={{ width: "100%", textAlign: "center", fontSize: "11px" }}
            >
              Ödeme sayfasındaki fiyatlara
              <strong style={{ marginLeft: "2px" }}>
                {" KDV dahil değildir."}
              </strong>{" "}
              +KDV tutarı ₺
              {((selectPackge[monthly]?.name.includes("diamond")
                ? selectPackge[monthly]?.discounted
                : selectPackge[monthly]?.discounted +
                (extras && !selectPackge[monthly]?.name.includes("diamond")
                  ? monthly === 0 &&
                    selectPackge[monthly]?.name.includes("platinium")
                    ? packages.filter(
                      (m) => m.name === "plusPlatiniumMonthly"
                    )[0]?.discounted
                    : monthly === 1 &&
                      selectPackge[monthly]?.name.includes("platinium")
                      ? packages.filter(
                        (m) => m.name === "plusPlatiniumYearly"
                      )[0]?.discounted
                      : monthly === 0 &&
                        selectPackge[monthly]?.name.includes("gold")
                        ? packages.filter((m) => m.name === "plusGoldMonthly")[0]
                          ?.discounted
                        : monthly === 1 &&
                          selectPackge[monthly]?.name.includes("gold")
                          ? packages.filter((m) => m.name === "plusGoldYearly")[0]
                            ?.discounted
                          : monthly === 0 &&
                            selectPackge[monthly]?.name.includes("platinium")
                            ? packages.filter(
                              (m) => m.name === "plusPlatiniumonthly"
                            )[0]?.discounted
                            : monthly === 1 &&
                              selectPackge[monthly]?.name.includes("platinium")
                              ? packages.filter(
                                (m) => m.name === "plusPlatiniumYearly"
                              )[0]?.discounted
                              : 0
                  : 0)) *
                20) /
                100}
            </div>
          </div>
        </AlertDialog>
      </div>
    </div>
  )
}

export default Licences;