import React, { useState } from "react";
import Navbar from "./Navbar/index";
import Header from "./Header/Header";
import Licences from "./License/index";
import Statisctic from "./Statistics";
import SayAboutUs from "./SayAboutUs";
import UserMostLike from "./UserMostLike";
import ChatBox from "./components/ChatBox";
import Footer from "./Footer/Footer";

function CampaignsPageIndex() {
  const [timeLeft, setTimeLeft] = useState(null);
  const [packages, setPackages] = useState([]);

  return (
    <div style={{ position: "relative" }}>
      <ChatBox />
      <Navbar timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      <Licences packages={packages} />
      <Header />
      <UserMostLike />
      <SayAboutUs />
      <Footer />
      <Statisctic />
    </div>
  )
}

export default CampaignsPageIndex;
