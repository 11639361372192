import styled from 'styled-components';

const SectionTitle = styled.h3`
font-size: 17px;
font-weight: 600;
padding: 0;
margin: 0 0 4px;
color: var(--dark-primary);
`;

export default SectionTitle;