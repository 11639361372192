import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

import Button from '../../theme/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Input from "../../theme/CustomMUI/Input";
import Axios from "axios";
import { toast } from "react-toastify";
import MaskedInput from "../../theme/CustomMUI/MaskedInput";
import { Alert } from "@material-ui/lab";
import AppContext from "../../context/store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${require('../../assets/images/unauth_image.jpg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const ForgotPassIndex = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const classes = useStyles();

  const [smsRequestForm, setSmsRequestForm] = useState({
    phone: null
  })


  const [newPassWithSMSCodeForm, setNewPassWithSMSCodeForm] = useState({
    code: null,
    password: null
  })

  const [step, setStep] = useState(1)


  const getSMSCode = () => {
    Axios
      .post(`${context.api_endpoint}/staff/reset/password/cd`, {
        ...smsRequestForm
      })
      .then(response => {
        setSmsRequestForm({ phone: null });
        if (response.status === 201) {
          toast.success(context.t(`forgotPass.verificationSuccessfullySentToast`));
          setStep(2)
        } else {
          toast.error(context.t(`forgotPass.verificationErrorToast`));
        }
      })
      .catch(() => {
        toast.error(context.t(`forgotPass.verificationErrorToast`));
      })
  }

  const resetPass = () => {
    Axios
      .post(`${context.api_endpoint}/staff/reset/password`, {
        ...newPassWithSMSCodeForm
      })
      .then(response => {
        if (response.status === 201) {
          toast.success(context.t(`forgotPass.passwordSuccessfullyChangedToast`));
          history.push('/login')
        } else {
          toast.error(context.t(`forgotPass.warningStatusToast`))
        }
      })

  }



  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={`${classes.paper} pt-5`}>

          <Typography component="h1" variant="h5" className="mb-0"><b>{context.app.NAME}</b></Typography>
          <Typography component="h1" variant="h6" className="mb-0">{context.t(`forgotPass.title`)}</Typography>

          <Alert severity="info" fullWidth className="mt-2 mb-2">
            <b>{context.t(`forgotPass.stepText`)}: {step}/2</b><br />
            {step === 1 && context.t(`forgotPass.firstStepText`)}
            {step === 2 && context.t(`forgotPass.secondStepText`)}
          </Alert>

          <form className={classes.form} noValidate onSubmit={e => e.preventDefault()} autoComplete="off">
            {step === 1 &&
              <>
                {process.env.APP_NAME === "management" ? (
                  <Input
                    required
                    autoComplete="off"
                    label={context.t(`register.staffMailInputLabel`)}
                    onChange={e => setSmsRequestForm({ ...smsRequestForm, phone: e.target.value })}
                  />
                ) : (
                  <MaskedInput
                    required
                    label={context.t(`forgotPass.phoneInputLabel`)}
                    maskChar={""}
                    mask="999 999 9999"
                    startAdornment="0"
                    value={smsRequestForm.phone !== null ? smsRequestForm.phone : ''}
                    placeholder="500 000 0000"
                    onChange={e => setSmsRequestForm({ ...smsRequestForm, phone: e.target.value.substring(0, 1) === "5" && e.target.value.replace(/\s/g, "") })}
                  />
                )}

                <Button
                  className="mt-1"
                  title={context.t(`forgotPass.continueButtonTitle`)}
                  type="submit"
                  size="lg"
                  fullWidth
                  textColor="white"
                  backgroundColor="primary"
                  onClick={() => getSMSCode()}
                />

                <Button
                  className="mt-1"
                  title={context.t(`forgotPass.backToHomeButtonTitle`)}
                  type="submit"
                  size="lg"
                  fullWidth
                  textColor="primary"
                  backgroundColor="white"
                  onClick={() => props.history.push('/login')}
                />
              </>
            }

            {step === 2 &&
              <>
                <Input
                  required
                  autoComplete="off"
                  label={context.t(`forgotPass.verificationCodeInputLabel`)}
                  onChange={e => setNewPassWithSMSCodeForm({ ...newPassWithSMSCodeForm, code: e.target.value })}
                />

                <Input
                  required
                  autoComplete="off"
                  label={context.t(`forgotPass.newPassInputLabel`)}
                  type="password"
                  onChange={e => setNewPassWithSMSCodeForm({ ...newPassWithSMSCodeForm, password: e.target.value })}
                />

                <Button
                  className="mt-1"
                  title={context.t(`forgotPass.updatePassButtonTitle`)}
                  type="submit"
                  size="lg"
                  fullWidth
                  textColor="white"
                  backgroundColor="primary"
                  onClick={() => resetPass()}
                />
              </>
            }
          </form>
        </div>
      </Grid>
    </Grid>
  );
}


export default withRouter(ForgotPassIndex);
