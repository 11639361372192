import { toast } from "react-toastify";

const validify = require("validify");
const CryptoJS = require("crypto-js");

const Validate = (fields) => {
  // validify("Hello World", String, "The input should be a string.").valid
  const ctx_state = localStorage.getItem("state2")
    ? JSON.parse(
        CryptoJS.AES.decrypt(localStorage.getItem("state2"), "S@LON").toString(
          CryptoJS.enc.Utf8
        )
      )
    : undefined;

  let arr = [];
  if (fields && fields.length > 0) {
    arr = [
      ...fields.map((item) => validify(item.value, item.condition, item.field)),
    ];
  }

  const falseArr = arr.filter((item) => item.valid === false);

  const string = `${falseArr.map((item, index) =>
    index === 0 ? item.error.message : ` ${item.error.message}`
  )}`;
  string.length > 0 &&
    toast.warning(
      `${
        ctx_state?.locales[ctx_state?.default_locale]["component"]
          .validationErrorText
      }: ${string}`
    );

  return falseArr.length === 0;
};

export default Validate;
