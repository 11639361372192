/* eslint-disable */
import React, { useRef, useContext } from "react";
import styled from "styled-components";
import AppContext from "../../../../context/store";

import { Grid, Column } from "../../../../theme/Grid";
import Button from "../../../../theme/Button";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddBox,
  ShoppingBasket,
  AllInbox,
  Search,
} from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../../../assets/images/loader.gif";
import LoaderEN from "../../../../assets/images/circular.gif";
import LoaderSM from "../../../../assets/images/loaderSM.gif";
import { flushSync } from "react-dom";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import StaffSelect from "../../../../theme/CustomMUI/StaffSelect";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PackageTable from "../../../../theme/PackageTable";
import Waiting from "../../../../assets/images/svg/waiting-receipt.svg";
import HomeChcek from "../../../../assets/images/svg/home-check-receipt.svg";
import NotCome from "../../../../assets/images/svg/notCome-receipt.svg";


const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

// Paket özetinin gösterildiği alan.

export const _PackageSummaryBuilder = ({
  is_package,
  packageSummaryGenerator
}) => {
  const context = useContext(AppContext);

  return (
    is_package && (
      <SectionBox style={{ overflow: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(140,15,180, 0.1)",
            color: "#8c0fb4",
            border: "1px solid #8c0fb4",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <AllInbox />
            <h3 style={{ marginLeft: "15px" }}>
              {context.t(`['receipt/detail/id'].packageInner.title`)}
            </h3>
          </div>
        </SectionHeading>
        <div
          style={{
            marginBottom: "auto",
            overflow: "scroll",
          }}
          className="no-scroll-bar"
        >
          <PackageTable
            headings={{
              "service.name": {
                label: context.t(
                  `['receipt/detail/id'].packageInner.headings.name`
                ),
              },
              total: {
                label: context.t(
                  `['receipt/detail/id'].packageInner.headings.count`
                ),
                rowStyleType: "primary",
              },
              completed: {
                label: context.t(
                  `['receipt/detail/id'].packageInner.headings.completed`
                ),
                rowStyleType: "success",
              },
              pending: {
                label: context.t(
                  `['receipt/detail/id'].packageInner.headings.pending`
                ),
                rowStyleType: "warn",
              },
            }}
            rows={packageSummaryGenerator()}
          />
        </div>
      </SectionBox>
    )
  );
};

// Paket satışı eklemek için açılan dialog.

export const _PackageSalesDialogBuilder = ({
  packages,
  setPackages,
  package_sale_modal,
  setPackageSaleModal,
  new_package,
  setNewPackage,
  total_loader,
  setTotalLoader,
  total,
  setTotal,
  selected_customer,
  __package,
  set__Package,
  staffs,
  services,
  save_button_disabled,
  setSaveButtonDisabled,
  paramsId,
  getSingleReceipt
}) => {
  const context = useContext(AppContext);
  let typingTimeout = null;

  const fieldChanger = (section, key, payload) => {
    setNewPackage({
      ...new_package,
      [section]: {
        ...new_package[section],
        [key]: {
          ...new_package[section][key],
          ...payload,
        },
      },
    });
    setTotal(_total().amount);
  };

  const _total = () => {
    return {
      amount: Object.keys(new_package.services)
        .filter((key) => new_package.services[key] !== undefined)
        .map((key) =>
          new_package.services[key].amount !== null
            ? new_package.services[key].amount *
            new_package.services[key].period
            : 0
        )
        .reduce((a, b) => a + b)
        .toFixed(2),

      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(new_package.services)
          .filter((key) => new_package.services[key] !== undefined)
          .map(
            (key) =>
            (SingleRatio[key] =
              new_package.services[key].amount !== null
                ? (new_package.services[key].amount *
                  new_package.services[key].period) /
                _total().amount
                : 0)
          );
        return SingleRatio;
      },
    };
  };

  const editTotal = () => {
    // console.log(_total().getRatioList());
    if (total !== 0 || total !== null) {
      let services = { ...new_package.services };
      [...Object.keys(_total().getRatioList())].map(
        (key) =>
        (services = {
          ...services,
          [key]: {
            ...new_package.services[key],
            amount: parseFloat(
              (
                (total * _total().getRatioList()[key]) /
                new_package.services[key].period
              ).toFixed(2)
            ),
          },
        })
      );

      setTotalLoader(false);
      setNewPackage({
        ...new_package,
        services,
      });
    }
  };

  const validatePackage = () => {
    let undefined_service_count = 0;
    Object.keys(new_package.services).filter(
      (key) =>
        new_package.services[key] !== undefined &&
        new_package.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(
        context.t(`['receipt/detail/id'].packageSale.servicePickToast`)
      );
      return false;
    }

    if (selected_customer === null) {
      toast.warning(
        context.t(`['receipt/detail/id'].packageSale.customerPickToast`)
      );
      return false;
    }

    return true;
  };

  return (
    <AlertDialog
      fullWidth
      maxWidth={__package ? "lg" : "sm"}
      title={context.t(`['receipt/detail/id'].packageSale.title`)}
      open={package_sale_modal}
      disableBackdropClick={false}
      closeHandler={() => setPackageSaleModal(false)}
    >
      <Select
        label={context.t(
          `['receipt/detail/id'].packageSale.packageNameInputLabel`
        )}
        items={[...packages]}
        selected={__package ? __package.id : null}
        labelKey="name"
        valueKey="id"
        returnFullObject
        handler={(_package) => {
          flushSync(() => {
            set__Package(_package);
            setNewPackage({
              services: {
                ...Object.assign(
                  ...(_package.packet_details
                    ? _package.packet_details.map((item, index) => ({
                      [(index + 1) *
                        (Object.keys(new_package.services).length + 1)]: {
                        service_id: item?.service?.id,
                        staff_id: context.state.user.id,
                        packet_id: item.packet_id,
                        amount: item.amount,
                        period: item.period,
                        period_between: 7,
                        period_start: moment().format("YYYY-MM-DD"),
                        process_type: 1,
                        name: _package.name,
                      },
                    }))
                    : {}),
                  {}
                ),
              }
            });
          });

          setTotal(_total.amount)
        }}
      />
      {Object.keys(new_package.services).filter(
        (key) => new_package.services[key] !== undefined
      ).length > 0 ? (
        Object.keys(new_package.services)
          .filter((key) => new_package.services[key] !== undefined)
          .map((item, index) => (
            <Grid key={index} className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                <Select
                  label={context.t(
                    `['receipt/detail/id'].packageSale.serviceInputLabel`
                  )}
                  items={staffs
                    .filter(
                      (staff) =>
                        staff.id === new_package.services[item].staff_id
                    )[0]
                    .services.map((service) => service.service)}
                  labelKey="name"
                  valueKey="id"
                  selected={
                    new_package.services[item].service_id
                      ? services.filter(
                        (ser) =>
                          ser.id === new_package.services[item].service_id
                      ).length
                        ? new_package.services[item].service_id
                        : "Silinmiş Hizmet"
                      : "Silinmiş Hizmet"
                  }
                  returnFullObject
                  handler={(service) => {
                    fieldChanger("services", item, {
                      service_id: parseInt(service.id),
                      amount: service.amount,
                    })
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                <StaffSelect
                  label={context.t(
                    `['receipt/detail/id'].packageSale.staffInputLabel`
                  )}
                  items={[...staffs]}
                  labelKey="full_name"
                  valueKey="id"
                  selected={new_package.services[item].staff_id ?? null}
                  handler={(staff_id) => {
                    fieldChanger("services", item, {
                      staff_id: parseInt(staff_id),
                    })
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                <DatePickerInput
                  label={context.t(
                    `['receipt/detail/id'].packageSale.startInputLabel`
                  )}
                  error={false}
                  value={
                    new Date(moment(new_package.services[item].period_start))
                  }
                  onChange={(period_start) =>
                    fieldChanger("services", item, {
                      period_start: moment(period_start).format("YYYY-MM-DD"),
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                <Select
                  label={context.t(
                    `['receipt/detail/id'].packageSale.periodInputLabel`
                  )}
                  items={[...new Array(50).keys()].map((i) => ({
                    value: i + 1,
                  }))}
                  labelKey="value"
                  valueKey="value"
                  selected={new_package.services[item].period ?? null}
                  handler={(period) =>
                    fieldChanger("services", item, {
                      period: parseInt(period),
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                <Select
                  label={context.t(
                    `['receipt/detail/id'].packageSale.periodBetweenInputLabel`
                  )}
                  items={[...new Array(30).keys()].map((i) => ({
                    value: i + 1,
                    label: `${i + 1} Gün`,
                  }))}
                  labelKey="label"
                  valueKey="value"
                  selected={new_package.services[item].period_between ?? null}
                  handler={(period_between) =>
                    fieldChanger("services", item, {
                      period_between: parseInt(period_between),
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                <MaskedInput
                  label={
                    context.t(
                      `['receipt/detail/id'].packageSale.periodAmountInputLabel`
                    ) +
                    (context.state.currency
                      ? " (" + context.state.currency + ")"
                      : "(₺)")
                  }
                  // mask="99,99"
                  maskChar=""
                  value={
                    new_package.services[item].amount
                      ? `${new_package.services[item].amount}`
                      : ""
                  }
                  onChange={(e) =>
                    fieldChanger("services", item, {
                      amount:
                        e.target.value && /[0-9,.]+/.test(e.target.value)
                          ? parseFloat(e.target.value)
                          : null,
                    })
                  }
                />
              </Column>
              <Column>
                <Button
                  icon="delete_forever"
                  iconSize={20}
                  backgroundColor="red-opacity"
                  textColor="red"
                  size="lg"
                  fullWidth
                  onClick={() => {
                    delete new_package.services[item];
                    setNewPackage({
                      ...new_package,
                      services: {
                        ...new_package.services,
                      },
                    });
                    setTotal(_total().amount);
                  }}
                />
              </Column>
            </Grid>
          ))
      ) : (
        <NoDataContainer style={{ height: "70%" }}>
          <img
            src={require("../../../../assets/images/manypixels/click_action.svg")}
            alt="Malesef bulunamadı!"
          />
          <p>
            {context.t(`['receipt/detail/id'].packageSale.noDataText`)}
          </p>
        </NoDataContainer>
      )}
      {Object.keys(new_package.services).filter(
        (key) => new_package.services[key] !== undefined
      ).length > 0 && (
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
              <Input
                label={
                  context.t(
                    `['receipt/detail/id'].packageSale.totalAmount`
                  ) +
                  (context.state.currency
                    ? " (" + context.state.currency + ")"
                    : "(₺)")
                }
                // type="number"
                value={total}
                onChange={(e) => {
                  setTotal(e.target.value && e.target.value !== "0" ? parseFloat(e.target.value) : 1);
                  setTotalLoader(true);
                }}
                InputProps={{
                  endAdornment: total_loader && (
                    <InputAdornment position="end" style={{ marginRight: 0 }}>
                      <img
                        src={
                          process.env.APP_NAME === "salon"
                            ? Loader
                            : process.env.APP_NAME === "en"
                              ? LoaderEN
                              : LoaderSM
                        }
                        width="100"
                        height="100"
                        alt="loading"
                      />
                    </InputAdornment>
                  ),
                  onKeyUp: (e) => {
                    const value = e.target.value;
                    clearTimeout(typingTimeout);
                    typingTimeout =
                      value.length > 0 && setTimeout(() => editTotal(), 1250);
                  },
                }}
              />
            </Column>

            <Column>
              <div style={{ opacity: 0 }}>
                <Button
                  icon="delete_forever"
                  iconSize={20}
                  backgroundColor="red-opacity"
                  textColor="red"
                  size="lg"
                  fullWidth
                />
              </div>
            </Column>
          </Grid>
        )}
      {Object.keys(new_package.services).filter(
        (key) => new_package.services[key] !== undefined
      ).length > 0 && (
          <Button
            className="mt-2 mb-1"
            icon="local_mall"
            title={context.t(
              `['receipt/detail/id'].packageSale.completeButtonTitle`
            )}
            backgroundColor="teal-opacity"
            textColor="teal"
            disabled={save_button_disabled}
            size="md"
            fullWidth
            onClick={() => {
              const newPacket = [];
              const CleanArray = Object.keys(new_package.services).map(
                (key) => new_package.services[key]
              );
              const staffs = [];
              CleanArray.map((item) => {
                if (
                  staffs.includes(`${item?.staff_id}${item?.packet_id}`) ===
                  false
                ) {
                  staffs.push(`${item?.staff_id}${item?.packet_id}`);
                  newPacket.push(item);
                }
              });
              setSaveButtonDisabled(true);

              const fPacket = newPacket.map((item) => ({
                ...item,
                amount: `${CleanArray.filter(
                  (el) =>
                    el.staff_id === item.staff_id &&
                    el.packet_id === item.packet_id
                )
                  .map((el) => el.amount * el.period)
                  .reduce((a, b) => a + b, 0)}`,
              }));

              validatePackage() &&
                Axios.post(
                  `${context.api_endpoint}/company/packet/to/receipt`,
                  {
                    receipt_id: parseInt(paramsId),
                    process_date:
                      new_package.services[1].period_start,
                    services: [],
                    packets: fPacket
                      .filter((item) => item !== undefined)
                      .map((data_packet) => {
                        return {
                          process_date: data_packet?.period_start,
                          staff_id: data_packet?.staff_id,
                          amount: parseInt(data_packet?.amount),
                          packet_id: data_packet?.packet_id,
                          note: data_packet?.note ?? "",
                          name: data_packet?.name,
                          services: [
                            ...Object.keys(new_package.services)
                              .filter(
                                (key) =>
                                  new_package.services[key] !== undefined &&
                                  new_package.services[key].staff_id ===
                                  data_packet?.staff_id &&
                                  new_package.services[key].packet_id ===
                                  data_packet?.packet_id
                              )
                              .map((item) =>
                                Object.assign(
                                  {
                                    service_id:
                                      new_package?.services[item]?.service_id,
                                    amount: new_package?.services[item]?.amount,
                                    period: new_package?.services[item]?.period,
                                    period_between:
                                      new_package?.services[item]
                                        ?.period_between,
                                    period_start:
                                      new_package?.services[item]?.period_start,
                                    process_type: 1,
                                  },
                                  {}
                                )
                              ),
                          ],
                        };
                      }),
                  }
                )
                  .then((response) => {
                    const {
                      data: { data },
                    } = response;
                    if (response.status === 201) {
                      getSingleReceipt();
                      setSaveButtonDisabled(false);
                      setPackageSaleModal(false);
                      setNewPackage({
                        services: {}
                      });
                      set__Package(null);
                      toast.success(
                        context.t(
                          `receipt/detail/id.packageSale.packageSaleSuccess`
                        )
                      );
                    }
                  })
                  .catch((e) => {
                    if (e.response.status === 401) {
                      toast.error(e.response.data.message);
                    }
                  });
            }}
          />
        )}
    </AlertDialog>
  );
};

/**
 * Paket satışının yapıldığı, verilerin düzenlendiği alan.
 */
export const _PackageSalesBuilder = ({
  checkout_data,
  setCheckoutData,
  packageChanges,
  setPackageChanges,
  getTotalAmounts,
  setPackageDetailDialogModal,
  setPackageSaleModal,
  setSelectedPackageUpdateDate,
  setSelectedPackageTransitionId,
  selected_package_transition_update_state,
  setSelectedPackageTransitionUpdateState,
  setServicesUpdateDateModal,
  services_update_date_modal,
  services_update_modal,
  setServicesUpdateModal,
  setPackageUpdateDate,
  packet_detail_array1,
  setPacketDetailArray1,
  staffs,
  services,
  customer_s,
  setIsChange,
  is_package,
  setCheckoutDataDialog,
  setSelectedTime,
  setSelectedDate,
  setSelectedService,
  setSelectedStaff,
  setDiscountTarget,
  setDiscountType,
  setDiscountValue,
  setDiscountModal,
  setTransictionsToState,
  setTransictionsToDate,
  package_delete_modal,
  setPackageDeleteModal,
  last_services_from_package_to_delete_modal,
  setLastServicesFromPackageToDeleteModal,
  delete_package_id,
  setDeletedPackages,
  setDeletedPackagesServicesTransactionPackageId,
}) => {
  const context = useContext(AppContext);

  let total_mount = 0;
  const rounded_amount = getTotalAmounts().packages;

  let amountData = Object.keys(checkout_data.packages).map((key) =>
    checkout_data.packages[key] !== undefined
      ? checkout_data.packages[key].amount !== NaN
        ? checkout_data.packages[key].amount
        : 0
      : 0
  );

  for (let i = 0; i < amountData.length - 1; i++) {
    total_mount += amountData[i];
  }

  for (let j = 0; j < amountData.length; j++) {
    amountData[j] = amountData[j];
    if (amountData[amountData.length - 1]) {
      amountData[amountData.length - 1] = rounded_amount - total_mount;
    }
  }

  const inputRef = useRef();
  return (
    <SectionBox style={{ overflowX: "hidden" }}>
      <SectionHeading
        style={{
          backgroundColor: "rgba(140,15,180, 0.1)",
          color: "#8c0fb4",
          border: "1px solid #8c0fb4",
          padding: "10px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div className="detail-search" style={{ backgroundColor: "#8c0fb4" }}>
          <Search
            style={{ color: "white", fontSize: "20px" }}
            onClick={() => setPackageDetailDialogModal(true)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ShoppingBasket />
          <h3 style={{ marginLeft: "15px" }}>
            {context.t(
              `['receipt/detail/id'].serviceTransactions.packageTitle`
            )}
          </h3>
        </div>
        <div>
          <AddBox
            style={{ cursor: "pointer", fontSize: "36px" }}
            onClick={() => setPackageSaleModal(true)}
          />
        </div>
      </SectionHeading>

      <ListSection className="no-scroll-bar">
        {checkout_data.groups
          .filter((el) => el !== undefined)
          .map((data, indexGroup) => (
            <ReportsContainer
              key={indexGroup}
              style={{
                padding: "1.5em 0 0 0",
                transition: "all 0.3s ease",
                height: "auto",
              }}
            >
              <Column
                className="sx-12 sm-12 md-12 lg-12 group_header"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <MuiThemeProvider theme={themeForDatePicker}>
                    <DatePickerInput
                      className="mb-1"
                      label={context.t(
                        `['receipt/detail/id'].serviceTransactions.packageDateInputLabel`
                      )}
                      error={false}
                      value={data.process_date}
                      onChange={(date) => {
                        setSelectedPackageUpdateDate(moment(date).format("YYYY-MM-DD"));
                        setSelectedPackageTransitionId(data.id)
                        setServicesUpdateDateModal(true);
                        setPackageUpdateDate(moment(date).diff(data.process_date, "days"));
                      }}
                    />
                  </MuiThemeProvider>
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <ReportsHeader
                    onClick={() => {
                      if (
                        packet_detail_array1.includes(indexGroup)
                      ) {
                        setPacketDetailArray1(packet_detail_array1.filter((index) => index !== indexGroup));
                      } else {
                        setPacketDetailArray1([...packet_detail_array1, indexGroup]);

                      }
                    }}
                  >
                    <p
                      style={{ color: data.is_passive ? "red" : "black" }}
                    >{`${data.package_name}`}</p>
                    <p style={{ color: data.is_passive ? "red" : "black" }}>
                      &nbsp;
                      {`(${Object.keys(checkout_data.packages)
                        .filter(
                          (key) => checkout_data.packages[key] !== undefined
                        )
                        .filter(
                          (key) =>
                            checkout_data.packages[key]
                              .receipt_package_id === data.id
                        ).length
                        })`}
                    </p>
                  </ReportsHeader>
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <StaffSelect
                    className="mb-1"
                    disabled={context.state.user.permission === 0}
                    label={context.t(
                      `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                    )}
                    items={staffs}
                    selected={data.staff_id ? data.staff_id : ""}
                    labelKey="full_name"
                    valueKey="id"
                    handler={(staff_id) => {
                      setCheckoutData({
                        ...checkout_data,
                        groups: [
                          ...checkout_data.groups.map((item) =>
                            item.id === data.id
                              ? { ...item, staff_id: parseInt(staff_id) }
                              : { ...item }
                          ),
                        ],
                        groupsAmount: Object.assign(
                          {},
                          Object.values(
                            checkout_data.groupsAmount
                          ).map((item, index) =>
                            item.id === data.id
                              ? {
                                ...item,
                                staff_id: parseInt(staff_id),
                              }
                              : { ...item }
                          )
                        ),
                        packages: Object.assign({}, [
                          ...Object.values(
                            checkout_data.packages
                          ).filter(
                            (item) => item.receipt_package_id !== data.id
                          ),
                          ...Object.values(
                            checkout_data.packages
                          )
                            .filter(
                              (item) => item.receipt_package_id === data.id
                            )
                            .map((item, key) => ({
                              ...item,
                              staff_id: parseInt(staff_id),
                            })),
                        ]),
                      });
                      setPackageChanges(true);
                    }}
                  />
                </Column>

                <Column
                  className="xs-12 sm-12 md-12 lg-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <InfoCard
                    style={{
                      backgroundColor: "rgba(249,186,132, 0.21)",
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                    }}
                    onClick={async () => {
                      await setSelectedPackageTransitionId(data.id);
                      await setSelectedPackageTransitionUpdateState(2);
                      await setServicesUpdateModal(true);
                    }}
                  >
                    <InfoCardText>
                      <span>
                        {
                          Object.keys(checkout_data.packages)
                            .filter(
                              (key) =>
                                checkout_data.packages[key] !== undefined
                            )
                            .filter(
                              (key) =>
                                checkout_data.packages[key]
                                  .receipt_package_id === data.id &&
                                checkout_data.packages[key].customer_state ===
                                2
                            ).length
                        }
                      </span>
                      <img src={Waiting} alt="waiting" />
                    </InfoCardText>
                  </InfoCard>

                  <InfoCard
                    style={{
                      backgroundColor: "rgb(29, 139, 62, 0.21)",
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                    }}
                    onClick={async () => {
                      await setSelectedPackageTransitionId(data.id);
                      await setSelectedPackageTransitionUpdateState(0);
                      await setServicesUpdateModal(true);
                    }}
                  >
                    <InfoCardText>
                      <span>
                        {
                          Object.keys(checkout_data.packages)
                            .filter(
                              (key) =>
                                checkout_data.packages[key] !== undefined
                            )
                            .filter(
                              (key) =>
                                checkout_data.packages[key]
                                  .receipt_package_id === data.id &&
                                checkout_data.packages[key].customer_state ===
                                0
                            ).length
                        }
                      </span>
                      <img src={HomeChcek} alt="home-check" />
                    </InfoCardText>
                  </InfoCard>

                  <InfoCard
                    style={{
                      backgroundColor: "rgb(254, 151, 151, 0.19)",
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                    }}
                    onClick={async () => {
                      await setSelectedPackageTransitionId(data.id);
                      await setSelectedPackageTransitionUpdateState(1);
                      await setServicesUpdateModal(true);
                    }}
                  >
                    <InfoCardText>
                      <span>
                        {
                          Object.keys(checkout_data.packages)
                            .filter(
                              (key) =>
                                checkout_data.packages[key] !== undefined
                            )
                            .filter(
                              (key) =>
                                checkout_data.packages[key]
                                  .receipt_package_id === data.id &&
                                checkout_data.packages[key].customer_state ===
                                1
                            ).length
                        }
                      </span>
                      <img src={NotCome} alt="note-come" />
                    </InfoCardText>
                  </InfoCard>
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <MaskedInput
                    className="mb-1"
                    label={
                      context.t(
                        `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                      ) +
                      (context.state.currency
                        ? " (" + context.state.currency + ")"
                        : "(₺)")
                    }
                    // context.state.currency ? context.state.currency : "₺"
                    mask="9999999999999"
                    maskChar=""
                    value={Math.round(
                      checkout_data.groupsAmount[indexGroup].amount !== null
                        ? checkout_data.groupsAmount[indexGroup].amount
                        : ""
                    )}
                    onChange={(e) => {
                      let dividedBy = Object.values(
                        checkout_data.packages
                      ).filter(
                        (el) => el.receipt_package_id === data.id
                      ).length;
                      setCheckoutData({
                        ...checkout_data,
                        groupsAmount: {
                          ...checkout_data.groupsAmount,
                          [indexGroup]: {
                            ...checkout_data.groupsAmount[indexGroup],
                            amount: /[0-9,.]+/.test(e.target.value)
                              ? Math.round(parseFloat(e.target.value))
                              : null,
                          },
                        },
                        groups: checkout_data.groups.map((item) =>
                          item.id !== checkout_data.groups[indexGroup].id
                            ? item
                            : {
                              ...item,
                              amount: /[0-9,.]+/.test(e.target.value)
                                ? Math.round(parseFloat(e.target.value))
                                : null,
                            }
                        ),
                        packages: {
                          ...Object.assign({}, [
                            ...Object.values(
                              checkout_data.packages
                            )
                              .filter(
                                (el) => el.receipt_package_id === data.id
                              )
                              .map((item) => ({
                                ...item,
                                amount: e.target.value / dividedBy,
                              })),
                            ...Object.values(
                              checkout_data.packages
                            ).filter(
                              (el) => el.receipt_package_id !== data.id
                            ),
                          ]),
                        },
                      });
                    }}
                  />
                </Column>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (
                      packet_detail_array1.includes(indexGroup)
                    ) {
                      setPacketDetailArray1(packet_detail_array1.filter(
                        (index) => index !== indexGroup
                      ))
                    } else {
                      setPacketDetailArray1([
                        ...packet_detail_array1,
                        indexGroup,
                      ])
                    }
                  }}
                >
                  {packet_detail_array1.includes(indexGroup) ? (
                    <ExpandLessIcon color="secondary" />
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )}
                </div>
              </Column>

              {packet_detail_array1.includes(indexGroup) && (
                <Column className="xs-12 sm-12 md-12 lg-12 mx-2">
                  {
                    //Paket Listesi
                    Object.keys(checkout_data.packages).filter(
                      (key) => checkout_data.packages[key] !== undefined
                    ).length > 0 ? (
                      Object.keys(checkout_data.packages)
                        .filter(
                          (key) =>
                            checkout_data.packages[key]
                              .transaction_package_id ===
                            data.transaction_package_id &&
                            checkout_data.packages[key].receipt_package_id ===
                            data.id
                        )
                        .map(
                          (key, indexSession) =>
                            checkout_data.packages[key].process_type ===
                            1 && (
                              <Grid
                                className="no-gutters-row"
                                key={indexSession}
                              >
                                {checkout_data.packages[key].process_date &&
                                  is_package && (
                                    <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                                      <MuiThemeProvider
                                        theme={themeForDatePicker}
                                      >
                                        <DatePickerInput
                                          className="mb-1"
                                          label={`${context.t(
                                            `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                          )} / ${indexSession + 1} Seans`}
                                          error={false}
                                          value={
                                            moment(
                                              checkout_data.packages[key]
                                                .process_date
                                            ).format("YYYY-MM-DD") ===
                                              "0001-01-01"
                                              ? null
                                              : moment(
                                                checkout_data.packages[key]
                                                  .process_date
                                              )
                                          }
                                          // disablePast
                                          onChange={(process_date) => {
                                            setCheckoutData({
                                              ...checkout_data,
                                              packages: {
                                                ...checkout_data.packages,
                                                [key]: {
                                                  ...checkout_data.packages[
                                                  key
                                                  ],
                                                  process_date:
                                                    moment(
                                                      process_date
                                                    ).format("YYYY-MM-DD"),
                                                },
                                              },
                                            });
                                            setPackageChanges(true);
                                          }}
                                        />
                                      </MuiThemeProvider>
                                    </Column>
                                  )}
                                <Column className="xs-12 sm-12 md-12 lg-4">
                                  <Select
                                    className="mb-1"
                                    label={context.t(
                                      `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                                    )}
                                    title={
                                      services.filter(
                                        (item) =>
                                          item.id ===
                                          checkout_data.packages[key]
                                            .service_id
                                      ).length > 0
                                        ? services.filter(
                                          (item) =>
                                            item.id ===
                                            checkout_data.packages[key]
                                              .service_id
                                        )[0].name
                                        : context.t(`["receipt/detail/id"].deletedService`)
                                    }
                                    items={staffs
                                      .filter(
                                        (staff) =>
                                          staff.id ===
                                          checkout_data.packages[key].staff_id
                                      )[0]
                                      .services.map(
                                        (service) => service?.service
                                      )}
                                    selected={
                                      checkout_data.packages[key].service_id
                                        ? services.filter(
                                          (item) =>
                                            item.id ===
                                            checkout_data.packages[key]
                                              .service_id
                                        ).length
                                          ? checkout_data.packages[key]
                                            .service_id
                                          : context.t(`["receipt/detail/id"].deletedService`)
                                        : context.t(`["receipt/detail/id"].deletedService`)
                                    }
                                    labelKey="name"
                                    valueKey="id"
                                    handler={(service_id) => {
                                      setCheckoutData({
                                        ...checkout_data,
                                        packages: {
                                          ...checkout_data.packages,
                                          [key]: {
                                            ...checkout_data.packages[key],
                                            service_id:
                                              parseInt(service_id),
                                          },
                                        },
                                      });
                                      setPackageChanges(true);
                                    }}
                                  />
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-3">
                                  <StaffSelect
                                    className="mb-1"
                                    disabled={
                                      context.state.user.permission === 0
                                    }
                                    label={context.t(
                                      `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                                    )}
                                    items={staffs}
                                    selected={
                                      checkout_data.packages[key].staff_id
                                        ? checkout_data.packages[key].staff_id
                                        : ""
                                    }
                                    labelKey="full_name"
                                    valueKey="id"
                                    handler={(staff_id) => {
                                      setCheckoutData({
                                        ...checkout_data,
                                        packages: {
                                          ...checkout_data.packages,
                                          [key]: {
                                            ...checkout_data.packages[key],
                                            staff_id: parseInt(staff_id),
                                          },
                                        },
                                      });
                                      setPackageChanges(true);
                                    }}
                                  />
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-2">
                                  <Select
                                    className="mb-1"
                                    disabled={
                                      context.state.user.permission === 0
                                    }
                                    label={context.t(`sms.bottom_table_titles.status`)}
                                    items={customer_s}
                                    selected={
                                      checkout_data.packages[key]
                                        .customer_state !== null
                                        ? `${checkout_data.packages[key].customer_state}`
                                        : ""
                                    }
                                    labelKey="label"
                                    valueKey="value"
                                    handler={(customer_state) => {
                                      setCheckoutData({
                                        ...checkout_data,
                                        packages: {
                                          ...checkout_data.packages,
                                          [key]: {
                                            ...checkout_data.packages[key],
                                            customer_state:
                                              parseInt(customer_state),
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </Column>

                                {checkout_data.packages[key].process_date &&
                                  is_package && (
                                    <Column className="xs-12 sm-12 md-12 lg-1 mt-1">
                                      <Button
                                        icon="event"
                                        iconSize={21}
                                        textColor="blue"
                                        size="lg"
                                        fullWidth
                                        onClick={() => {
                                          if (packageChanges) {
                                            toast.warning(
                                              context.t(`["receipt/detail/id"].saveBeforeGetAppointmentWarnToast`)
                                            );
                                          } else {
                                            setCheckoutDataDialog(true);
                                            setSelectedTime(moment().hour() + ":" + moment().minutes());
                                            setSelectedDate(
                                              new Date(
                                                checkout_data.packages[
                                                  key
                                                ].process_date
                                              ).toISOString()
                                            );
                                            setSelectedService(checkout_data.packages[key].service_id);
                                            setSelectedStaff(checkout_data.packages[key].staff_id);
                                          }
                                        }}
                                      />
                                    </Column>
                                  )}
                              </Grid>
                            )
                        )
                    ) : (
                      <NoDataContainer style={{ height: "70%" }}>
                        <img
                          src={require("../../../../assets/images/manypixels/click_action.svg")}
                          alt="Malesef bulunamadı!"
                        />
                        <p>
                          {context.t(
                            `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                          )}
                        </p>
                      </NoDataContainer>
                    )
                  }
                </Column>
              )}
            </ReportsContainer>
          ))}

        {checkout_data.groups.length === 0 ? (
          <NoDataContainer style={{ height: "80%" }}>
            <img
              src={require("../../../../assets/images/no-data-img.png")}
              alt="Malesef bulunamadı!"
            />
            <p>
              {context.t(
                `['receipt/detail/id'].serviceTransactions.noDataPackageText`
              )}
            </p>
          </NoDataContainer>
        ) : (
          <></>
        )}
      </ListSection>

      {Object.values(checkout_data.packages).length > 0 && (
        <SubTotalText className="mt-2">
          <CustomerBoxDiv>
            <Button
              style={{
                border: "1px solid rgba(255, 127, 80, 0.53)",
              }}
              disabled={
                Object.keys(checkout_data.packages).filter(
                  (key) => checkout_data.packages[key] !== undefined
                ).length === 0
              }
              className="mr-2"
              icon="emoji_symbols"
              title={context.t(
                `['receipt/detail/id'].applyDiscountButtonTitle`
              )}
              backgroundColor="orange-opacity"
              textColor="orange"
              onClick={() => {
                if (
                  Object.keys(checkout_data.paids).filter(
                    (key) => checkout_data.paids[key] !== undefined
                  ).length === 0 &&
                  Object.keys(checkout_data.installment).filter(
                    (key) => checkout_data.installment[key] !== undefined
                  ).length === 0
                ) {
                  setDiscountTarget("groupsAmount");
                  setDiscountValue(null);
                  setDiscountType("TL");
                  setDiscountModal(true);
                } else {
                  toast.warning(
                    context.t(
                      `['receipt/detail/id'].discountBeforePaymentToast`
                    )
                  );
                }
              }}
            />
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={RemainingDebtRed} alt="remaining_debt_red" />
              <CustomerPointBox>
                {getTotalAmounts().groupsAmount !==
                  getTotalAmounts()._fgroups ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{ textDecoration: "line-through" }}
                      className="fc-grey mr-1"
                    >
                      {Math.round(getTotalAmounts()._fgroups)}
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                    </p>
                    <p>
                      {Math.round(getTotalAmounts().groupsAmount)}
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                    </p>
                  </span>
                ) : (
                  <span>
                    <p>
                      {console.log(getTotalAmounts().groupsAmount.toFixed(0))}
                      {getTotalAmounts().groupsAmount.toFixed(0)}
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                    </p>
                  </span>
                )}
                <h3>
                  {context.t(
                    `['receipt/detail/id'].serviceTransactions.packageTotalAmount`
                  )}
                </h3>
              </CustomerPointBox>
            </div>
          </CustomerBoxDiv>
        </SubTotalText>
      )}
      <AlertDialog
        closeHandler={() => {
          setServicesUpdateModal(false);
        }}
        open={services_update_modal}
        title={context.t(`["receipt/detail/id"].packageSessionUpdateTitle`)}
        buttons={[
          {
            title: context.t(`receipts.detailButton.confirmButton`),
            backgroundColor: "primary-opacity",
            textColor: "primary",
            icon: "check",
            onClick: async () => {
              await setTransictionsToState();
            },
          },
          {
            title: context.t(`["packages/add"].discardButtonTitle`),
            outlined: true,
            textColor: "#fff",
            icon: "clear",
            onClick: () => {
              setSelectedPackageTransitionUpdateState(null);
              setSelectedPackageTransitionId(null)
            },
          },
        ]}
      >
        <p>
          {context.t(`["receipt/detail/id"].packageSessionUpdateText1`).replace("%s",
            selected_package_transition_update_state === 0
              ? context.t(`appointments.detail.appointmentStatus.arrived`)
              : selected_package_transition_update_state === 2
                ? context.t(`appointments.detail.appointmentStatus.waiting`)
                : context.t(`appointments.detail.appointmentStatus.notArrived`))}
          <br />
          <br />
          {context.t(`["receipt/detail/id"].packageSessionUpdateText2`)}
        </p>
      </AlertDialog>

      <AlertDialog
        closeHandler={() => {
          setServicesUpdateDateModal(false);
        }}
        open={services_update_date_modal}
        title={context.t(`["receipt/detail/id"].packageDateUpdateTitle`)}
        buttons={[
          {
            title: context.t(`receipts.detailButton.confirmButton`),
            backgroundColor: "primary-opacity",
            textColor: "primary",
            icon: "check",
            onClick: async () => {
              await setTransictionsToDate();
            },
          },
          {
            title: context.t(`["packages/add"].discardButtonTitle`),
            outlined: true,
            textColor: "#fff",
            icon: "clear",
            onClick: () => {
              setPackageUpdateDate(null);
              setSelectedPackageTransitionId(null);
              setSelectedPackageUpdateDate(null);
            },
          },
        ]}
      >
        {context.t(`["receipt/detail/id"].packageDateUpdateText`)}
        <br />
        {context.t(`["receipt/detail/id"].packageSessionUpdateText2`)}
      </AlertDialog>

      <AlertDialog
        closeHandler={() => {
          setPackageDeleteModal(false);
        }}
        open={package_delete_modal}
        title={context.t(`["receipt/detail/id"].packageDeleteTitle`)}
        buttons={[
          {
            title: context.t(`receipts.detailButton.confirmButton`),
            backgroundColor: "primary-opacity",
            textColor: "primary",
            icon: "check",
            onClick: async () => {
              await setCheckoutData({
                ...checkout_data,
                packages: Object.assign(
                  {},
                  Object.values(checkout_data.packages).filter(
                    (item) =>
                      item.receipt_package_id !==
                      delete_package_id
                  )
                ),
                groups: checkout_data.groups.filter(
                  (item) => item.id !== delete_package_id
                ),
              });
            },
          },
          {
            title: context.t(`["packages/add"].discardButtonTitle`),
            outlined: true,
            textColor: "#fff",
            icon: "clear",
          },
        ]}
      >
        {context.t(`["receipt/detail/id"].packageDeleteText1`)}
        <br />
        {context.t(`["receipt/detail/id"].packageSessionUpdateText2`)}
      </AlertDialog>

      <AlertDialog
        closeHandler={() => {
          setLastServicesFromPackageToDeleteModal(false);
        }}
        open={last_services_from_package_to_delete_modal}
        title={context.t(`["receipt/detail/id"].packageDeleteTitle`)}
        buttons={[
          {
            title: context.t(`receipts.detailButton.confirmButton`),
            backgroundColor: "primary-opacity",
            textColor: "primary",
            icon: "check",
            onClick: async () => {
              // delete checkout_data.packages[
              //   key_for_delete_services
              // ];
              await setCheckoutData({
                ...checkout_data,
                groups: checkout_data.groups.filter(
                  (item) => item.id !== delete_package_id
                ),
                packages: Object.assign(
                  {},
                  Object.values(checkout_data.packages).filter(
                    (item) =>
                      item.receipt_package_id !==
                      delete_package_id
                  )
                ),
              });
              await setCheckoutData({
                ...checkout_data,
              });
              await setIsChange(true);
              // console.log("checkoutdata:", checkout_data);

              setTimeout(() => {
                setDeletedPackages([]);
                setDeletedPackagesServicesTransactionPackageId(null);
              }, 250);
            },
          },
          {
            title: context.t(`["packages/add"].discardButtonTitle`),
            outlined: true,
            textColor: "#fff",
            icon: "clear",
            onClick: () => { },
          },
        ]}
      >
        {context.t(`["receipt/detail/id"].packageDeleteText2`)}
        <br />
        {context.t(`["receipt/detail/id"].packageSessionUpdateText2`)}
      </AlertDialog>
    </SectionBox>
  );
};


const InfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 2px 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const InfoCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const ReportsHeader = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const ReportsHeaderIcon = styled.span`
  padding-right: 10px;
`;
const ReportsContainer = styled.div`
  border-bottom: 1px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  flex-direction: column;
  overflow: hidden;
`;
const SectionBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  padding-top: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: var(--dark-primary);

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const PaymentSection = styled.div`
  width: 95%;
  height: 57px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;

const SubTotalText = styled.div`
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const CustomerBox = styled.div``;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 57px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 5px;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;

const SelectedBoxPhoto = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 5px;
  background-color: #fafafa;
  margin-right: 16px;
`;

const CustomerBoxInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex - direction: column;
    div, img {margin: 0 !important}
  }

  div{
    b, span {margin: 0; padding: 0; display: block;}
    b{
      font - size: 15px; font-weight: 500
    }

    &.call_button{
      margin - left: 24px;
      flex: 1
      display:flex;
      justify-content: flex-end;
    }
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const AdvancedType = styled.div`
  display: flex;
  width: 190px;
  height: 88px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const ReceiptProfile = styled.div`
  display: flex;
  flex-direction: column;
  height: 57px;
  width: 100%;
  border-radius: 5px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    padding: 2px;
  }
`;

const SaveChangesButton = styled.div`
  position: fixed;
  top: 75px;
  right: 4vw;
  z-index: 999;
  width: 20vw;
`;

const ServicesSectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--dark-primary);
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-bottom:16px;

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const StaffList = styled.div`
  height: 26vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  margin: 0;
`;

const ServicesList = styled.div`
  height: 22.5vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  overflow: scroll;
`;

const Services = styled.div`
  height: 63vh;
  border-radius: 10px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageDetailContainer = styled.div`
  flex-direction: column;
  overflow: hidden;
`;
