import React, { useContext } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { BiSolidQuoteRight } from "react-icons/bi";
import { MdOutlineWeb } from "react-icons/md";
import { GoStar } from "react-icons/go";
import { MdSupportAgent } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import styles from "./userMostLike.module.css";
import vildanOkan from "../../../../assets/images/vildanOkan.png";
import oktayKopru from "../../../../assets/images/oktayKopru.png";
import tugbaBeauty from "../../../../assets/images/tugbaBeauty.png";
import AppContext from "../../../../context/store";

const Index = () => {
  const context = useContext(AppContext);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f1f6ff",
        paddingBottom: "100px",
      }}
    >
      <div className="container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "90%",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "30px",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#393e46",
              }}
            >
              <span
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                  color:
                    process.env.APP_NAME === "salon"
                      ? "rgb(213, 10, 88)"
                      : "var(--primary)",

                  fontFamily: "Mulish, sans-serif",
                }}
              >
                {context.t(`campaigns.sayAboutUs.title1`)}
              </span>{" "}
              {context.t(`campaigns.sayAboutUs.title2`)}
            </p>
            <Grid>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p style={{ width: "100%", textAlign: "center" }}>
                      <MdOutlineWeb
                        color={
                          process.env.APP_NAME === "salon"
                            ? "#7500FF"
                            : "var(--primary)"
                        }
                        size={36}
                      />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#d0217e",
                        fontWeight: "bolder",
                        background:
                          process.env.APP_NAME === "salon"
                            ? "linear-gradient(to right, #ed1e79 0%, #85298b 100%)"
                            : "var(--primary)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontSize: "15px",
                      }}
                    >
                      {context.t(`campaigns.sayAboutUs.title3`)}
                    </p>
                  </Column>
                </Grid>
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#d0217e",
                        fontWeight: "bolder",
                        fontSize: "28px",
                      }}
                    >
                      <GoStar
                        color={
                          process.env.APP_NAME === "salon"
                            ? "#7500FF"
                            : "var(--primary)"
                        }
                        size={36}
                      />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#d0217e",
                        fontWeight: "bolder",
                        background:
                          process.env.APP_NAME === "salon"
                            ? "linear-gradient(to right, #ed1e79 0%, #85298b 100%)"
                            : "var(--primary)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontSize: "15px",
                      }}
                    >
                      {context.t(`campaigns.sayAboutUs.title4`)}
                    </p>
                  </Column>
                </Grid>
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p style={{ width: "100%", textAlign: "center" }}>
                      <MdSupportAgent
                        color={
                          process.env.APP_NAME === "salon"
                            ? "#7500FF"
                            : "var(--primary)"
                        }
                        size={36}
                      />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#d0217e",
                        fontWeight: "bolder",
                        background:
                          process.env.APP_NAME === "salon"
                            ? "linear-gradient(to right, #ed1e79 0%, #85298b 100%)"
                            : "var(--primary)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontSize: "15px",
                      }}
                    >
                      {context.t(`campaigns.sayAboutUs.title5`)}
                    </p>
                  </Column>
                </Grid>
              </Column>
            </Grid>
          </div>
        </div>
        <div className={styles.content}>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6 mt-4">
              <Grid style={{ height: "450px" }}>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p>{context.t(`campaigns.sayAboutUs.title6`)}</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "40px",
                          }}
                          src={tugbaBeauty}
                        />
                        <div>
                          {" "}
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              marginTop: "40px",
                              color: "#0b3052",
                            }}
                          >
                            Tuğba Karaman Beauty Stüdio
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Tuğba K.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6 mt-4">
              <Grid style={{ height: "450px" }}>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p style={{ marginTop: "15px" }}>
                        {context.t(`campaigns.sayAboutUs.title7`)}
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            //marginTop: "20px",
                          }}
                          src={vildanOkan}
                        />
                        <div>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              color: "#0b3052",
                            }}
                          >
                            High Care Güzellik Merkezi
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Vildan Okan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p style={{ marginTop: "15px" }}>
                        {context.t(`campaigns.sayAboutUs.title8`)}
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "43px",
                            height: "40px",
                            // marginTop: "20px",
                          }}
                          src={oktayKopru}
                        />
                        <div>
                          {" "}
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "40px",
                              color: "#0b3052",
                            }}
                          >
                            Erciyes Binicilik
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Oktay Köprü
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </div>
        <div className={styles.smallScreenOnly}>
          {" "}
          <Grid style={{ marginTop: "50px" }}>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <Carousel
                additionalTransfrom={0}
                autoPlaySpeed={3000}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                arrows={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p>{context.t(`campaigns.sayAboutUs.title6`)}</p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={tugbaBeauty}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          Tuğba Karaman Beauty Stüdio
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Tuğba K.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",

                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {context.t(`campaigns.sayAboutUs.title7`)}
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={vildanOkan}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          High Care Güzellik Merkezi
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Vildan Okan
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {context.t(`campaigns.sayAboutUs.title8`)}
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "45px", height: "40px" }}
                          src={oktayKopru}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          Erciyes Binicilik
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Oktat Köprü
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </Column>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Index;

const ServicesGalleryContainer = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
