import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";

import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { Grid, Column } from "../../../theme/Grid";
import { Add } from "@material-ui/icons";
import AuthContainer from "../../../components/AuthContainer";
import Validate from "../../../functions/Validate";
import Axios from "axios";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";
import { getCountryCode } from "../../../functions/CountryCode";
import { getCurrencies } from "../../../functions/Currency";

const BranchAdd = ({ onSuccess }) => {
  const context = useContext(AppContext);
  const [form, setForm] = useState({ ...FORM_KEYS });
  const [dialCode, setDialCode] = useState(context.state.dialCode);
  const [currency, setCurrency] = useState(context.state.currency);

  const history = useHistory();

  const InputOnChange = async (key, sub_key, value) =>
    setForm({ ...form, [key]: { ...form[key], [sub_key]: value } });

  const insertDealer = () => {
    validateForm() &&
      Axios.post(`${context.api_endpoint}/company/dealer/insert`, { ...form })
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data.data);
            toast.success(context.t(`branches.newBranchSuccessToast`));
            history.push("/settings");
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const validateForm = () => {
    return Validate([
      {
        field: "İşletme Adı",
        value: form.dealer.name,
        condition: /\w+/,
      },
      {
        field: "Yetkili Adı",
        value: form.permitted.name,
        condition: /\w+/,
      },
      {
        field: "Yetkili Soyadı",
        value: form.permitted.surname,
        condition: /\w+/,
      },
      {
        field: "Telefon Numarası",
        value: form.dealer.phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: form.dealer.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
    ]);
  };

  return (
    <AuthContainer
      // authorities={[1]}
      // makePlanUpgrade={[2]}
      limited_permission="system"
    >
      <Grid>
        <Column className="xs-12 sm-12">
          <Input
            required
            label={context.t(`branches.createBranch.companyNameInputLabel`)}
            value={form.dealer.name}
            onChange={(e) => InputOnChange("dealer", "name", e.target.value)}
          />
          <Input
            required
            label={context.t(`branches.createBranch.staffNameInputLabel`)}
            value={form.permitted.name}
            onChange={(e) => InputOnChange("permitted", "name", e.target.value)}
          />
          <Input
            required
            label={context.t(`branches.createBranch.staffSurnameInputLabel`)}
            value={form.permitted.surname}
            onChange={(e) =>
              InputOnChange("permitted", "surname", e.target.value)
            }
          />
          <Input
            required
            label={context.t(`branches.createBranch.commonMailInputLabel`)}
            value={form.dealer.mail}
            onChange={(e) => {
              setForm({
                ...form,
                dealer: { ...form.dealer, mail: e.target.value },
                permitted: { ...form.permitted, mail: e.target.value },
              });
            }}
          />
          <MaskedInput
            required
            label={context.t(`branches.createBranch.commonPhoneInputLabel`)}
            maskChar={""}
            mask="999 999 9999"
            startAdornment="0"
            value={form.dealer.phone}
            placeholder="500 000 0000"
            onChange={(e) => {
              setForm({
                ...form,
                dealer: {
                  ...form.dealer,
                  phone:
                    e.target.value.substring(0, 1) === "5" &&
                    e.target.value.replace(/\s/g, ""),
                },
                permitted: {
                  ...form.permitted,
                  phone:
                    e.target.value.substring(0, 1) === "5" &&
                    e.target.value.replace(/\s/g, ""),
                },
              });
            }}
          />
          {process.env.APP_NAME === "management" ? (
            <>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <Select
                  dialCode={true}
                  label="Dial Code"
                  items={getCountryCode()}
                  selected={
                    getCountryCode().filter(
                      (curr) => curr.dial_code === dialCode
                    )[0].dial_code
                  }
                  labelKey="dial_code"
                  valueKey="dial_code"
                  handler={(item) => {
                    InputOnChange(
                      "dealer",
                      "dialCode",
                      getCountryCode().filter(
                        (curr) => curr.dial_code === item
                      )[0].dial_code
                    );
                    setDialCode(
                      getCountryCode().filter(
                        (curr) => curr.dial_code === item
                      )[0].dial_code
                    );
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <Select
                  label="Currency"
                  items={getCurrencies()}
                  selected={
                    getCurrencies().filter(
                      (curr) => curr.symbol === currency
                    )[0]?.code
                  }
                  labelKey="name"
                  valueKey="code"
                  handler={(item) => {
                    InputOnChange(
                      "dealer",
                      "dialCode",
                      getCurrencies().filter((curr) => curr.code === item)[0]
                        .symbol
                    );
                    setCurrency(
                      getCurrencies().filter((curr) => curr.code === item)[0]
                        .symbol
                    );
                  }}
                />
              </Column>
            </>
          ) : null}
          <Input
            required
            label={context.t(`branches.createBranch.passInputLabel`)}
            type="password"
            autoComplete={false}
            value={form.permitted.password}
            onChange={(e) =>
              InputOnChange("permitted", "password", e.target.value)
            }
          />

          <Button
            iconComponent={Add}
            title={context.t(`branches.createBranchButtonTitle`)}
            type="submit"
            size="md"
            fullWidth
            textColor="white"
            backgroundColor="primary"
            onClick={() => insertDealer()}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default BranchAdd;

const FORM_KEYS = {
  dealer: {
    name: null,
    province: null,
    district: null,
    phone: null,
    address: null,
    mail: null,
  },
  permitted: {
    name: null,
    surname: null,
    mail: null,
    phone: null,
    password: null,
  },
  code: null,
  campaign_code: null,
};
