import React, { useContext } from "react";
import AppContext from "../../context/store";

import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";
import { Notifications, Translate } from "@material-ui/icons";

const MenuIconButton = (props) => {
  const context = useContext(AppContext);

  const ButtonBaseStyle = {
    height: props.height ? props.height : "",
    padding: window.orientation !== undefined ? 8 : 14,
    lineHeight: 1,
    borderRadius: props.radius ?? 10,
    transition: "all ease 0.35s",
    boxShadow: props.radius ? "0 2px 4px rgba(0,0,0, 0.04)" : "",
  };

  if (props.badgecontent || props.hasOwnProperty("badgecontent")) {
    return (
      <ButtonBase {...props} icon={undefined} style={ButtonBaseStyle}>
        <Badge>
          <props.icon style={{ height: 20 }} />
          <span className="count">{props.badgecontent ?? 0}</span>
        </Badge>
        {props.icon === Notifications ? (
          props.badgecontent > 0 ? (
            <TextContainer>
              {context.t(`scaffold.notificationFound`)}
            </TextContainer>
          ) : null
        ) : null}
      </ButtonBase>
    );
  }

  if (props.icon === Translate) {
    return (
      <ButtonBase {...props} icon={undefined} style={ButtonBaseStyle}>
        <props.icon style={{ height: 20 }} />
        <TextContainer>{context.t(`langText`)}</TextContainer>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      {...props}
      icon={undefined}
      style={{ ...ButtonBaseStyle, ...props.style }}
    >
      <props.icon
        style={{
          height: props.iconHeight ?? 20,
          color: props.color ? props.color : null,
        }}
      />
      <span className="label">{props.label ?? ""}</span>
    </ButtonBase>
  );
};

export default React.memo(MenuIconButton);

const Badge = styled.div`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;

  span.count {
    height: 20px;
    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;

    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;

    background: rgba(255, 144, 22, 0.08) !important;
    color: var(--orange);
  }
`;

const TextContainer = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 8px;
  margin: 0;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
