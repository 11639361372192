/* eslint-disable */
import React, { useContext } from "react";
import styled from "styled-components";
import AppContext from "../../../../context/store";
import { Grid, Column } from "../../../../theme/Grid";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import Button from "../../../../theme/Button";
import Input from "../../../../theme/CustomMUI/Input";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddBox,
  AddShoppingCart,
  Search
} from "@material-ui/icons";
import { AiOutlineBarcode } from "react-icons/ai";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import StaffSelect from "../../../../theme/CustomMUI/StaffSelect";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";


const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

/**
 * Ürün satışını eklemek için açılan dialog.
 */
export const _ProductSalesDialogBuilder = ({
  product_transaction,
  setProductTransaction,
  selected_stock,
  setSelectedStock,
  product_transaction_modal,
  setProductTransactionModal,
  addSingleStockSaleIntoCheckout,
  openInput,
  setOpenInput,
  barcodeValue,
  setBarcodeValue,
  setStockItemValue,
  staffs,
  stock_items,
  stock_item_value,
  searchStockByKey,
  stockItemHandler
}) => {
  const context = useContext(AppContext);

  return (
    <AlertDialog
      containerStyle={{
        overflow: "visible",
      }}
      fullWidth
      maxWidth="sm"
      title={context.t(`['receipt/detail/id'].stockSale.title`)}
      open={product_transaction_modal}
      closeHandler={() => setProductTransactionModal(false)}
      buttons={[
        {
          title: context.t(
            `['receipt/detail/id'].stockSale.addButtonTitle`
          ),
          icon: "check",
          backgroundColor: "primary-opacity",
          textColor: "primary",
          onClick: () => {
            if (selected_stock.limit !== 0) {
              addSingleStockSaleIntoCheckout();
            }
            if (selected_stock.limit === 0) {
              toast.error(context.t(`["receipt/detail/id"].stockSaleErrorToast`));
            }
            setOpenInput(false);
            setBarcodeValue("");
            setStockItemValue(null);
          },
        },

        {
          title: context.t(
            `['receipt/detail/id'].stockSale.discardButtonTitle`
          ),
          icon: "close",
          textColor: "grey",
          marginLeft: openInput ? "50px" : "0px",
          onClick: () => {
            setOpenInput(false);
            setBarcodeValue("");
            setStockItemValue(null);
          },
        },
      ]}
    >
      <StaffSelect
        disabled={context.state.user.permission === 0}
        label={context.t(
          `['receipt/detail/id'].stockSale.staffInputLabel`
        )}
        items={staffs}
        selected={
          product_transaction.staff_id !== null
            ? product_transaction.staff_id
            : null
        }
        labelKey="full_name"
        valueKey="id"
        returnFullObject
        handler={(staff) => 
          setProductTransaction({
            ...product_transaction,
            staff_id: parseInt(staff.id),
          })}
      />

      <AutocompleteInput
        label={context.t(
          `['receipt/detail/id'].stockSale.stockInputLabel`
        )}
        showField="amount"
        extraLimit="limit"
        labelKey="name"
        opcStyle={{ maxHeight: "30vh" }}
        valueKey="id"
        selected={
          stock_items !== null ? stock_items : ""
        }
        value={
          stock_item_value !== null
            ? stock_item_value
            : null
        }
        onRemove={() => {
          searchStockByKey("");
        }}
        returnFullObject
        selectedHandler={(stock_item) => {
          setProductTransaction({
            ...product_transaction,
            stock_item_id: parseInt(stock_item.id),
            amount: stock_item.amount,
          });
          setSelectedStock(stock_item);
        }}
        asyncDataService={(keyword) =>
          keyword.length > 0 ? searchStockByKey(keyword) : []
        }
      />
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {!openInput ? (
          <button
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              border: "none",
              background: "rgb(49, 137, 236)",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background 0.3s, color 0.3s",
              disabled: true,
            }}
            onClick={() => {
              if (context.state?.balance?.data?.license?.barcode) {
                setOpenInput(true);
              } else {
                toast.warn(
                  context.t(
                    `['receipt/detail/id'].stockSales.barcodeCheckAlert`
                  )
                );
              }
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = "0.7";
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = "1";
            }}
          >
            {context.t(
              `['receipt/detail/id'].stockSales.selectWithBarcode`
            )}
            <AiOutlineBarcode
              color="black"
              size={"30px"}
              style={{ marginLeft: "15px" }}
            />
          </button>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                style={{
                  width: "300px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s",
                  flex: 1,
                }}
                placeholder={context.t(`["receipt/detail/id"].barcodeInputPlaceholder`)}
                type="text"
                value={barcodeValue ? barcodeValue : ""}
                onChange={(event) => {
                  setBarcodeValue(event.target.value);
                }}
              />
              <button
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",

                  background: [
                    barcodeValue === "" ? "#8db7e7 " : "#3189ec ",
                  ],
                  border: "none",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  barcodeValue
                    ? stockItemHandler()
                    : toast.warn(
                        context.t(
                          `['receipt/detail/id'].barcodeAlert.pleaseEnterACode`
                        )
                      );
                }}
                onMouseEnter={(e) => {
                  e.target.style.opacity = barcodeValue !== "" ? "0.8" : null;
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = barcodeValue !== "" ? "1" : null;
                }}
              >
                {context.t(
                  `['receipt/detail/id'].stockSales.selectStock`
                )}
              </button>
            </div>
            <span
              style={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={() => setOpenInput(false)}
            >
              &#10006;
            </span>
          </div>
        )}
      </div>

      {/* <Select
        label={context.t(
          `['receipt/detail/id'].stockSale.stockInputLabel`
        )}
        items={state.stock_items}
        selected={product_transaction.stock_item_id}
        labelKey="name"
        valueKey="id"
        returnFullObject
        handler={(stock_item) =>
          setState({
            product_transaction: {
              ...product_transaction,
              stock_item_id: parseInt(stock_item.id),
              amount: stock_item.amount,
            },
          })
        }
      /> */}
    </AlertDialog>
  );
};

//** */ Ürün satışının yapıldığı, verilerin düzenlendiği alan.

export const _ProductSalesBuilder = ({
  setProductDetailDialogModal,
  setProductTransaction,
  _product_transaction,
  setProductTransactionModal,
  checkout_data,
  setCheckoutData,
  stock_items,
  staffs,
  getTotalAmounts,
  setDiscountTarget,
  setDiscountType,
  setDiscountValue,
  setDiscountModal,
}) => {
  const context = useContext(AppContext);

  return (
    <SectionBox style={{ overflowX: "hidden" }}>
      <SectionHeading
        style={{
          backgroundColor: "rgba(49, 169, 243, 0.24)",
          color: "#3189ec",
          border: "1px solid #3189ec",
          padding: "10px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          className="detail-search"
          style={{
            backgroundColor: "#3189ec",
          }}
          onClick={() => {
            setProductDetailDialogModal(true);
          }}
        >
          <Search style={{ color: "white", fontSize: "20px" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <AddShoppingCart />
          <h3 style={{ marginLeft: "15px" }}>
            {context.t(`['receipt/detail/id'].stockSales.title`)}
          </h3>
        </div>
        <div>
          <AddBox
            style={{ cursor: "pointer", fontSize: "36px" }}
            onClick={() => {
              setProductTransaction({ ..._product_transaction })
              setProductTransactionModal(true);
            }}
          />
        </div>
      </SectionHeading>

      <ListSection className="no-scroll-bar">
        {Object.keys(checkout_data.sales).filter(
          (key) => checkout_data.sales[key] !== undefined
        ).length ? (
          Object.keys(checkout_data.sales).map(
            (key) =>
              checkout_data.sales[key] !== undefined && (
                <Grid className="no-gutters-row">
                  <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                    <MuiThemeProvider theme={themeForDatePicker}>
                      <DatePickerInput
                        className="mb-1"
                        label={context.t(
                          `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                        )}
                        error={false}
                        value={
                          moment(checkout_data.sales[key].sold_date).format(
                            "YYYY-MM-DD"
                          ) === "0001-01-01"
                            ? null
                            : moment(checkout_data.sales[key].sold_date)
                        }
                        //  disabled={true}
                        onChange={(process_date) => {
                          setCheckoutData({
                            ...checkout_data,
                            sales: {
                              ...checkout_data.sales,
                              [key]: {
                                ...checkout_data.sales[key],
                                sold_date:
                                  moment(process_date).toISOString(),
                              },
                            }
                          });
                        }}
                      />
                    </MuiThemeProvider>
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      className="mb-1"
                      readOnly
                      label={context.t(
                        `['receipt/detail/id'].stockSales.nameInputLabel`
                      )}
                      inputProps={{
                        style: {
                          color: `${
                            checkout_data.sales[key].stock_item?.is_passive
                              ? "red"
                              : "black"
                          }`,
                        },
                      }}
                      value={
                        checkout_data.sales[key].stock_item_id
                          ? stock_items.filter(
                              (item) =>
                                item.id ===
                                checkout_data.sales[key].stock_item_id
                            ).length
                            ? stock_items.filter(
                                (item) =>
                                  item.id ===
                                  checkout_data.sales[key].stock_item_id
                              )[0].name
                            : context.t(
                                `['receipt/detail/id'].stockSales.deletedName`
                              )
                          : context.t(
                              `['receipt/detail/id'].stockSales.deletedName`
                            )
                      }
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <StaffSelect
                      className="mb-2"
                      disabled={context.state.user.permission === 0}
                      label={context.t(
                        `['receipt/detail/id'].stockSales.staffInputLabel`
                      )}
                      items={staffs}
                      selected={
                        checkout_data.sales[key].staff_id
                          ? checkout_data.sales[key].staff_id
                          : null
                      }
                      labelKey="full_name"
                      valueKey="id"
                      handler={(staff_id) => {
                        setCheckoutData({
                          ...checkout_data,
                            sales: {
                              ...checkout_data.sales,
                              [key]: {
                                ...checkout_data.sales[key],
                                staff_id: parseInt(staff_id),
                              },
                            }
                        });
                      }}
                    />
                  </Column>

                  {/* INPUTCHECK */}
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <Input
                      className="mb-2"
                      label={context.t(
                        `['receipt/detail/id'].stockSales.countInputLabel`
                      )}
                      value={checkout_data.sales[key].quantity}
                      onChange={(e) => {
                        setCheckoutData({
                          ...checkout_data,
                            sales: {
                              ...checkout_data.sales,
                              [key]: {
                                ...checkout_data.sales[key],
                                quantity:
                                  e.target.value &&
                                  /[0-9]+/.test(e.target.value)
                                    ? parseInt(e.target.value)
                                    : 0,
                              },
                            }
                        });
                      }}
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <Input
                      readOnly
                      className="mb-2"
                      type="number"
                      label={
                        context.t(
                          `['receipt/detail/id'].stockSales.amountInputLabel`
                        ) +
                        (context.state.currency
                          ? " (" + context.state.currency + ")"
                          : "(₺)")
                      }
                      value={checkout_data.sales[key].amount}
                      onChange={(e) => {
                        setCheckoutData({
                          ...checkout_data,
                            sales: {
                              ...checkout_data.sales,
                              [key]: {
                                ...checkout_data.sales[key],
                                amount:
                                  e.target.value &&
                                  /[0-9,.]+/.test(e.target.value)
                                    ? parseFloat(e.target.value)
                                    : 0,
                              },
                            }
                        });
                      }}
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-1">
                    <Button
                      icon="delete_forever"
                      iconSize={21}
                      textColor="red"
                      size="lg"
                      fullWidth
                      onClick={() => {
                        setCheckoutData({
                          ...checkout_data,
                          sales: {
                            ...checkout_data.sales,
                            [key]: undefined,
                          },
                        });
                      }}
                    />
                  </Column>
                </Grid>
              )
          )
        ) : (
          <NoDataContainer style={{ height: "80%" }}>
            <img
              src={require("../../../../assets/images/no-data-img.png")}
              alt="Malesef bulunamadı!"
            />
            <p>
              {context.t(`['receipt/detail/id'].stockSales.noDataText`)}
            </p>
          </NoDataContainer>
        )}
      </ListSection>

      {Object.keys(checkout_data.sales).filter(
        (key) => checkout_data.sales[key] !== undefined
      ).length > 0 && (
        <SubTotalText className="mt-2">
          <CustomerBoxDiv>
            <Button
              style={{
                border: "1px solid rgba(255, 127, 80, 0.53)",
              }}
              disabled={
                Object.keys(checkout_data.sales).filter(
                  (key) => checkout_data.sales[key] !== undefined
                ).length === 0
              }
              className="mr-2"
              icon="emoji_symbols"
              title={context.t(
                `['receipt/detail/id'].applyDiscountButtonTitle`
              )}
              backgroundColor="orange-opacity"
              textColor="orange"
              onClick={() => {
                if (
                  Object.keys(checkout_data.paids).filter(
                    (key) => checkout_data.paids[key] !== undefined
                  ).length === 0 &&
                  Object.keys(checkout_data.installment).filter(
                    (key) => checkout_data.installment[key] !== undefined
                  ).length === 0
                ) {
                  setDiscountTarget("sales");
                  setDiscountValue(null);
                  setDiscountType("TL");
                  setDiscountModal(true);
                } else {
                  toast.warning(
                    context.t(
                      `['receipt/detail/id'].discountBeforePaymentToast`
                    )
                  );
                }
              }}
            />

            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={RemainingDebtRed} alt="remaining_debt_red" />
              <CustomerPointBox>
                {getTotalAmounts().sales !=
                getTotalAmounts()._fsales ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{ textDecoration: "line-through" }}
                      className="fc-grey mr-1"
                    >
                      {getTotalAmounts()._fsales.toFixed(0)}
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                    </p>
                    <p>
                      {getTotalAmounts().sales.toFixed(0)}
                      {context.state.currency
                        ? context.state.currency
                        : "₺"}
                    </p>
                  </span>
                ) : (
                  <p>
                    {getTotalAmounts().sales.toFixed(0)}
                    {context.state.currency
                      ? context.state.currency
                      : "₺"}
                  </p>
                )}
                <h3>
                  {context.t(
                    `['receipt/detail/id'].stockSales.totalAmount`
                  )}
                </h3>
              </CustomerPointBox>
            </div>
          </CustomerBoxDiv>
        </SubTotalText>
      )}
    </SectionBox>
  );
};

const InfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 2px 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const InfoCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const ReportsHeader = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const ReportsHeaderIcon = styled.span`
  padding-right: 10px;
`;
const ReportsContainer = styled.div`
  border-bottom: 1px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  flex-direction: column;
  overflow: hidden;
`;
const SectionBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  padding-top: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: var(--dark-primary);

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const PaymentSection = styled.div`
  width: 95%;
  height: 57px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;

const SubTotalText = styled.div`
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const CustomerBox = styled.div``;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 57px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 5px;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;

const SelectedBoxPhoto = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 5px;
  background-color: #fafafa;
  margin-right: 16px;
`;

const CustomerBoxInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex - direction: column;
    div, img {margin: 0 !important}
  }

  div{
    b, span {margin: 0; padding: 0; display: block;}
    b{
      font - size: 15px; font-weight: 500
    }

    &.call_button{
      margin - left: 24px;
      flex: 1
      display:flex;
      justify-content: flex-end;
    }
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const AdvancedType = styled.div`
  display: flex;
  width: 190px;
  height: 88px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const ReceiptProfile = styled.div`
  display: flex;
  flex-direction: column;
  height: 57px;
  width: 100%;
  border-radius: 5px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    padding: 2px;
  }
`;

const SaveChangesButton = styled.div`
  position: fixed;
  top: 75px;
  right: 4vw;
  z-index: 999;
  width: 20vw;
`;

const ServicesSectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--dark-primary);
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-bottom:16px;

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const StaffList = styled.div`
  height: 26vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  margin: 0;
`;

const ServicesList = styled.div`
  height: 22.5vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  overflow: scroll;
`;

const Services = styled.div`
  height: 63vh;
  border-radius: 10px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageDetailContainer = styled.div`
  flex-direction: column;
  overflow: hidden;
`;
